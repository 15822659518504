import React from "react";
import {getPlaceholderImage} from "../../../utilities/common";

const StartingCallRequestJoin = ({callProfile, startCalling,onStartCallingHandler,onCancelCallingHandler}) => {


   return (
       <div className="tyn-chat-call tyn-chat-call-video">
           <div className="tyn-chat-call-stack">
               <div className="tyn-chat-call-cover">
               </div>
           </div>
           <div className="tyn-chat-call-stack on-dark" style={{background: '#64748b'}}>
               <div className="tyn-media-group tyn-media-vr tyn-media-center mt-auto">
                   <div
                       className="tyn-media tyn-size-xl tyn-circle border border-2 border-white">
                       <img src={getPlaceholderImage(callProfile?.photo)} alt=""/>
                   </div>
                   <div className="tyn-media-col">
                           {startCalling ? (
                               <>
                                   <div className="tyn-media-row has-dot-sap">
                                       <span className="meta">Appel en cours ...</span>
                                   </div>
                                   <div className="tyn-media-row has-dot-sap">
                                       <span className="meta">Veuillez patienter le temps qu'il accepte.</span>
                                   </div>
                               </>
                           ):(
                               <>
                                   <div className="tyn-media-row has-dot-sap">
                                       <span className="meta">Veuillez cliquer sur le bouton pour demarer.</span>
                                   </div>
                               </>
                           )}
                       <div className="tyn-media-row">
                           <h6 className="name">{callProfile?.name}</h6>
                       </div>
                   </div>
               </div>
               <ul className="tyn-list-inline gap gap-3 mx-auto py-4 justify-content-center">
                   {
                       startCalling ? (
                           <li>
                               <button className="btn btn-icon btn-pill btn-danger" onClick={onCancelCallingHandler}>
                                   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                        fill="currentColor" className="bi bi-telephone-x-fill"
                                        viewBox="0 0 16 16">
                                       <path fillRule="evenodd"
                                             d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm9.261 1.135a.5.5 0 0 1 .708 0L13 2.793l1.146-1.147a.5.5 0 0 1 .708.708L13.707 3.5l1.147 1.146a.5.5 0 0 1-.708.708L13 4.207l-1.146 1.147a.5.5 0 0 1-.708-.708L12.293 3.5l-1.147-1.146a.5.5 0 0 1 0-.708z"></path>
                                   </svg>
                               </button>
                           </li>
                       ):(
                           <li>
                               <button className="btn btn-icon btn-pill btn-success" onClick={onStartCallingHandler}>
                                   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-fill" viewBox="0 0 16 16">
                                       <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"></path>
                                   </svg>
                               </button>
                           </li>
                       )
                   }
               </ul>
           </div>
       </div>
   );

}
export  default StartingCallRequestJoin;