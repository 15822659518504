import React, {memo, useContext} from "react";
import {RootStateContext} from "../../../../../../providers/RootProvider";
import Outgoing from "./Outgoing";
import Incoming from "./Incoming";
import {dateFromApiTo} from "../../../../../../utilities/common";
import TimeAgo from "timeago-react";

const UserReadCheckSign = ({message}) => {
    if (message.readBy && message.readBy.some((_readBy)=> _readBy.user !== message.sender)){
        return (
            <span>
                    <svg fill="#00ffab" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{height: '16px',width: '16px'}}><path d="M0.41,13.41L6,19L7.41,17.58L1.83,12M22.24,5.58L11.66,16.17L7.5,12L6.07,13.41L11.66,19L23.66,7M18,7L16.59,5.58L10.24,11.93L11.66,13.34L18,7Z" /></svg>
                </span>
        )
    }else if(message.receiveBy && message.receiveBy.some((_receiveBy)=> _receiveBy.user !== message.sender)){
        return(
            <span>
                    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{height: '16px',width: '16px'}}><path d="M0.41,13.41L6,19L7.41,17.58L1.83,12M22.24,5.58L11.66,16.17L7.5,12L6.07,13.41L11.66,19L23.66,7M18,7L16.59,5.58L10.24,11.93L11.66,13.34L18,7Z" /></svg>
                </span>
        );
    }else{
        return(
            <span>
                    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" style={{height: '16px',width: '16px'}}><path fill="currentColor" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"></path></svg>
                </span>
        );

    }
}
export default UserReadCheckSign;