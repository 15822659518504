import React from "react";


const VideoItem = (props) => {
    return (
        <div className="col-6">
            <a target="_blank" rel="noopener noreferrer" href={props.url} className="glightbox tyn-video" data-gallery="media-video">
                <video controls className="tyn-video" data-src={"" + props?.url}
                       style={{width: '100%', height: '100%'}}>
                    <source
                        src={props.url}
                    />
                </video>
                <div className="tyn-video-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                         fill="currentColor" className="bi bi-play-fill"
                         viewBox="0 0 16 16">
                        <path
                            d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>
                    </svg>
                </div>
            </a>
        </div>
    );
}
export default VideoItem;
