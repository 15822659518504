import {ACCOUNT_ACTIONS, NOTIFICATION_ACTIONS} from "../actions/constants/Index";

const defaultState = {
    name: "",
    email: ""
}
const reducer = (prevState = defaultState, action) => {
    switch (action.type) {
        case ACCOUNT_ACTIONS.UPDATE_PROFILE:
            const userInfo= {
                ...prevState,
                ...action.payload
            };
            localStorage.setItem("pid_user",JSON.stringify(userInfo));
            return userInfo;
        case NOTIFICATION_ACTIONS.DECREMENT_UNREAD_COUNT:
            const _dPrevState= prevState;
            _dPrevState.unread_notifications_count = _dPrevState.unread_notifications_count - action.payload;
            localStorage.setItem("pid_user",JSON.stringify(_dPrevState));
            return {...prevState,..._dPrevState};
        case NOTIFICATION_ACTIONS.INCREMENT_UNREAD_COUNT:
            const _iPrevState= prevState;
            _iPrevState.unread_notifications_count = _iPrevState.unread_notifications_count + action.payload;
            localStorage.setItem("pid_user",JSON.stringify(_iPrevState));
            return {...prevState,..._iPrevState};
        case ACCOUNT_ACTIONS.SET_RETRIEVE:
            return action.payload;
        default:
            return prevState;
    }
};
export default reducer;
