import React, {useContext, useEffect, useState} from "react";
import {RootDispatchContext} from "../../../../providers/RootProvider";
import {Link} from "react-router-dom";

const  AsideEmptyOpened =()=> {

    const rootDispatchContext = useContext(RootDispatchContext);
    const {user} = rootDispatchContext;


    return (
        <div className="tyn-main tyn-chat-content" id="tynMain">
            <div className="tyn-section">
                <div className="tyn-text-block text-center pb-4 pb-lg-5">
                    <Link className="tyn-logo" to="index-2.html">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            id="Calque_1"
                            data-name="Calque 1"
                            viewBox="0 0 430 400"
                        >
                            <defs>
                                <linearGradient
                                    id="sans_nom_1"
                                    x1={-198.8}
                                    x2={487.57}
                                    y1={-80.48}
                                    y2={323.07}
                                    gradientUnits="userSpaceOnUse"
                                >
                                    <stop offset={0} stopColor="#e23a00"/>
                                    <stop offset={1} stopColor="#ff8b00"/>
                                </linearGradient>
                                <style>{".cls-3{fill:#fff}"}</style>
                            </defs>
                            <path
                                d="M191.6 348.78a154.75 154.75 0 0 1-34.18 3.84 151.83 151.83 0 0 1-83.23-24.69L0 352.62l26-74.93A152.61 152.61 0 0 1 157.42 47.38a154.75 154.75 0 0 1 34.18 3.84 172.14 172.14 0 0 0 0 297.56Z"
                                style={{
                                    fill: "#021f42",
                                }}
                            />
                            <path
                                d="M430 200v151.76H278.22A151.79 151.79 0 0 1 170.91 92.67a151.06 151.06 0 0 1 107.31-44.43A151.74 151.74 0 0 1 430 200Z"
                                style={{
                                    fill: "url(#sans_nom_1)",
                                }}
                            />
                            <circle cx={223.63} cy={200} r={18.4} className="cls-3"/>
                            <circle cx={278.24} cy={200} r={18.4} className="cls-3"/>
                            <circle cx={332.85} cy={200} r={18.4} className="cls-3"/>
                        </svg>
                    </Link>
                    <h1 className="mt-3">Bienvenue sur notre espace de chat ! 🎉</h1>
                    <p>Nous sommes ravis de t'accueillir pour ta toute première conversation ici. 🌟</p>
                </div>
                <div className="row g-3 justify-content-center twqext-center">
                    <div className="col-xl-3 col-lg-4">
                        <h4 className="title mb-3">Rencontres Enchantées 👫</h4>
                        <div className="row g-3 justify-content-center">
                            <div className="col-sm-6 col-md-5 col-lg-12">
                                <div className="card h-100 border-0">
                                    <div className="card-body">
                                        <div className="tyn-text-block">
                                            <p>Lance-toi dans la découverte de nouveaux visages et d'amitiés
                                                sincères.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4">
                        <h4 className="title mb-3">Thèmes En Folie 🌐</h4>
                        <div className="row g-3 justify-content-center">
                            <div className="col-sm-6 col-md-5 col-lg-12">
                                <div className="card h-100 border-0">
                                    <div className="card-body">
                                        <div className="tyn-text-block">
                                            <p>Rejoins l'une de nos discussions thématiques pour échanger des idées sur
                                                des sujets passionnents.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4">
                        <h4 className="title mb-3">La Sagesse Collective" 📖</h4>
                        <div className="row g-3 justify-content-center">
                            <div className="col-sm-6 col-md-5 col-lg-12">
                                <div className="card h-100 border-0">
                                    <div className="card-body">
                                        <div className="tyn-text-block">
                                            <p>Besoin de conseils, d'opinions ou simplement de l'avis de la communauté ? </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-center mt-4">
                    <div className="mt-4">
                        <Link className="btn btn-primary btn-rg btn-pill" to="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-send-fill" viewBox="0 0 16 16">
                                <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z" />
                            </svg>
                            <span>Commencer une discution</span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default  AsideEmptyOpened;

