import React, {useContext, useEffect, useRef, useState} from "react";
import {getPlaceholderImage, wait} from "../../../../../utilities/common";
import {useSocket} from "../../../../../providers/SocketProvider";
import {useNavigate} from "react-router-dom";
import toast from "react-hot-toast";
const audioPath = '/assets/audio/receive_calling.mp3';

const CallingVideoFrom = ()=>{

    const audioRef = useRef();
    const [isPlaying, setIsPlaying] = useState(false);
    const [callingVideoFrom, setCallingVideoFrom] = useState(null);
    const socket = useSocket();
    const modelCallingVideoFromElem = useRef(null);
    const modelCallingVideoFrom = useRef(null);
    const navigate = useNavigate();
    const [isInteracted, setIsInteracted] = useState(false);




    /**
     * @description Annule la demande
     */
    const onCancelRequestJoinCallVideo =()=>{
        socket.emit("cancelRequestJoinCallVideoRoom",callingVideoFrom);
        handleStop();
        modelCallingVideoFrom.current.hide();
    }


    /**
     * @description Accepte la demande
     */
    const onAcceptRequestJoinCallVideo =()=>{
        socket.emit("acceptRequestJoinCallVideoRoom",callingVideoFrom);

        setCallingVideoFrom(false);
        modelCallingVideoFrom.current.hide();
        handleStop();
        wait(1000).then(()=>{
            navigate("/video-call/received/"+callingVideoFrom.fromUserInfo.id+"/"+callingVideoFrom.roomID, { replace: true });
        });
    }

    /**
     * @description
     * @returns {null}
     */
    const getOrCreateModelCallingVideoFrom =()=>{
        let instance = modelCallingVideoFrom.current;
        if(!instance){
            instance = window.bootstrap.Modal.getOrCreateInstance(modelCallingVideoFromElem.current);
        }
        return instance;
    }


    useEffect(() => {
        if (callingVideoFrom){
            if (modelCallingVideoFromElem.current){
                modelCallingVideoFrom.current = getOrCreateModelCallingVideoFrom();
                modelCallingVideoFrom.current.show();
                handlePlayPause();

            }
        }else{
            if(modelCallingVideoFromElem.current){
                if(!modelCallingVideoFrom.current){
                    modelCallingVideoFrom.current = getOrCreateModelCallingVideoFrom();
                    modelCallingVideoFrom.current.hide();
                    handleStop();
                }
            }
        }
    }, [callingVideoFrom]);

    /**
     * @description
     */
    const handlePlayPause = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    }

    const handleStop = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
            setIsPlaying(false);
        }
    };



    useEffect(() => {
        if (socket){
            socket.on("requestJoinCallVideoRoom",(dataInfo)=>{
                setCallingVideoFrom(dataInfo);
            });

            //Ici l'utilisateur qui a initie la demande decide d'annuler sa propre demande
            socket.on("cancelCallingVideoRoom",(dataInfo)=>{
                handleStop();
                modelCallingVideoFrom.current.hide();
                setCallingVideoFrom(null);
            });

            //Ici un des sockets de l'utilisateur qui etait cense accepte la demande a refuse
            socket.on("anotherCancelRequestJoinCallVideoRoom",(dataInfo)=>{
                toast.success( "Une de vos sessions a déjà rejeté la demande de l'appel vidéo.");
                handleStop();
                modelCallingVideoFrom.current.hide();
                setCallingVideoFrom(null);

            });

            return ()=>{
                socket.off("requestJoinCallVideoRoom");
                socket.off("cancelCallingVideoRoom");
                socket.off("anotherCancelRequestJoinCallVideoRoom");
            }
        }
    }, [socket]);


    useEffect(() => {
        const handleInteraction = () => {
            if (isInteracted) return void 0;
            setIsInteracted(true);
            audioRef.current.defaultMuted = true;
        };
        document.addEventListener("click", handleInteraction);

        return () => {
            document.removeEventListener("click", handleInteraction);
        };
    }, []);


    return (
        <div className="modal fade" tabIndex="-1" id="CallingVideoFrom" ref={modelCallingVideoFromElem}  data-bs-backdrop="static" data-bs-keyboard="false">
            <div className="modal-dialog modal-dialog-centered modal-sm">
                <div className="modal-content border-0">
                    <div className="tyn-chat-call tyn-chat-call-video">
                        <div className="tyn-chat-call-stack">
                            <div className="tyn-chat-call-cover">
                                <img src={getPlaceholderImage(callingVideoFrom?.fromUserInfo?.photo)} alt=""/>
                            </div>
                        </div>
                        <div className="tyn-chat-call-stack on-dark" style={{background: '#64748b'}}>
                            <div className="tyn-media-group tyn-media-vr tyn-media-center mt-auto">
                                <div className="tyn-media tyn-size-xl tyn-circle border border-2 border-white">
                                    <img src={getPlaceholderImage(callingVideoFrom?.fromUserInfo?.photo)} alt=""/>
                                </div>
                                <div className="tyn-media-col">
                                    <div className="tyn-media-row has-dot-sap">
                                        <span className="meta">Appel vidéo de...</span>
                                    </div>
                                    <div className="tyn-media-row">
                                        <h6 className="name">{callingVideoFrom?.fromUserInfo?.name}</h6>
                                    </div>
                                </div>
                            </div>
                            <ul className="tyn-list-inline gap gap-3 mx-auto py-4 justify-content-center">
                                <li><button className="btn btn-icon btn-pill btn-danger" onClick={onCancelRequestJoinCallVideo}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-x-fill" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm9.261 1.135a.5.5 0 0 1 .708 0L13 2.793l1.146-1.147a.5.5 0 0 1 .708.708L13.707 3.5l1.147 1.146a.5.5 0 0 1-.708.708L13 4.207l-1.146 1.147a.5.5 0 0 1-.708-.708L12.293 3.5l-1.147-1.146a.5.5 0 0 1 0-.708z"></path>
                                    </svg>
                                </button></li>
                                <li><button className="btn btn-icon btn-pill btn-success" onClick={onAcceptRequestJoinCallVideo}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-camera-video-fill" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5z"></path>
                                    </svg>
                                </button></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <audio ref={audioRef} src={audioPath} loop style={{ display: 'none' }} muted={true}/>
        </div>
    );
}

export default CallingVideoFrom;
