import React from "react";
import {Link} from "react-router-dom";

const ImageItem = (props) => {
    return (
        <div className="col-4">
            <Link target="_blank" rel="noopener noreferrer" to={props?.url} className="glightbox tyn-thumb"
                  data-gallery="media-photo">
                <img src={props?.url} className="tyn-image"
                     alt=""/>
            </Link>

        </div>
    )
        ;
}
export default ImageItem;