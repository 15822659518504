import React, {useContext, useEffect, useState} from "react";
import toast from "react-hot-toast";
import {getAuthFriends} from "../../../../../actions/server/friend";
import {RootDispatchContext} from "../../../../../providers/RootProvider";
import AddToGroupItem from "../../../../contact/AddToGroupItem";
import {requestToCreate} from "../../../../../actions/server/group";
const defaultPreview = 'https://avatars1.githubusercontent.com/u/11435231?s=460&v=4';

export default ({contacts})=>{
    const rootDispatchContext = useContext(RootDispatchContext);
    const [friends,setFriends] = useState(contacts);
    const [filterData, setFilterData] = useState(contacts);
    const [friendsAdded, setFriendsAdded] = useState([]);
    const [isCreating, setIsCreating] = useState(false);
    
    
    const [image, setImage] = useState({
        preview: defaultPreview,
        data: null
    })
    const [dataForm, setDataForm] = useState({
        name: "",
        description: "",
        members: []
    });





    /**
     * @description callback permetant de filtrer la liste des contacts
     * @param _event
     */
    const filterListHandler = _event => {
        const updatedList = friends.filter(item => {
            return (
                item.name.toLowerCase().search(_event.target.value.toLowerCase()) !== -1 ||
                item.contact.toLowerCase().search(_event.target.value.toLowerCase()) !== -1
            );
        });
        setFilterData(updatedList);
    }

    /**
     * @description Permet de changer de facon intelligente la valeur
     * @param _event
     * @param key
     */
    const onChangeFormFieldHandler = (_event, key) => {
        let _currentForm = dataForm;
        _currentForm[key] = _event.target.value;
        setDataForm({
            ...dataForm,
            ..._currentForm
        });
    }

    /**
     *@description
     * @param _event
     */
    const handleFileChange = (_event) => {
        if (_event.target.files.length !== 0) {
            setImage((prevState) => ({
                ...prevState,
                preview: URL.createObjectURL(_event.target.files[0]),
                data: _event.target.files[0],
            }));
        }
    }

    /**
     *@description
     * @param friend
     */
    const toggleContactRequest = (friend) => {
        let _friendsAdded = friendsAdded;
        if (_friendsAdded.indexOf(friend._id) !== -1) {
            _friendsAdded = _friendsAdded.filter((_) => _ !== friend._id);
        } else {
            _friendsAdded = [...friendsAdded, friend._id];
        }
        setFriendsAdded(_friendsAdded);
    }

    /**
     * @description Permet de traiter la creation du groupe
     * @param _event
     */
    const onSubmitHandler = (_event)=>{
        _event.preventDefault();

        setIsCreating((prevState) => !prevState);

        if(friendsAdded.length ===0){
            setIsCreating(false);
            toast.error('Veuillez ajouter au moins un contact au groupe');
            return void(0);
        }
        if(dataForm.name.trim().length ===0){
            setIsCreating(false);
            toast.error('Veuillez saisir le nom de votre groupe');
            return void(0);
        }
        if(dataForm.description.trim().length ===0){
            setIsCreating(false);
            toast.error('Veuillez saisir une description de votre groupe');
            return void(0);
        }

        if(!image.data){
            setIsCreating(false);
            toast.error('Votre groupe doit avoir au moin une image de couverture');
            return void(0);
        }

        let _formData = new FormData();
        _formData.append('cover', image.data);
        _formData.append('name', dataForm.name);
        _formData.append('description', dataForm.description);
        _formData.append('members', JSON.stringify(friendsAdded));

        requestToCreate(rootDispatchContext, _formData,
            (group) => {
                setIsCreating(false);
                toast.success('Groupe cree avec succes !');
            }, (error) => {
                toast.error(error.msg);
                setIsCreating(false);
            });
    }
    
    return (
        <div className="modal fade" tabIndex="-1" id="newGroup">
            <div className="modal-dialog modal-dialog-centered modal-md">
                <div className="modal-content border-0">
                    <div className="modal-body p-4">
                        <h4 className="pb-2">Nouveau groupe</h4>
                        <div className="d-flex flex-column justify-content-center border-bottom pb-3 mb-3">
                            <div className="tyn-choice-input-image">
                                <label className="label">
                                    <input type="file" onChange={handleFileChange} accept="image/png, image/jpg, image/jpeg"/>
                                    <figure className="tyn-choice-input-figure d-flex justify-content-center align-items-center">
                                        {image.data ? (
                                            <img src={image.preview} className="tyn-choice-input-avatar" alt="avatar"  style={{ objectFit:"cover"}}/>
                                        ):(
                                            <svg xmlns="http://www.w3.org/2000/svg" width="75" height="75" fill="currentColor" className="bi bi-card-image" viewBox="0 0 16 16">
                                                <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"></path>
                                                <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54A.505.505 0 0 1 1 12.5v-9a.5.5 0 0 1 .5-.5h13z"></path>
                                            </svg>
                                        )}
                                        <figcaption className="tyn-choice-input-figcaption">
                                            <img src="https://raw.githubusercontent.com/ThiagoLuizNunes/angular-boilerplate/master/src/assets/imgs/camera-white.png" alt="avatar"/>
                                        </figcaption>
                                    </figure>
                                </label>
                            </div>
                            <div className="text-center">
                                <span className="small">Image de couverture</span>
                            </div>
                        </div>
                       <div className="row g-gs border-bottom pb-3 mb-3">
                           <div className="col-lg-12">
                               <div className="form-group">
                                   <div className="form-control-wrap">
                                       <input type="text" className="form-control rounded-pill" id="name" name="name" placeholder="Nom" value={dataForm.name} onChange={(_event) => onChangeFormFieldHandler(_event, "name")}/>
                                   </div>
                               </div>
                           </div>
                           <div className="col-lg-12">
                               <div className="form-group">
                                   <div className="form-control-wrap">
                                       <input type="text" className="form-control rounded-pill" id="name" name="name" placeholder="Description" onChange={(_event) => onChangeFormFieldHandler(_event, "description")}/>
                                   </div>
                               </div>
                           </div>
                           <div className="col-lg-12 d-flex justify-content-center">
                               <button className={!isCreating ? "btn btn-md btn-primary" : "btn btn-md btn-light"} onClick={onSubmitHandler} disabled={isCreating}>
                                   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                        fill="currentColor" className="bi bi-check2-circle"
                                        viewBox="0 0 16 16">
                                       <path
                                           d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"></path>
                                       <path
                                           d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"></path>
                                   </svg>
                                   <span>{isCreating ? "En cours...":"Enregistrer"}</span>
                               </button>
                           </div>
                       </div>
                        <div className="form-group">
                            <div className="form-control-wrap">
                                <div className="form-control-icon start">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                    </svg>
                                </div>
                                <input type="text" className="form-control form-control-solid" id="search-contact" placeholder="Rechercher des contacts" onChange={filterListHandler}/>
                            </div>
                        </div>
                        <ul className="tyn-media-list gap gap-3 pt-4">
                            {filterData.map((_value, _key) => (
                                <AddToGroupItem toggleContactRequest={toggleContactRequest}
                                                isAdded={friendsAdded.indexOf(_value._id) !== -1}
                                                contact={_value} key={_value._id}/>
                            ))}
                        </ul>
                    </div>
                    <button className="btn btn-md btn-icon btn-pill btn-white shadow position-absolute top-0 end-0 mt-n3 me-n3" data-bs-dismiss="modal">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
);
}