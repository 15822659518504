import {AUTH_ACTIONS} from "../constants/Index";


/**
 * @description Permet de traiter la response des requetes api
 * @param error
 * @param dispatch
 * @param nextCallback
 */
export default (error, dispatch,nextCallback) => {
    if (error.authenticateInvalidToken){
        let pid_user = localStorage.getItem("pid_user");
        if(pid_user){//il est connecte
            localStorage.removeItem("pid_user");
            dispatch({
                type: AUTH_ACTIONS.SET_LOGGED_OUT
            });
        }
    }
    nextCallback(error);
}