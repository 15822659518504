export default {
    authenticate:{
        PROD:{
            endpoint : "https://api-meet.yuzspace.com",
        },
        DEV:{
            endpoint : "http://localhost:8000"
        }
    },
    default:{
        PROD:{
            endpoint: "https://api-meet.yuzspace.com"
        },
        DEV:{
            endpoint: "http://localhost:8000"
        }
    }
}