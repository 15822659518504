import React from "react";
import ActionsDropdown from "./ActionsDropdown";
import Media from "./Media";
import Audio from "./Audio";
import AuthorSender from "./AuthorSender";
import TimeAgoBubble from "./TimeAgoBubble";

const  Bubble =({message,conversation,context,handleDeleteMessage,handleReplyTo})=> {

    return (
        <>
            {message.audio && (
                <Audio key={'audio'+message.id} message={message} conversation={conversation} context={context} handleDeleteMessage={handleDeleteMessage} handleReplyTo={handleReplyTo} showActionDropdown={true}/>
            )}
            {message.media && Array.isArray(message.media) && message.media.length > 0 && (
                <Media message={message} conversation={conversation} context={context} handleDeleteMessage={handleDeleteMessage} handleReplyTo={handleReplyTo} showActionDropdown={true}/>
            )}
            { message.content.trim() !=="" && (
                <div className="tyn-reply-bubble">
                    <div className="tyn-reply-text">
                        <AuthorSender message={message} conversation={conversation} context={context} handleDeleteMessage={handleDeleteMessage} handleReplyTo={handleReplyTo}/>
                        <div dangerouslySetInnerHTML={{__html: message.content}} className="mb-1"></div>
                        <TimeAgoBubble message={message} conversation={conversation} context={context} handleDeleteMessage={handleDeleteMessage} handleReplyTo={handleReplyTo}/>
                    </div>
                    <ActionsDropdown handleReplyTo={handleReplyTo} handleDeleteMessage={handleDeleteMessage} conversation={conversation} message={message} context={context}/>
                </div>
            )}
        </>
    );
}
export default  Bubble;