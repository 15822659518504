import React, {useContext, useState} from "react";
import {MasterLayoutProvider} from "../../components/layouts/auth/Master";
import {Link} from "react-router-dom";
import toast from "react-hot-toast";
import {authRegister} from "../../actions/dispatch/auth";
import {RootDispatchContext} from "../../providers/RootProvider";
import {getBaseUrlLocation} from "../../utilities/common";

function Register() {
    const rootDispatchContext = useContext(RootDispatchContext);

    const [isLogging, setIsLogging] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [dataForm, setDataForm] = useState({
        name: "",
        email: "",
        contact: "",
        password: "",
        is_agree:false
    });


    /**
     * @description Permet de changer de facon intelligente la valeur
     * @param _event
     * @param key
     */
    const onChangeFormFieldHandler = (_event, key) => {
        let _ = dataForm;
        _[key] = key==="is_agree" ? _event.target.checked: _event.target.value;
        setDataForm({
            ...dataForm,
            ..._
        });
    }

    const onSubmitHandler =(_event)=>{
        _event.preventDefault();
        setIsLogging(prevState => !prevState);
        if(!dataForm.is_agree){
            toast.error("Veuillez accepter les termes du contrat");
            setIsLogging(false);
            return void(0);
        }
        authRegister(rootDispatchContext, dataForm,
            (user) => {
                setIsLogging(false);
                toast.success('Votre inscription a effectue avec succes !');
                window.location = getBaseUrlLocation();
            }, (error) => {
                toast.error(error.msg);
                setIsLogging(false);
            });
    }


    return (
        <MasterLayoutProvider>
            <div className="card border-0">
                <form className="p-4" method="post"  onSubmit={onSubmitHandler}>
                    <h3>Créer un compte</h3>
                    <div className="row g-3 gx-4">
                        <div className="col-12">
                            <div className="form-group">
                                <div className="form-control-wrap">
                                    <input type="text" className="form-control rounded-pill" id="name" name="name"
                                           placeholder="Nom complet" value={dataForm.name} onChange={(_event)=> onChangeFormFieldHandler(_event,'name')}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <div className="form-control-wrap">
                                    <input type="email" className="form-control rounded-pill" id="email" name="email"
                                           placeholder="Adresse email" value={dataForm.email} onChange={(_event)=> onChangeFormFieldHandler(_event,'email')}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <div className="form-control-wrap">
                                    <input type="number" className="form-control rounded-pill" id="contact" name="contact"
                                           placeholder="Contact" value={dataForm.contact} onChange={(_event)=> onChangeFormFieldHandler(_event,'contact')}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <div className="form-group">
                                    <div className="input-group mb-3">
                                        <input style={{borderBottomLeftRadius:'9999px',borderTopLeftRadius:'9999px'}} type={showPassword ? "text" : "password"} className="form-control" id="password" name="password"
                                               placeholder="Votre mot de passe" value={dataForm.password} onChange={(_event)=> onChangeFormFieldHandler(_event,'password')}/>
                                        <span className="input-group-text"
                                               style={{borderBottomRightRadius:'9999px',borderTopRightRadius:'9999px'}}
                                              onClick={() => setShowPassword((prevState) => !prevState)}>
                                            {showPassword ? (
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                     viewBox="0 0 24 24" fill="none">
                                                    <path d="M2 2L22 22" stroke="#000000" strokeWidth="2"
                                                          strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path
                                                        d="M6.71277 6.7226C3.66479 8.79527 2 12 2 12C2 12 5.63636 19 12 19C14.0503 19 15.8174 18.2734 17.2711 17.2884M11 5.05822C11.3254 5.02013 11.6588 5 12 5C18.3636 5 22 12 22 12C22 12 21.3082 13.3317 20 14.8335"
                                                        stroke="#000000" strokeWidth="2" strokeLinecap="round"
                                                        strokeLinejoin="round"/>
                                                    <path
                                                        d="M14 14.2362C13.4692 14.7112 12.7684 15.0001 12 15.0001C10.3431 15.0001 9 13.657 9 12.0001C9 11.1764 9.33193 10.4303 9.86932 9.88818"
                                                        stroke="#000000" strokeWidth="2" strokeLinecap="round"
                                                        strokeLinejoin="round"/>
                                                </svg>
                                            ) : (
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" version="1.1"
                                                     width="24" height="24" viewBox="0 0 37.519 37.519">
                                                    <g>
                                                        <path
                                                            d="M37.087,17.705c-0.334-0.338-8.284-8.276-18.327-8.276S0.766,17.367,0.433,17.705c-0.577,0.584-0.577,1.523,0,2.107   c0.333,0.34,8.284,8.277,18.327,8.277s17.993-7.938,18.327-8.275C37.662,19.23,37.662,18.29,37.087,17.705z M18.76,25.089   c-6.721,0-12.604-4.291-15.022-6.332c2.411-2.04,8.281-6.328,15.022-6.328c6.72,0,12.604,4.292,15.021,6.332   C31.369,20.802,25.501,25.089,18.76,25.089z M18.76,13.009c3.176,0,5.75,2.574,5.75,5.75c0,3.175-2.574,5.75-5.75,5.75   c-3.177,0-5.75-2.574-5.75-5.75C13.01,15.584,15.583,13.009,18.76,13.009z"/>
                                                    </g>
                                                </svg>
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="muteFor"
                                       id="privacy-term-agree" checked={dataForm.is_agree} onChange={(_event)=> onChangeFormFieldHandler(_event,'is_agree')}/>
                                <label className="form-check-label" htmlFor="privacy-term-agree">
                                    J'accepte <Link to="#">la politique de confidentialité et les conditions</Link>
                                </label>
                            </div>
                        </div>
                        <div className="col-12">
                            <button className="btn btn-primary w-100" type="submit" disabled={(dataForm.name === "" || dataForm.email === "" || dataForm.contact === "" || dataForm.password === "" || !dataForm.is_agree) || isLogging}>
                                {isLogging ?
                                    <>
                                        <span className="spinner-border spinner-border-sm" role="status"
                                              aria-hidden="true"></span>
                                        <span className="sr-only">Inscription...</span>
                                    </> :
                                    <>S'inscrire</>
                                }
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div className="text-center mt-4">
                <p className="small">Vous avez déjà un compte ? <Link to="/">Se connecter</Link></p>
            </div>
        </MasterLayoutProvider>
    );
}

export default Register;