import React from "react";
import TimeAgoBubble from "./TimeAgoBubble";
import ActionsDropdown from "./ActionsDropdown";
import AuthorSender from "./AuthorSender";
import {humanFileSize} from "../../../../../../utilities/common";

const ViewMediaAttach = ({media}) => {
    switch (media.type) {
        case 'photo':
            return (
                <a href={media.url} className="glightbox tyn-thumb" data-gallery="media-photo" key={media.url}>
                    <img src={media.url} className="tyn-image" alt=""/>
                </a>
            );
        case 'video':
            return (
                <div key={media.url}>
                    <video controls className="tyn-video" data-src={"" + media.url}
                           style={{width: '100%', height: '100%'}}>
                        <source
                            src={media.url}
                        />
                    </video>
                </div>
            );
        case 'file':
            return (
                <div className="tyn-reply-file" key={media.url}>
                    <a href={media.url} rel="noopener noreferrer" target="_blank" className="tyn-file">
                        <div className="tyn-media-group">
                            <div className="tyn-media tyn-size-lg text-bg-light">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-filetype-docx" viewBox="0 0 16 16">
                                    <path fillRule="evenodd"
                                          d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5Zm-6.839 9.688v-.522a1.54 1.54 0 0 0-.117-.641.861.861 0 0 0-.322-.387.862.862 0 0 0-.469-.129.868.868 0 0 0-.471.13.868.868 0 0 0-.32.386 1.54 1.54 0 0 0-.117.641v.522c0 .256.04.47.117.641a.868.868 0 0 0 .32.387.883.883 0 0 0 .471.126.877.877 0 0 0 .469-.126.861.861 0 0 0 .322-.386 1.55 1.55 0 0 0 .117-.642Zm.803-.516v.513c0 .375-.068.7-.205.973a1.47 1.47 0 0 1-.589.627c-.254.144-.56.216-.917.216a1.86 1.86 0 0 1-.92-.216 1.463 1.463 0 0 1-.589-.627 2.151 2.151 0 0 1-.205-.973v-.513c0-.379.069-.704.205-.975.137-.274.333-.483.59-.627.257-.147.564-.22.92-.22.357 0 .662.073.916.22.256.146.452.356.59.63.136.271.204.595.204.972ZM1 15.925v-3.999h1.459c.406 0 .741.078 1.005.235.264.156.46.382.589.68.13.296.196.655.196 1.074 0 .422-.065.784-.196 1.084-.131.301-.33.53-.595.689-.264.158-.597.237-.999.237H1Zm1.354-3.354H1.79v2.707h.563c.185 0 .346-.028.483-.082a.8.8 0 0 0 .334-.252c.088-.114.153-.254.196-.422a2.3 2.3 0 0 0 .068-.592c0-.3-.04-.552-.118-.753a.89.89 0 0 0-.354-.454c-.158-.102-.361-.152-.61-.152Zm6.756 1.116c0-.248.034-.46.103-.633a.868.868 0 0 1 .301-.398.814.814 0 0 1 .475-.138c.15 0 .283.032.398.097a.7.7 0 0 1 .273.26.85.85 0 0 1 .12.381h.765v-.073a1.33 1.33 0 0 0-.466-.964 1.44 1.44 0 0 0-.49-.272 1.836 1.836 0 0 0-.606-.097c-.355 0-.66.074-.911.223-.25.148-.44.359-.571.633-.131.273-.197.6-.197.978v.498c0 .379.065.704.194.976.13.271.321.48.571.627.25.144.555.216.914.216.293 0 .555-.054.785-.164.23-.11.414-.26.551-.454a1.27 1.27 0 0 0 .226-.674v-.076h-.765a.8.8 0 0 1-.117.364.699.699 0 0 1-.273.248.874.874 0 0 1-.401.088.845.845 0 0 1-.478-.131.834.834 0 0 1-.298-.393 1.7 1.7 0 0 1-.103-.627v-.495Zm5.092-1.76h.894l-1.275 2.006 1.254 1.992h-.908l-.85-1.415h-.035l-.852 1.415h-.862l1.24-2.015-1.228-1.984h.932l.832 1.439h.035l.823-1.439Z"></path>
                                </svg>
                            </div>
                            <div className="tyn-media-col">
                                <h6 className="name">{media.name}</h6>
                                <div className="meta">{humanFileSize(media.size,true)}</div>
                            </div>
                        </div>
                    </a>
                </div>
            );
            default:
                return <></>
    }
}
const Media = ({message,handleReplyTo,handleDeleteMessage,conversation,context,showActionDropdown}) => {
    if (message.media) {
        return (
            <div className="tyn-reply-bubble">
                <div className="tyn-reply-media" style={{
                    position: 'relative',
                    paddingTop: message.content.trim() === "" ? '25px' : 'initial',
                    paddingBottom: message.content.trim() === "" ? '25px' : 'initial',
                    background: context === "incoming" ? 'var(--reply-incoming-bg)' : 'var(--reply-outgoing-bg)',
                    color: context === "incoming" ? 'var(--incoming-message-text)' : 'var(--outgoing-message-text)'
                }}>
                    {message.content.trim() === "" &&
                        <div style={{position: 'absolute', top: '5px', left: '10px'}} className="w-100">
                            <AuthorSender handleReplyTo={handleReplyTo} handleDeleteMessage={handleDeleteMessage}
                                          conversation={conversation} message={message} context={context}/>
                        </div>}
                    {message.media.map((_media, _key) => <ViewMediaAttach media={_media} key={_media.url}/>)}
                    {message.content.trim() === "" &&
                        <div style={{position: 'absolute', bottom: '5px'}}><TimeAgoBubble handleReplyTo={handleReplyTo}
                                                                                          handleDeleteMessage={handleDeleteMessage}
                                                                                          conversation={conversation}
                                                                                          message={message}
                                                                                          context={context}/></div>}
                </div>
                {showActionDropdown === true && (
                    <ActionsDropdown handleReplyTo={handleReplyTo} handleDeleteMessage={handleDeleteMessage}
                                     conversation={conversation} message={message} context={context}/>
                )}
            </div>
        );
    }

}
export default Media;
