import React from "react";
import Media from "./Media";
import Audio from "./Audio";
import AuthorSender from "./AuthorSender";
import TimeAgoBubble from "./TimeAgoBubble";

const  ReplyToMessage =({message,conversation})=> {

    return (
        <>
            <Audio message={message} conversation={conversation} showActionDropdown={false} context={message.context}/>
            <Media message={message} conversation={conversation} showActionDropdown={false} context={message.context}/>
            { message.content.trim() !== "" && (
                <div className="tyn-reply-bubble">
                    <div className="tyn-reply-text">
                        <AuthorSender message={message} conversation={conversation} context={message.context}/>
                        <div dangerouslySetInnerHTML={{__html: message.content}} className="mb-1"></div>
                        <TimeAgoBubble message={message} conversation={conversation} context={message.context}/>
                    </div>
                </div>
            )}
        </>
    );
}
export default  ReplyToMessage;