import React, {useContext} from "react";
import {Link} from "react-router-dom";
import AppPreventLoading from "../AppPreventLoading";
import {RootStateContext} from "../../../providers/RootProvider";

export const MasterLayoutProvider = ({children}) => {
    const rootStateContext = useContext(RootStateContext);

    return (
        <div className="tyn-root">
            {!rootStateContext.appState.isPreventLoading &&  (
                <div className="tyn-content tyn-auth tyn-auth-centered">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-5 col-lg-5 col-md-7 col-sm-9">
                                <div className="my-3 text-center">
                                    <Link className="tyn-logo tyn-logo-sm" to="/">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            id="Calque_1"
                                            data-name="Calque 1"
                                            viewBox="0 0 643.78 158.26"
                                        >
                                            <defs>
                                                <linearGradient
                                                    id="sans_nom_3"
                                                    x1={-79.55}
                                                    x2={195.09}
                                                    y1={-33.09}
                                                    y2={128.38}
                                                    gradientUnits="userSpaceOnUse"
                                                >
                                                    <stop offset={0} stopColor="#e23a00"/>
                                                    <stop offset={1} stopColor="#ff8b00"/>
                                                </linearGradient>
                                                <linearGradient
                                                    xlinkHref="#sans_nom_3"
                                                    id="sans_nom_3-2"
                                                    x1={197.18}
                                                    x2={781.94}
                                                    y1={48.99}
                                                    y2={144.43}
                                                />
                                                <linearGradient
                                                    xlinkHref="#sans_nom_3"
                                                    id="sans_nom_3-3"
                                                    x1={197.58}
                                                    x2={782.34}
                                                    y1={46.51}
                                                    y2={141.95}
                                                />
                                                <linearGradient
                                                    xlinkHref="#sans_nom_3"
                                                    id="sans_nom_3-4"
                                                    x1={199.13}
                                                    x2={783.89}
                                                    y1={37.06}
                                                    y2={132.5}
                                                />
                                                <linearGradient
                                                    xlinkHref="#sans_nom_3"
                                                    id="sans_nom_3-5"
                                                    x1={201.71}
                                                    x2={786.47}
                                                    y1={21.21}
                                                    y2={116.66}
                                                />
                                                <style>{".cls-1{fill:#021f42}.cls-3{fill:#fff}"}</style>
                                            </defs>
                                            <path
                                                d="m213.74 84.48-34.54-52h19.51l23.23 37.38L245 32.47h19.51L230.13 83.4v42.4h-16.39ZM323.89 98.39a31.82 31.82 0 0 1-1.89 11.24 22.56 22.56 0 0 1-5.67 8.61 26.09 26.09 0 0 1-9.5 5.56 40.28 40.28 0 0 1-13.28 2 39.56 39.56 0 0 1-13.13-2 27 27 0 0 1-9.55-5.61 23.59 23.59 0 0 1-5.88-8.67 29.67 29.67 0 0 1-2-11v-45h12.7v44.59a13.68 13.68 0 0 0 4.78 10.79q4.77 4.2 13.28 4.19h.31q8.51 0 12.82-4.19a14.43 14.43 0 0 0 4.3-10.79V53.45h12.7ZM387.45 75.29a8.39 8.39 0 0 0 3-2.31 4.63 4.63 0 0 0 1.2-2.94 3.7 3.7 0 0 0-1.15-2.78 5 5 0 0 0-3.57-1.1h-48.3V53.45h47.87q8.82 0 13.18 4.62A16.69 16.69 0 0 1 404 70c0 .91-.08 2-.22 3.41a13.22 13.22 0 0 1-1.31 4.35 16.4 16.4 0 0 1-3.46 4.61 22.78 22.78 0 0 1-6.56 4.2l-39.8 17.29a7.62 7.62 0 0 0-3.1 2.31 4.75 4.75 0 0 0-1 2.83 3.61 3.61 0 0 0 1.36 3 5.25 5.25 0 0 0 3.26 1h49.13v12.8h-48.77q-8.82 0-13.23-4.62a16.49 16.49 0 0 1-4.41-11.87 22.69 22.69 0 0 1 .42-4.14 14.85 14.85 0 0 1 1.68-4.52 17.57 17.57 0 0 1 3.57-4.36 21 21 0 0 1 6.09-3.67ZM76.66 138.66A61.66 61.66 0 0 1 63 140.2a60.72 60.72 0 0 1-33.3-9.88L0 140.2l10.42-30A61.06 61.06 0 0 1 63 18.07a61.66 61.66 0 0 1 13.66 1.53 68.88 68.88 0 0 0 0 119.06Z"
                                                className="cls-1"
                                            />
                                            <path
                                                d="M172.05 79.12v60.74h-60.73a60.73 60.73 0 1 1 0-121.45 60.71 60.71 0 0 1 60.73 60.71Z"
                                                style={{
                                                    fill: "url(#sans_nom_3)",
                                                }}
                                            />
                                            <circle cx={89.48} cy={79.13} r={7.36} className="cls-3"/>
                                            <circle cx={111.33} cy={79.13} r={7.36} className="cls-3"/>
                                            <circle cx={133.18} cy={79.13} r={7.36} className="cls-3"/>
                                            <path
                                                d="M467.73 124.81V78.16l-18.16 34.74h-7.44L424 78.16v46.65h-13.7V54.34H425l20.84 40.1 21-40.1h14.59v70.47Z"
                                                style={{
                                                    fill: "url(#sans_nom_3-2)",
                                                }}
                                            />
                                            <path
                                                d="M518.25 125.8a29 29 0 0 1-11.32-2.13 25.57 25.57 0 0 1-14.14-14.34 27.43 27.43 0 0 1-1.93-10.23 29.15 29.15 0 0 1 1.88-10.47A25.65 25.65 0 0 1 498.2 80a26.19 26.19 0 0 1 8.68-5.91 29 29 0 0 1 11.47-2.18 28.28 28.28 0 0 1 11.36 2.18 26.08 26.08 0 0 1 8.53 5.91 24.82 24.82 0 0 1 5.36 8.53 28.35 28.35 0 0 1 1.84 10.13c0 .86 0 1.68-.05 2.48a10 10 0 0 1-.25 2H505a15.36 15.36 0 0 0 1.49 5.46 13.32 13.32 0 0 0 7.34 6.65 13.91 13.91 0 0 0 5 .89 15.35 15.35 0 0 0 7.5-1.93 10.26 10.26 0 0 0 4.81-5.11l11.41 3.17a23.24 23.24 0 0 1-9.18 9.78 28.5 28.5 0 0 1-15.12 3.75Zm13.49-31.36a13.86 13.86 0 0 0-4.31-9.38 13.55 13.55 0 0 0-18.41 0 13.68 13.68 0 0 0-2.93 4.07 14.32 14.32 0 0 0-1.34 5.26Z"
                                                style={{
                                                    fill: "url(#sans_nom_3-3)",
                                                }}
                                            />
                                            <path
                                                d="M577.69 125.8a28.86 28.86 0 0 1-11.31-2.13 25.55 25.55 0 0 1-14.15-14.34 27.43 27.43 0 0 1-1.93-10.23 28.94 28.94 0 0 1 1.89-10.47 25.47 25.47 0 0 1 5.45-8.63 26.13 26.13 0 0 1 8.69-5.91 29 29 0 0 1 11.46-2.18 28.28 28.28 0 0 1 11.36 2.18 26.23 26.23 0 0 1 8.54 5.91 25.13 25.13 0 0 1 5.36 8.53 28.56 28.56 0 0 1 1.83 10.13v2.48a9.32 9.32 0 0 1-.25 2h-40.24a15.57 15.57 0 0 0 1.49 5.46 13.4 13.4 0 0 0 7.34 6.65 14 14 0 0 0 5 .89 15.34 15.34 0 0 0 7.49-1.93 10.22 10.22 0 0 0 4.81-5.11l11.41 3.17a23.24 23.24 0 0 1-9.18 9.78 28.5 28.5 0 0 1-15.06 3.75Zm13.5-31.36a13.87 13.87 0 0 0-4.32-9.38 13.55 13.55 0 0 0-18.41 0 13.68 13.68 0 0 0-2.93 4.07 14.51 14.51 0 0 0-1.34 5.26Z"
                                                style={{
                                                    fill: "url(#sans_nom_3-4)",
                                                }}
                                            />
                                            <path
                                                d="M643.78 122.13a61 61 0 0 1-6.55 2.38 27.62 27.62 0 0 1-8.13 1.19 18.59 18.59 0 0 1-5.21-.7 11.26 11.26 0 0 1-4.27-2.23 10.83 10.83 0 0 1-2.93-4 14.32 14.32 0 0 1-1.09-5.9V83h-6.85V72.81h6.85V55.93h13.3v16.88h10.91V83H628.9v25.4c0 1.85.48 3.16 1.44 3.92a5.47 5.47 0 0 0 3.52 1.14 12.34 12.34 0 0 0 4.07-.69c1.32-.46 2.38-.86 3.17-1.19Z"
                                                style={{
                                                    fill: "url(#sans_nom_3-5)",
                                                }}
                                            />
                                        </svg>
                                    </Link>
                                </div>
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {rootStateContext.appState.isPreventLoading && <AppPreventLoading/>}
        </div>
    );
}