import React, {useEffect} from 'react';
import AsideEmptyOpened from "../../../components/conversation/work_space/Aside/AsideEmptyOpened";
import Conversation from "./Conversation";

const ConversationList = ({openConversations,newPushMessage,onSendMessageInConversationHandler,readForMessages}) => {

    useEffect(() => {
        Array.from((document.querySelectorAll('.tyn-main.tyn-chat-content'))).forEach((_chatBody,_key)=>{
            if (_key !==0){
                _chatBody.classList.add('d-none');
            }else{
                _chatBody.classList.remove('d-none');
            }
        });
    }, [openConversations]);

    return (
        <>
            {openConversations.length > 0 ? openConversations.map((conversation) => (
                <Conversation readForMessages={readForMessages} conversation={conversation} key={conversation.id} newPushMessage={newPushMessage} onSendMessageInConversationHandler={onSendMessageInConversationHandler}/>
            )) : (
                <AsideEmptyOpened/>
            )}
        </>
    );
};

export default ConversationList;
