import React, {useContext, useEffect, useRef, useState} from "react";
import Logo from "./Logo";
import Start from "./app_bar_nav/Start";
import End from "./app_bar_nav/End";
import AppPreventLoading from "../AppPreventLoading";
import {RootStateContext} from "../../../providers/RootProvider";
import CallingVideoFrom from "./modals/chat/CallingVideoFrom";

export const MasterLayoutProvider = ({children,isFooter,isQuickChat}) => {
    const rootStateContext = useContext(RootStateContext);



    return (
        <div className="tyn-root">
            {!rootStateContext.appState.isPreventLoading &&  (
                <>
                        <nav className="tyn-appbar">
                            <div className="tyn-appbar-wrap">
                                <Logo/>
                                <div className="tyn-appbar-content">
                                    <Start/>
                                    <End/>
                                </div>
                            </div>
                        </nav>
                        {children}
                    <CallingVideoFrom/>
                </>
            )}
            {rootStateContext.appState.isPreventLoading &&   <AppPreventLoading/>}
        </div>
    );
}