import {combineReducers} from "@reduxjs/toolkit";
import authReducer from "./authReducer";
import appStateReducer from "./appStateReducer";
import themeReducer from "./themeReducer";
import userReducer from "./userReducer";
import conversationTabReducer from "./conversationTabReducer";

export const reducers = combineReducers({
    auth: authReducer,
    appState:appStateReducer,
    user:userReducer,
    theme:themeReducer,
    conversationTab:conversationTabReducer
});