import React, {useContext, useEffect, useState} from "react";
import BrowserItem from "../../../components/contact/BrowserItem";
import toast from "react-hot-toast";
import {getNotFriendUsers} from "../../../actions/server/user/browser";
import {RootDispatchContext} from "../../../providers/RootProvider";

function Browser() {
    const rootDispatchContext = useContext(RootDispatchContext);

    const [isFetchingApi, setIsFetchingApi] = useState(true);
    const [contactsBrowser, setContactsBrowser] = useState([]);
    const [filterData, setFilterData] = useState([]);

    const [contactsWaitingJoin, setContactsWaitingJoin] = useState([]);

    useEffect(() => {
        let pi_contacts_join = localStorage.getItem("pi_contacts_join");
        if (pi_contacts_join) {
            pi_contacts_join = JSON.parse(pi_contacts_join);
            if (pi_contacts_join) {
                setContactsWaitingJoin(pi_contacts_join);
            }
        }
        getNotFriendUsers(rootDispatchContext, (users) => {
                setContactsBrowser(users);
                setFilterData(users);
                setIsFetchingApi(false);
            },
            (error) => {
                toast.error(error.msg);
                setIsFetchingApi(false);
            }
        )
    }, [rootDispatchContext]);

    /**
     * @description callback permetant de filtrer la liste des contacts
     * @param _event
     */
    const filterListHandler = _event => {
        const updatedList = contactsBrowser.filter(item => {
            return (
                item.name.toLowerCase().search(_event.target.value.toLowerCase()) !== -1 ||
                item.contact.toLowerCase().search(_event.target.value.toLowerCase()) !== -1
            );
        });
        setFilterData(updatedList);
    }

    /**
     *@description
     * @param contact
     */
    const toggleContactRequest = (contact) => {
        let _contactsWaitingJoin = contactsWaitingJoin;
        if (contactsWaitingJoin.indexOf(contact.id) !== -1) {
            _contactsWaitingJoin = contactsWaitingJoin.filter((_) => _ !== contact.id);
            setContactsWaitingJoin(_contactsWaitingJoin);
        } else {
            const _contactsWaitingJoin = [...contactsWaitingJoin, contact.id];
            setContactsWaitingJoin(_contactsWaitingJoin);
        }
        localStorage.setItem("pi_contacts_join", JSON.stringify(_contactsWaitingJoin));
    }


    return (
        <div className="tyn-content  tyn-content-page">
            <div className="tyn-main tyn-content-inner" id="tynMain">
                <div className="container">
                    {isFetchingApi && <div className="d-flex justify-content-center align-items-center"
                                           style={{minHeight: "100%"}}>Chargement des contacts en cours...</div>}
                    {!isFetchingApi && (
                        <div className="card p-4">
                            <h4 className="pb-2">Rechercher par nom ou numéro de téléphone</h4>
                            <div className="form-group">
                                <div className="form-control-wrap">
                                    <div className="form-control-icon start">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                            <path
                                                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                                        </svg>
                                    </div>
                                    <input type="text" className="form-control form-control-solid" id="search-contact"
                                           placeholder="Entrez un nom ou un numéro de téléphone"
                                           onChange={filterListHandler}/>
                                </div>
                            </div>
                            <ul className="tyn-media-list gap gap-3 pt-4">
                                {filterData.map((_value, _key) => (
                                    <BrowserItem toggleContactRequest={toggleContactRequest}
                                                 isWaitingJoin={contactsWaitingJoin.indexOf(_value.id) !== -1}
                                                 contactBrowser={_value} key={_value.id}/>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Browser;