import Home from "../scenes/auth/Home";
import {Route, Routes} from "react-router-dom";
import React from "react";
import Register from "../scenes/auth/Register";
import PageNotFound from "../scenes/PageNotFound";

 function Index(){
     return (
         <Routes>
             <Route index path="/"  element={<Home/>}/>
             <Route index path="/register"  element={<Register/>}/>
             <Route path="*" element={<PageNotFound />} />
         </Routes>
     );
 }

export  default  Index;