import DefaultRouters from "./Default";
import AuthRouters from "./Auth";
import React, {useContext, useEffect, useState} from "react";
import {RootDispatchContext, RootStateContext} from "../providers/RootProvider";
import AppLoading from "../components/layouts/AppLoading";
import {ACCOUNT_ACTIONS, AUTH_ACTIONS, THEME_ACTIONS} from "../actions/constants/Index";
import toast, {Toaster} from "react-hot-toast";
import axios from "axios";
import {getMinifyInfo} from "../actions/server/user/browser";
import {loadJS} from "../utilities/common";


export default () => {
    const rootStateContext = useContext(RootStateContext);
    const rootDispatchContext = useContext(RootDispatchContext);
    const [isLoadingApp, setIsLoadingApp] = useState(true);


    useEffect(() => {
        /**
         *
         * @private
         */
        const _loadMinifyUserInfo = () => {
            getMinifyInfo(rootDispatchContext, (error) => toast.error(error.msg));
        }

        /**
         *
         * @private
         */
        const _loadRetrieveInitialState = () => {
            Promise.all([
                localStorage.getItem("pid_user"),
                localStorage.getItem("pid_theme"),
            ]).then((_values) => {

                //User
                let pid_user = _values[0];
                if (pid_user) {
                    pid_user = JSON.parse(pid_user);
                    if (pid_user) {
                        rootDispatchContext({
                            type: ACCOUNT_ACTIONS.SET_RETRIEVE,
                            payload: pid_user
                        });

                        axios.defaults.headers.common['X-Auth-Token'] = pid_user.auth.access_token;
                        _loadMinifyUserInfo();

                        rootDispatchContext({
                            type: AUTH_ACTIONS.SET_LOGGED_IN,
                            payload: {...pid_user.auth, isLoggedIn: true}
                        });

                    }
                }

                //Theme
                let pid_theme = _values[1];
                if (pid_theme) {
                    pid_theme = JSON.parse(pid_theme);
                    if (pid_theme) {
                        rootDispatchContext({
                            type: THEME_ACTIONS.SET_RETRIEVE,
                            payload: pid_theme
                        });
                        document.documentElement.setAttribute("data-bs-theme", pid_theme.mode);
                    }
                }
                setTimeout(() => {
                    setIsLoadingApp(false);
                }, 1000);
            });
        }


        _loadRetrieveInitialState();

    }, [rootDispatchContext]);


    useEffect(() => {
        loadJS(`https://meet.yuzspace.com/assets/js/bundle1c4a.js`);
    }, [])


    if (isLoadingApp) {
        return <AppLoading/>;
    }
    return <>
        {rootStateContext.auth.isLoggedIn ?
            <DefaultRouters/> :
            <AuthRouters/>}
        <Toaster/>
    </>
}
