import {AUTH_ACTIONS} from "../actions/constants/Index";

const defaultState = {
    isLoggedIn: false,
    token_access:null,
    token_email:null,
}
const reducer = (prevState = defaultState, action) => {
    switch (action.type) {
        case AUTH_ACTIONS.SET_LOGGED_IN:
            return {
                ...prevState,
                ...action.payload
            };
        case AUTH_ACTIONS.SET_LOGGED_OUT:
            return {
                ...prevState,
                isLoggedIn: false
            };
        default:
            return prevState;
    }
};
export default reducer;
