import React, { useContext, useState } from "react";
import {
  RootDispatchContext,
  RootStateContext,
} from "../../../../providers/RootProvider";
import { getPlaceholderImage } from "../../../../utilities/common";
import toast from "react-hot-toast";
import { requestToUpdate } from "../../../../actions/server/group";
import { dateFromApiTo } from "../../../../utilities/common";

const AboutTab = ({ profileDetails }) => {
  const currentPreview = getPlaceholderImage(profileDetails?.cover);
  const rootDispatchContext = useContext(RootDispatchContext);
  const rootStateContext = useContext(RootStateContext);
  const { user } = rootStateContext;
  const [isUpdating, setIsUpdating] = useState(false);
  const [image, setImage] = useState({
    preview: currentPreview,
    data: null,
  });
  const [dataForm, setDataForm] = useState({
    name: profileDetails.name,
    short_name: profileDetails.short_name,
    description: profileDetails.description,
    members: profileDetails.members,
  });

  /**
   * @description Permet de changer de facon intelligente la valeur
   * @param _event
   * @param key
   */
  const onChangeFormFieldHandler = (_event, key) => {
    let _currentForm = dataForm;
    _currentForm[key] = _event.target.value;
    setDataForm({
      ...dataForm,
      ..._currentForm,
    });
  };

  /**
   *@description
   * @param _event
   */
  const handleFileChange = (_event) => {
    if (_event.target.files.length !== 0) {
      setImage((prevState) => ({
        ...prevState,
        preview: URL.createObjectURL(_event.target.files[0]),
        data: _event.target.files[0],
      }));
    }
  };

  /**
   * @description Permet de traiter la creation du groupe
   * @param _event
   */
  const onSubmitHandler = (_event) => {
    _event.preventDefault();

    setIsUpdating((prevState) => !prevState);

    if (dataForm.name.trim().length === 0) {
      setIsUpdating(false);
      toast.error("Veuillez saisir le nom de votre groupe");
      return void 0;
    }
    if (dataForm.description.trim().length === 0) {
      setIsUpdating(false);
      toast.error("Veuillez saisir une description de votre groupe");
      return void 0;
    }

    if (!image.data && !profileDetails.cover) {
      setIsUpdating(false);
      toast.error("Votre groupe doit avoir au moin une image de couverture");
      return void 0;
    }

    let _formData = new FormData();
    _formData.append("cover", image.data);
    _formData.append("name", dataForm.name);
    _formData.append("short_name", dataForm.short_name);
    _formData.append("description", dataForm.description);

    requestToUpdate(
      rootDispatchContext,
      profileDetails._id,
      _formData,
      (group) => {
        setIsUpdating(false);
        toast.success("Groupe modifie avec succes !");
      },
      (error) => {
        toast.error(error.msg);
        setIsUpdating(false);
      }
    );
  };
  console.log(profileDetails);

  return (
    <>
      <div className="tyn-aside-row py-0">
        <ul className="nav nav-tabs nav-tabs-line">
          <li className="nav-item">
            <button
              className="nav-link active"
              data-bs-toggle="tab"
              data-bs-target="#group-profile-about-general"
              type="button"
            >
              Informations Générales
            </button>
          </li>
          <li className="nav-item">
            <button
              className="nav-link"
              data-bs-toggle="tab"
              data-bs-target="#group-profile-about-terms"
              type="button"
            >
              Règlements
            </button>
          </li>
          {profileDetails.author._id === user.id && (
            <li className="nav-item">
              <button
                className="nav-link"
                data-bs-toggle="tab"
                data-bs-target="#group-profile-about-edit"
                type="button"
              >
                Mise a jour
              </button>
            </li>
          )}
        </ul>
      </div>
      <div className="tyn-aside-row">
        <div className="tab-content">
          <div
            className="tab-pane show active"
            id="group-profile-about-general"
          >
            <div className="col-lg">
              <div className="card" style={{background: 'var(--bs-gray-400) !important'}}>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-5">
                      <p className="mb-0 text-primary fw-bold">Nom Complet : </p>
                    </div>
                    <div className="col-sm-7">
                      <p className="text-muted mb-0">{profileDetails?.name}</p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-5">
                      <p className="mb-0 text-primary fw-bold">Description : </p>
                    </div>
                    <div className="col-sm-7">
                      <p className="text-muted mb-0">
                        {profileDetails?.description}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-5">
                      <p className="mb-0 text-primary fw-bold">Date de création : </p>
                    </div>
                    <div className="col-sm-7">
                      <p className="text-muted mb-0">
                        {dateFromApiTo(profileDetails?.createdAt)}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-5">
                      <p className="mb-0 text-primary fw-bold">Crée par : </p>
                    </div>
                    <div className="col-sm-7">
                      <p className="text-muted mb-0">
                        {profileDetails?.author.name}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-pane" id="group-profile-about-terms">
            <div className="row g-3">terms</div>
          </div>
          {profileDetails.author._id === user.id && (
            <div className="tab-pane" id="group-profile-about-edit">
              <div className="row gy-5">
                <div className="col-12">
                  <div className="row gy-4">
                    <div className="col-lg-3">
                      <h6>Informations Générales</h6>
                      <div className="tyn-subtext">
                        Modifier les informations générales
                      </div>
                    </div>
                    <div className="col-lg-9">
                      <div className="row g-gs">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-label" htmlFor="name">
                              Nom
                            </label>
                            <div className="form-control-wrap">
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                placeholder="Nom du groupe"
                                value={dataForm.name}
                                onChange={(_event) =>
                                  onChangeFormFieldHandler(_event, "name")
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-label" htmlFor="short_name">
                              Alias
                            </label>
                            <div className="form-control-wrap">
                              <input
                                type="text"
                                className="form-control"
                                id="short_name"
                                name="short_name"
                                placeholder="Alias du groupe"
                                value={dataForm.short_name}
                                onChange={(_event) =>
                                  onChangeFormFieldHandler(_event, "short_name")
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="form-label" htmlFor="description">
                              Description
                            </label>
                            <div className="form-control-wrap">
                              <textarea
                                value={dataForm.description}
                                className="form-control"
                                name="description"
                                id=""
                                cols="30"
                                rows="5"
                                onChange={(_event) =>
                                  onChangeFormFieldHandler(
                                    _event,
                                    "description"
                                  )
                                }
                              ></textarea>
                              {/*<input type="text" className="form-control"
                                                                   id="description" name="description"
                                                                   placeholder="Description du groupe"
                                                                   value={dataForm.description}
                                                                   />*/}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row gy-4">
                    <div className="col-lg-3">
                      <h6>Photo de couverture</h6>
                      <div className="tyn-subtext">
                        Modifier la photo de couverture
                      </div>
                    </div>
                    <div className="col-lg-9">
                      <div className="tyn-choice-input-image">
                        <label className="label">
                          <input
                            type="file"
                            onChange={handleFileChange}
                            accept="image/png, image/jpg, image/jpeg"
                          />
                          <figure className="tyn-choice-input-figure d-flex justify-content-center align-items-center">
                            <img
                              src={image.preview}
                              className="tyn-choice-input-avatar"
                              alt="avatar"
                              style={{ objectFit: "cover" }}
                            />
                            <figcaption className="tyn-choice-input-figcaption">
                              <img
                                src="https://raw.githubusercontent.com/ThiagoLuizNunes/angular-boilerplate/master/src/assets/imgs/camera-white.png"
                                alt="avatar"
                              />
                            </figcaption>
                          </figure>
                        </label>
                      </div>
                      <div className="text-center">
                        <span className="small">Image de couverture</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 d-flex justify-content-end">
                  <div className="row gy-4 px-3">
                    <button
                      className={
                        !isUpdating
                          ? "btn btn-md btn-primary"
                          : "btn btn-md btn-light"
                      }
                      onClick={onSubmitHandler}
                      disabled={isUpdating}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-check2-circle"
                        viewBox="0 0 16 16"
                      >
                        <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"></path>
                        <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"></path>
                      </svg>
                      <span>{isUpdating ? "En cours..." : "Enregistrer"}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default AboutTab;
