import {AUTH_ACTIONS} from "../constants/Index";
import axios from "axios";
import ConfigsApi from "../../configs/Api";
import ConfigsEnv from "../../configs/Env";
import {excludeObjectByKeys} from "../../utilities/common";

export const authLogin = (dispatch, dataLogin, success, failure) => {
    const taskURI = ConfigsApi.authenticate[ConfigsEnv.deploy].endpoint + '/auth/login';
    axios.post(taskURI, dataLogin).then(v => {
        const data = v.data;
        if (data.error) {
            failure({
                code: 500,
                key: "ERROR",
                msg:data.error
            });
        } else {
            const _userInfo = data.user;
            let userInfo = excludeObjectByKeys(_userInfo,["__v","_id","password"]);
            userInfo.id = _userInfo._id;
            const auth = {
                access_token: data.access_token,
                access_expire_in: data.access_expire_in,
            };
            const user = {
                auth: auth,
                ...userInfo
            };
            success(user);
            dispatch(LoginSuccess({
                ...auth,
                isLoggedIn: true
            }));
        }
    }).catch(error => {
        failure({
            code: error.code,
            key: "ERROR",
            msg: error.message
        });
    });
}


export const authRegister = (dispatch, dataRegister, success, failure) => {
    const taskURI = ConfigsApi.authenticate[ConfigsEnv.deploy].endpoint + '/auth/register';
    axios.post(taskURI, dataRegister).then(v => {
        const data = v.data;
        if (data.error) {
            failure({
                code: 500,
                key: "ERROR",
                msg:data.error
            });
        } else {
            success(data.user);
            dispatch(RegisterSuccess(data.user));
        }
    }).catch(error => {
        failure({
            code: error.code,
            key: "ERROR",
            msg: error.message
        });
    });
}

const LoginSuccess = (auth) => {
    return {
        type: AUTH_ACTIONS.SET_LOGGED_IN,
        payload: auth
    };
}

const RegisterSuccess = (user) => {
    return {
        type: AUTH_ACTIONS.SET_REGISTER_IN,
        payload: user
    };
}


export const authLogout = (dispatch, dataLogout, success, failure) => {

    //Call
    let dataResultAxios = {
        is_error: false
    };

    if (dataResultAxios.is_error) {
        failure(dataResultAxios);
    } else {
         success(dataResultAxios);
        return  dispatch(LogoutSuccess());
    }
}


const LogoutSuccess = () => {
    return {
        type: AUTH_ACTIONS.SET_LOGGED_OUT
    };
}

/**
 * @description Permet de retourner les informations de l'utilisateur connecte
 * @returns {any|null}
 */
export const getCurrentUser = () => {
    let pid_user = localStorage.getItem("pid_user");
    if (!pid_user) return null;
    pid_user = JSON.parse(pid_user);
    if (pid_user){
        return pid_user;
    }
    return  null;

}


