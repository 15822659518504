import React, {useEffect, useRef} from "react";

const StartingCallRequestJoin = ({callProfile,userVideo}) => {

   return (
       <video
           className="rounded-full h-100 w-100"
           playsInline
           muted
           ref={userVideo}
           autoPlay
       />
   );

}
export  default StartingCallRequestJoin;