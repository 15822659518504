import {Link} from "react-router-dom";
import React from "react";

export  default  ()=>{
    return (
        <div className="tyn-appbar-logo">
            <Link className="tyn-logo" to="/">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    id="Calque_1"
                    data-name="Calque 1"
                    viewBox="0 0 430 400"
                >
                    <defs>
                        <linearGradient
                            id="sans_nom_1"
                            x1={-198.8}
                            x2={487.57}
                            y1={-80.48}
                            y2={323.07}
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop offset={0} stopColor="#e23a00"/>
                            <stop offset={1} stopColor="#ff8b00"/>
                        </linearGradient>
                        <style>{".cls-3{fill:#fff}"}</style>
                    </defs>
                    <path
                        d="M191.6 348.78a154.75 154.75 0 0 1-34.18 3.84 151.83 151.83 0 0 1-83.23-24.69L0 352.62l26-74.93A152.61 152.61 0 0 1 157.42 47.38a154.75 154.75 0 0 1 34.18 3.84 172.14 172.14 0 0 0 0 297.56Z"
                        style={{
                            fill: "#021f42",
                        }}
                    />
                    <path
                        d="M430 200v151.76H278.22A151.79 151.79 0 0 1 170.91 92.67a151.06 151.06 0 0 1 107.31-44.43A151.74 151.74 0 0 1 430 200Z"
                        style={{
                            fill: "url(#sans_nom_1)",
                        }}
                    />
                    <circle cx={223.63} cy={200} r={18.4} className="cls-3"/>
                    <circle cx={278.24} cy={200} r={18.4} className="cls-3"/>
                    <circle cx={332.85} cy={200} r={18.4} className="cls-3"/>
                </svg>
            </Link>
        </div>
    );
}