import React from "react";

function AppPreventLoading() {
    return (
        <div style={{ position:"absolute", top:0,left:0, width:"100%", height:"100%", zIndex:9999, background:"rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity, 1))"}}>
            <div className="d-flex justify-content-center flex-column" style={{minHeight:"100vh", alignItems:"center"}}>
                <div className="spinner-border" role="status"></div>
                <div>Chargement en cours...</div>
            </div>
        </div>

    );
}

export default AppPreventLoading;