import {THEME_ACTIONS} from "../actions/constants/Index";

const defaultState = {
    mode: "light"
}
const reducer = (prevState = defaultState, action) => {
    switch (action.type) {
        case THEME_ACTIONS.SET_RETRIEVE:
            return {
                ...prevState,
                mode: action.payload.mode
            };
        default:
            return prevState;
    }
};
export default reducer;
