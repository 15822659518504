import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import ConfigsApi from "../../../../../../configs/Api";
import ConfigsEnv from "../../../../../../configs/Env";

const AudioRecorder = ({onPreviewAudioRecorder,onUploadSuccess}) => {
    const [showButtonRecorder, setShowButtonRecorder] = useState(true);
    const [permission, setPermission] = useState(false);
    const [stream, setStream] = useState(null);
    const mimeType = "audio/webm";
    const mediaRecorder = useRef(null);
    const [recordingStatus, setRecordingStatus] = useState("inactive");
    const [audioChunks, setAudioChunks] = useState([]);

    /**
     * @description Permet de demarrer l'enregistrement
     * @returns {Promise<void>}
     */
    const startRecording = async () => {
        const _stream = (showButtonRecorder && recordingStatus === "inactive") && await getMicrophonePermission();
        setRecordingStatus("recording");
        mediaRecorder.current = new MediaRecorder(_stream, {type: mimeType});
        mediaRecorder.current.start();
        let localAudioChunks = [];
        mediaRecorder.current.ondataavailable = (event) => {
            if (typeof event.data === "undefined") return;
            if (event.data.size === 0) return;
            localAudioChunks.push(event.data);
        };
        setAudioChunks(localAudioChunks);
    }

    /**
     * @description Permet de stopper l'enregistrement
     */
    const stopRecording = () => {
        setRecordingStatus("inactive");
        mediaRecorder.current.stop();
        mediaRecorder.current.onstop = () => {
            const audioBlob = new Blob(audioChunks, {type: mimeType});
            uploadRecorder(audioBlob);
            const audioUrl = URL.createObjectURL(audioBlob);
            onPreviewAudioRecorder(audioUrl);
            setAudioChunks([]);
        }
        stopStream(mediaRecorder.current.stream);
    }

    const uploadRecorder = async (blob) => {
        const formData = new FormData();
        formData.append('audio', blob, 'audio_recording.wav');
        try {
            const response = await axios.post(ConfigsApi.default[ConfigsEnv.deploy].endpoint + '/conversation/upload/audio', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            const responseData = response.data;
            onUploadSuccess(responseData);
        } catch (error) {
            console.error('Error uploading audio:', error);
        }
    }

    /**
     * @description Permet de stopper le flux
     * @param _stream
     */
    const stopStream = (_stream) => {
        _stream.getTracks().forEach(track => {
            track.stop();
            track.enabled = false
        });
    }

    const getMicrophonePermission = async () => {
        if ("MediaRecorder" in window) {
            try {
                const streamData = await navigator.mediaDevices.getUserMedia({
                    audio: true,
                    video: false,
                });
                setStream(streamData);
                setPermission(true);
                return streamData;
            } catch (err) {
                return null;
            }
        } else {
            alert("The MediaRecorder API is not supported in your browser.");
            return null;
        }
    }

    useEffect(() => {
        return () => {
            setPermission(false);
        }
    }, []);

    return (
        <>
            {showButtonRecorder && recordingStatus === "inactive" ? (
                <li>
                    <button className="btn btn-icon btn-white btn-md btn-pill" onClick={startRecording}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             className="bi bi-mic-fill" viewBox="0 0 16 16">
                            <path d="M5 3a3 3 0 0 1 6 0v5a3 3 0 0 1-6 0V3z"/>
                            <path
                                d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z"/>
                        </svg>
                    </button>
                </li>
            ) : null}
            {recordingStatus === "recording" ? (
                <li>
                    <button className="btn btn-icon btn-danger btn-md btn-pill" onClick={stopRecording}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             className="bi bi-mic-fill" viewBox="0 0 16 16">
                            <path d="M5 3a3 3 0 0 1 6 0v5a3 3 0 0 1-6 0V3z"/>
                            <path
                                d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z"/>
                        </svg>
                    </button>
                </li>
            ) : null}
        </>
    );
};
export default AudioRecorder;