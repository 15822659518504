import React from "react";
import ActionsDropdown from "./ActionsDropdown";
import AuthorSender from "./AuthorSender";
import TimeAgoBubble from "./TimeAgoBubble";

const Audio = ({message, conversation, handleDeleteMessage, handleReplyTo, context, showActionDropdown}) => {
    if (message.audio) {
        return (
            <div className="tyn-reply-bubble">
                <div className="tyn-reply-media"
                     style={{background: context === "incoming" ? 'var(--reply-incoming-bg)' : 'var(--reply-outgoing-bg)', color:context === "incoming" ? 'var(--incoming-message-text)' : 'var(--outgoing-message-text)'}}>
                    {message.content.trim() === "" && <AuthorSender/>}
                    <audio src={message.audio.url} controls></audio>
                    {message.content.trim() === "" && <TimeAgoBubble/>}
                </div>
                {showActionDropdown === true && (
                    <ActionsDropdown handleReplyTo={handleReplyTo} handleDeleteMessage={handleDeleteMessage}
                                     conversation={conversation} message={message} context={context}/>
                )}
            </div>

        );
    } else {
        return <></>;
    }
}
export default Audio;