import React from "react";

export default ({selectedVideos,handleRemoveVideo})=>{

    return (
        <div className={"tyn-reply " + (selectedVideos.length===0 ? 'd-none':'')} style={{paddingBottom:0,position:'relative'}}>
            <div className="tyn-reply-item" style={{paddingBottom:0, position:'absolute', bottom:0}}>
                <div className="tyn-reply-group">
                    <div className="tyn-reply-bubble w-100">
                        <div className="tyn-reply-media">
                            {selectedVideos.map((video) => (
                                <div className="glightbox tyn-thumb position-relative" data-gallery="media-photo"
                                     key={video.id}>
                                    {video?.uploadProgress === 100 && (
                                        <button className="position-absolute link-danger btn btn-link"
                                                onClick={() => handleRemoveVideo(video.id)} style={{zIndex:10}}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                 fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                <path
                                                    d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"></path>
                                                <path fillRule="evenodd"
                                                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"></path>
                                            </svg>
                                        </button>
                                    )}
                                    <video controls className="tyn-video" data-src={"" + video?.src} style={{width:'100%', height:'100%'}}>
                                        <source
                                            src={URL.createObjectURL(video.file)}
                                        />
                                    </video>
                                    {video?.uploadProgress !== 100 && (
                                        <div
                                            className="d-flex justify-content-center position-absolute align-items-center"
                                            style={{
                                                left: 0,
                                                top: 0,
                                                zIndex: 10,
                                                width: '100%',
                                                height: '100%',
                                                background: '#0f172a85'
                                            }}>
                                            <span className="spinner-border spinner-border-sm text-white" role="status"
                                                  aria-hidden="true"></span>
                                        </div>
                                    )}
                                </div>))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
