import {Link} from "react-router-dom";
import React, {useCallback, useContext, useState} from "react";
import {RootDispatchContext, RootStateContext} from "../../../../providers/RootProvider";
import {useSocket} from "../../../../providers/SocketProvider";
import {authLogout} from "../../../../actions/dispatch/auth";
import toast from "react-hot-toast";
import {getBaseUrlLocation, getPlaceholderImage} from "../../../../utilities/common";
import NotificationHeader from "../../../notification/NotificationHeader";
import SOCKET_TYPE_EVENTS_USER from "../../../../actions/constants/sockets/user";
import SOCKET_TYPE_EVENTS_GROUP from "../../../../actions/constants/sockets/group";

export default () => {
    const rootDispatchContext = useContext(RootDispatchContext);
    const rootStateContext = useContext(RootStateContext);
    const {theme,user} = rootStateContext;
    const socket = useSocket();
    const [currentThemeMode,setCurrentThemeMode] = useState(theme.mode);



    const onChangeCheckedHandler = useCallback((_event) => {
        localStorage.setItem("pid_theme",JSON.stringify({
            ...theme,
            mode:_event.target.value
        }));
        document.documentElement.setAttribute("data-bs-theme", _event.target.value);
        setCurrentThemeMode(_event.target.value);
    },[theme]);


    const toLogoutButtonHandler = () => {
        socket.disconnect();
        socket.off(SOCKET_TYPE_EVENTS_USER.NEW_CONNECTION);
        socket.off(SOCKET_TYPE_EVENTS_USER.LOGIN_SUCCESS);
        socket.off(SOCKET_TYPE_EVENTS_USER.FRIEND_ONLINE);
        socket.off(SOCKET_TYPE_EVENTS_USER.FRIEND_OFFLINE);
        socket.off(SOCKET_TYPE_EVENTS_USER.USER_DISCONNECTED);
        socket.off(SOCKET_TYPE_EVENTS_GROUP.USER_DISCONNECTED_IN_GROUP);

        window.location = getBaseUrlLocation();
        authLogout(rootDispatchContext, {}, () => {
            localStorage.removeItem("pid_user");
            toast.success("Déconnexion reussie avec succes !");
        }, (error) => {
            toast.error("Oups ! Une erreur est survenue");
        })

    }
    return (
        <ul className="tyn-appbar-nav tyn-appbar-nav-end">
            <li className="tyn-appbar-item dropdown">
                <Link className="tyn-appbar-link dropdown-toggle" data-bs-toggle="dropdown" to="#"
                      data-bs-offset="0,10">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         className="bi bi-grid-fill" viewBox="0 0 16 16">
                        <path
                            d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zm8 0A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm-8 8A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm8 0A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3z"/>
                    </svg>
                    <span className="d-none">Menu</span>
                </Link>
                <div className="dropdown-menu dropdown-menu-auto dropdown-menu-end">
                    <ul className="tyn-list-links">
                        <li>
                            <h6 className="tyn-list-links-heading">Menus Principal</h6>
                        </li>
                        <li>
                            <Link to="/account/profile#profile-groups">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chat-square-text" viewBox="0 0 16 16">
                                    <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                    <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                                </svg>
                                <span>Groupes</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/account/profile#profile-friends">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-person-lines-fill" viewBox="0 0 16 16">
                                    <path
                                        d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z"/>
                                </svg>
                                <span>Ccontacts</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="stories.html">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-subtract" viewBox="0 0 16 16">
                                    <path
                                        d="M0 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2H2a2 2 0 0 1-2-2V2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2z"/>
                                </svg>
                                <span>Stories</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="chat-bot-s2.html">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-person-bounding-box" viewBox="0 0 16 16">
                                    <path
                                        d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5z"/>
                                    <path
                                        d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                                </svg>
                                <span>Chaines</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="pricing.html">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-person-fill-up" viewBox="0 0 16 16">
                                    <path
                                        d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.354-5.854 1.5 1.5a.5.5 0 0 1-.708.708L13 11.707V14.5a.5.5 0 0 1-1 0v-2.793l-.646.647a.5.5 0 0 1-.708-.708l1.5-1.5a.5.5 0 0 1 .708 0ZM11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                                    <path
                                        d="M2 13c0 1 1 1 1 1h5.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.544-3.393C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4Z"/>
                                </svg>
                                <span>Import. Contacts</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="faq.html">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-question-octagon-fill" viewBox="0 0 16 16">
                                    <path
                                        d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353L11.46.146zM5.496 6.033a.237.237 0 0 1-.24-.247C5.35 4.091 6.737 3.5 8.005 3.5c1.396 0 2.672.73 2.672 2.24 0 1.08-.635 1.594-1.244 2.057-.737.559-1.01.768-1.01 1.486v.105a.25.25 0 0 1-.25.25h-.81a.25.25 0 0 1-.25-.246l-.004-.217c-.038-.927.495-1.498 1.168-1.987.59-.444.965-.736.965-1.371 0-.825-.628-1.168-1.314-1.168-.803 0-1.253.478-1.342 1.134-.018.137-.128.25-.266.25h-.825zm2.325 6.443c-.584 0-1.009-.394-1.009-.927 0-.552.425-.94 1.01-.94.609 0 1.028.388 1.028.94 0 .533-.42.927-1.029.927z"/>
                                </svg>
                                <span>Faq</span>
                            </Link>
                        </li>
                        <li>
                            <h6 className="tyn-list-links-heading">Services</h6>
                        </li>
                        <li>
                            <Link to="ui-chat-replies.html">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-chat-right-text-fill" viewBox="0 0 16 16">
                                    <path
                                        d="M16 2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h9.586a1 1 0 0 1 .707.293l2.853 2.853a.5.5 0 0 0 .854-.353V2zM3.5 3h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1 0-1zm0 2.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1 0-1zm0 2.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1z"/>
                                </svg>
                                <span>Tickets</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </li>
            <NotificationHeader/>
            <li className="tyn-appbar-item">
                <Link className="d-inline-flex dropdown-toggle" data-bs-auto-close="outside" data-bs-toggle="dropdown"
                      to="#" data-bs-offset="0,10">
                    <div className="tyn-media tyn-size-lg tyn-circle">
                        <img src={getPlaceholderImage(user?.photo)} alt="" style={{width:50,height:50}}/>
                    </div>
                </Link>
                <div className="dropdown-menu dropdown-menu-end">
                    <div className="dropdown-gap">
                        <div className="tyn-media-group">
                            <div className="tyn-media tyn-size-lg">
                                <img src={getPlaceholderImage(user?.photo)} alt="" className="rounded-pill"/>
                            </div>
                            <div className="tyn-media-col">
                                <div className="tyn-media-row">
                                    <h6 className="name">{user?.name}</h6>
                                    <div className="indicator varified">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor" className="bi bi-check-circle-fill"
                                             viewBox="0 0 16 16">
                                            <path
                                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                                        </svg>
                                    </div>
                                </div>
                                <div className="tyn-media-row has-dot-sap">
                                    <p className="content">{user.contact}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dropdown-gap">
                        <div className="d-flex gap gap-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-moon-fill" viewBox="0 0 16 16">
                                <path
                                    d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278z"/>
                            </svg>
                            <div>
                                <h6>Mode Sombre</h6>
                                <ul className="d-flex align-items-center gap gap-3">
                                    <li className="inline-flex">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="themeMode" id="dark"
                                                   value="dark" onChange={onChangeCheckedHandler} checked={currentThemeMode ==="dark"}/>
                                            <label className="form-check-label small" htmlFor="dark"> Oui</label>
                                        </div>
                                    </li>
                                    <li className="inline-flex">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="themeMode" id="light"
                                                   value="light" onChange={onChangeCheckedHandler} checked={currentThemeMode ==="light"}/>
                                            <label className="form-check-label small" htmlFor="light"> Non </label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <ul className="tyn-list-links">
                        <li>
                            <Link to="/account/profile">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-person" viewBox="0 0 16 16">
                                    <path
                                        d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"/>
                                </svg>
                                <span>Gestion de profil</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="account/profile#profile-settings">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-gear" viewBox="0 0 16 16">
                                    <path
                                        d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z"/>
                                    <path
                                        d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z"/>
                                </svg>
                                <span>Paramètres</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="account/profile#profile-settings">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-unlock" viewBox="0 0 16 16">
                                    <path
                                        d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2zM3 8a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1H3z"/>
                                </svg>
                                <span>Modifier votre mot de passe</span>
                            </Link>
                        </li>
                        <li className="dropdown-divider"></li>
                        <li>
                            <button className="link" onClick={toLogoutButtonHandler}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-power" viewBox="0 0 16 16">
                                    <path d="M7.5 1v7h1V1h-1z"/>
                                    <path
                                        d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z"/>
                                </svg>
                                <span>Déconnexion</span>
                            </button>
                        </li>
                    </ul>
                </div>
            </li>
        </ul>
    );
}