import React, {useState} from "react";
import ContactStartConversationItem from "./ContactStartConversationItem";

export default ({contacts})=>{

    const [filterData, setFilterData] = useState(contacts);

    /**
     * @description callback permetant de filtrer la liste des contacts
     * @param _event
     */
    const filterListHandler = _event => {
        const updatedList = contacts.filter(item => {
            return (
                item.name.toLowerCase().search(_event.target.value.toLowerCase()) !== -1 ||
                item.contact.toLowerCase().search(_event.target.value.toLowerCase()) !== -1
            );
        });
        setFilterData(updatedList);
    }


    return (
        <div className="modal fade" tabIndex="-1" id="newChat">
            <div className="modal-dialog modal-dialog-centered modal-md">
                <div className="modal-content border-0">
                    <div className="modal-body p-4">
                        <h4 className="pb-2">Rechercher par Nom/Numéro</h4>
                        <div className="form-group">
                            <div className="form-control-wrap">
                                <div className="form-control-icon start">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                    </svg>
                                </div>
                                <input type="text" className="form-control form-control-solid" id="search-contact" placeholder="Rechercher par nom/numéro" onChange={filterListHandler}/>
                            </div>
                        </div>
                        <ul className="tyn-media-list gap gap-3 pt-4">
                            {filterData && filterData.map((_value, _key) => (
                                <ContactStartConversationItem contact={_value} key={_value._id}/>
                            ))}
                        </ul>
                    </div>
                    <button className="btn btn-md btn-icon btn-pill btn-white shadow position-absolute top-0 end-0 mt-n3 me-n3" data-bs-dismiss="modal">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    );
}