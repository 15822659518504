export const _Page ={
    Height : window.innerHeight,
    Width : window.innerWidth,
}
export const _Style = function(name,value){
    if(name[0]!=='-') name = '--'+name //allow passing with or without --
    if(value) document.documentElement.style.setProperty(name, value)
    return getComputedStyle(document.documentElement).getPropertyValue(name);
}
export const _Body = document.querySelector('body');
export const _Breakpoints = {
    sm : parseInt(_Style('--bs-breakpoint-sm')),
    md : parseInt(_Style('--bs-breakpoint-md')),
    lg : parseInt(_Style('--bs-breakpoint-lg')),
    xl : parseInt(_Style('--bs-breakpoint-xl')),
    xxl : parseInt(_Style('--bs-breakpoint-xxl')),
}
