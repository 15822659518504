import React from "react";
import {getPlaceholderImage} from "../../utilities/common";
const  AddToGroupItem =({contact,isAdded,toggleContactRequest})=> {



    return (
        <li data-id={contact._id}>
            <div className="tyn-media-group">
                <div className="tyn-media">
                    <img src={getPlaceholderImage(contact?.photo)} alt=""/>
                </div>
                <div className="tyn-media-col">
                    <div className="tyn-media-row">
                        <h6 className="name">{contact?.name} <span className="small">{contact?.username}</span></h6>
                    </div>
                    <div className="tyn-media-row">
                        <p className="content">{contact?.contact}</p> /
                        <p className="content">{contact?.email}</p>
                    </div>
                </div>
                <ul className="tyn-media-option-list me-n1">
                    <button className={!isAdded ? "btn btn-md btn-primary" : "btn btn-md btn-success"} onClick={()=> toggleContactRequest(contact)}>
                        {isAdded ? (
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                                <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"></path>
                            </svg>
                        ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-plus-fill" viewBox="0 0 16 16">
                                <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"></path>
                                <path fillRule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"></path>
                            </svg>
                        )}
                        <span>{isAdded ? "Ajouté":"Ajouter"}</span>
                    </button>

                </ul>
            </div>
        </li>

    );
}
export default  AddToGroupItem;