import {_Body, _Breakpoints, _Page} from "./Utilities/Index";

export const ChatAside = (prefixRootElem) => {

    let chatRootElem = document.getElementById('tynMain-' + prefixRootElem);
    if (chatRootElem) {
        let elm = chatRootElem.querySelector('.js-toggle-chat-options');
        if (elm) {
            let target = document.getElementById('tynChatAside-' + prefixRootElem);
            target.insertAdjacentHTML('beforebegin', `<div class="tyn-overlay js-toggle-chat-options" ></div>`);
            let overlay = chatRootElem.querySelector('.tyn-overlay.js-toggle-chat-options');

            function asideshow() {
                elm.classList.add('active');
                target.classList.add('show-aside');
                chatRootElem.classList.add('aside-shown');
                if (_Page.Width < _Breakpoints.xl) {
                    overlay.classList.add('active');
                }
            }

            function asidehide() {
                elm.classList.remove('active');
                target.classList.remove('show-aside');
                chatRootElem.classList.remove('aside-shown');
                if (_Page.Width < _Breakpoints.xl) {
                    overlay.classList.remove('active');
                }
            }

            if (_Page.Width > _Breakpoints.xl) {
                asideshow();
            }

            elm.addEventListener('click', (e) => {
                e.preventDefault();
                if (!chatRootElem.classList.contains('aside-shown')) {
                    asideshow();
                } else {
                    asidehide()
                }
            })

            overlay.addEventListener('click', (e) => {
                e.preventDefault();
                asidehide();
            })

            const chatObserver = new ResizeObserver((entries) => {
                for (const entry of entries) {
                    if (entry.contentRect.width > _Breakpoints.xl) {
                        overlay.classList.remove('active');
                        chatRootElem.classList.remove('aside-collapsed');
                    } else {
                        setTimeout(() => {
                            chatRootElem.classList.add('aside-collapsed');
                        }, 1000);
                    }

                    if (entry.contentRect.width < _Breakpoints.xl) {
                        if (!chatRootElem.classList.contains('aside-collapsed')) {
                            asidehide();
                        }

                    }
                }
            });

            chatObserver.observe(_Body);
        }
    }
}

export const ChatReplySearch = (prefixRootElem) => {
    let chatRootElem = document.getElementById('tynMain-' + prefixRootElem);
    if (chatRootElem) {
        let elm = chatRootElem.querySelectorAll('.js-toggle-chat-search');
        if (elm) {
            elm.forEach(item => {
                item.addEventListener('click', (e) => {
                    e.preventDefault();
                    chatRootElem.querySelector('#tynChatSearch').classList.toggle('active');
                })
            })
        }
    }

}

export const ChatSettleInputFocus = (prefixRootElem) => {
    let chatRootElem = document.getElementById('tynMain-' + prefixRootElem);
    if (chatRootElem) {
        let chatInput = chatRootElem.querySelector('[data-id="tynChatInput"]');
        if (chatInput) {
            chatInput.focus();
        }
    }
}
