import React, {useEffect} from 'react';
import {RootProvider} from "./providers/RootProvider";
import Routers from "./routers/Index";
import timeAgoLocale from './hooks/timeAgoLocale';

function App() {

    useEffect(() => {
        timeAgoLocale();

    }, []);

    return (
        <RootProvider>
            <Routers/>
        </RootProvider>

    );
}

export default App;
