import React from "react";
 const Index = ({conversation})=>{
     return (
         <>
             <div className="flex-grow-1">
                 <div className="form-group">
                     <div className="form-control-wrap form-control-plaintext-wrap">
                         <div className="form-control-icon start">
                             <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                  className="bi bi-search" viewBox="0 0 16 16">
                                 <path
                                     d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                             </svg>
                         </div>
                         <input type="text" className="form-control form-control-plaintext" id="searchInThisChat"
                                placeholder="Rechercher dans cette conversation"/>
                     </div>
                     <div className="d-flex">

                     </div>
                 </div>
             </div>
             <div className="d-flex align-items-center gap gap-3">
                 <ul className="tyn-list-inline ">
                     <li>
                         <button className="btn btn-icon btn-sm btn-transparent">
                             <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                  className="bi bi-chevron-up" viewBox="0 0 16 16">
                                 <path fillRule="evenodd"
                                       d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
                             </svg>
                         </button>
                     </li>
                     <li>
                         <button className="btn btn-icon btn-sm btn-transparent">
                             <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                  className="bi bi-chevron-down" viewBox="0 0 16 16">
                                 <path fillRule="evenodd"
                                       d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                             </svg>
                         </button>
                     </li>
                 </ul>
                 <ul className="tyn-list-inline ">
                     <li>
                         <button className="btn btn-icon btn-md btn-light js-toggle-chat-search">
                             <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                  className="bi bi-x-lg" viewBox="0 0 16 16">
                                 <path
                                     d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                             </svg>
                         </button>
                     </li>
                 </ul>
             </div>
         </>
     );
 }

export default Index;
