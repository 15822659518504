import {Route, Routes} from "react-router-dom";
import React from "react";
import Home from "../scenes/default/Home";
import {MasterLayoutProvider} from "../components/layouts/default/Master";
import ConversationHome from "../scenes/default/conversation/Home";
import PageNotFound from "../scenes/PageNotFound";
import ContactBrowser from "../scenes/default/contact/Browser";
import ContactProfileView from "../scenes/default/contact/ProfileView";
import AccountProfile from "../scenes/default/account/Profile";
import GroupProfileView from "../scenes/default/group/ProfileView";
import {SocketProvider} from "../providers/SocketProvider";
import RequestCalling from "../scenes/default/videoCall/RequestCalling";
import ReceivedCalling from "../scenes/default/videoCall/success/ReceivedCalling";
import InitCalling from "../scenes/default/videoCall/success/InitCalling";

function Index(){
    return (
        <SocketProvider>
            <MasterLayoutProvider>
                <Routes>
                    <Route index path="/"  element={<Home/>}/>
                    <Route path="/conversation"  element={<ConversationHome/>}/>
                    <Route path="/contact/browser"  element={<ContactBrowser/>}/>
                    <Route path="/contact/profile/view/:contactId"  element={<ContactProfileView/>}/>
                    <Route path="/account/profile"  element={<AccountProfile/>}/>
                    <Route path="/group/profile/view/:groupId"  element={<GroupProfileView/>}/>
                    <Route exact path="/video-call/request-calling/:callingId/:roomID"  element={<RequestCalling/>}/>

                    <Route  path="/video-call/init/:callingId/:roomID"  element={<InitCalling/>}/>
                    <Route  path="/video-call/received/:callingId/:roomID"  element={<ReceivedCalling/>}/>
                    <Route path="*" element={<PageNotFound />} />
                </Routes>
            </MasterLayoutProvider>
        </SocketProvider>

    );
}

export  default  Index;