import axios from "axios";
import ConfigsApi from "../../../configs/Api";
import ConfigsEnv from "../../../configs/Env";
import {ACCOUNT_ACTIONS} from "../../constants/Index";
import {excludeObjectByKeys} from "../../../utilities/common";
import errorMiddleware from "../errorMiddleware";

/**
 * @description
 * @param dispatch
 * @param dataForm
 * @param success
 * @param failure
 */
export const updateProfile = (dispatch, dataForm, success, failure) => {
    const taskURI = ConfigsApi.default[ConfigsEnv.deploy].endpoint + '/account/profile';
    axios.put(taskURI, dataForm).then(v => {
        const data = v.data;
        if (data.error) {
            errorMiddleware({
                ...data,
                code: 500,
                key: "ERROR",
                msg:data.error
            },dispatch,failure);
        } else {
            const _userInfo = data.user;
            let userInfo = excludeObjectByKeys(_userInfo,["__v","_id","password"]);
            userInfo.id = _userInfo._id;
            success(userInfo);
            dispatch(updateProfileSuccess(userInfo));
        }
    }).catch(error => {
        errorMiddleware({
            ...error,
            code: error.code,
            key: "ERROR",
            msg: error.message
        },dispatch,failure);
    });
}

/**
 *
 * @param dispatch
 * @param success
 * @param failure
 */
export const getAuthInfoDetails = (dispatch, success, failure) => {
    const taskURI = ConfigsApi.default[ConfigsEnv.deploy].endpoint + '/account/auth/info-details';
    axios.get(taskURI).then(v => {
        const data = v.data;
        if (data.error) {
            errorMiddleware({
                ...data,
                code: 500,
                key: "ERROR",
                msg:data.error
            },dispatch,failure);
        } else {
            const _userInfo = data.userInfo;
            let userInfo = excludeObjectByKeys(_userInfo,["__v","_id","password"]);
            userInfo.id = _userInfo._id;
            success(userInfo);
            //dispatch(updateProfileSuccess(userInfo)); bug au niveau du local storage car il y aura trop de donnee
        }
    }).catch(error => {
        errorMiddleware({
            ...error,
            code: error.code,
            key: "ERROR",
            msg: error.message
        },dispatch,failure);
    });
}


/**
 *
 * @param dispatch
 * @param contactId
 * @param success
 * @param failure
 */
export const getContactInfoDetails = (dispatch, contactId,success, failure) => {
    const taskURI = ConfigsApi.default[ConfigsEnv.deploy].endpoint + '/account/contact/info-details/'+contactId;
    axios.get(taskURI).then(v => {
        const data = v.data;
        if (data.error) {
            errorMiddleware({
                ...data,
                code: 500,
                key: "ERROR",
                msg:data.error
            },dispatch,failure);
        } else {
            const _userInfo = data.userInfo;
            let userInfo = excludeObjectByKeys(_userInfo,["__v","_id","password","openedConversationsTab","request_received","request_sent"]);
            userInfo.id = _userInfo._id;
            success(userInfo);
        }
    }).catch(error => {
        errorMiddleware({
            ...error,
            code: error.code,
            key: "ERROR",
            msg: error.message
        },dispatch,failure);
    });
}

/**
 * @description Permet de mettre le state a jour
 * @param userInfo
 * @returns {{payload, type: string}}
 */
const updateProfileSuccess = (userInfo) => {
    return {
        type: ACCOUNT_ACTIONS.UPDATE_PROFILE,
        payload: userInfo
    };
}


/**
 *
 * @param dispatch
 * @param userId
 * @param success
 * @param failure
 */
export const getAuthLoadMediaImage = (dispatch, userId,success, failure) => {
    const taskURI = ConfigsApi.default[ConfigsEnv.deploy].endpoint + '/media/by-user/'+userId+'/image';
    axios.get(taskURI).then(v => {
        const data = v.data;
        if (data.error) {
            errorMiddleware({
                ...data,
                code: 500,
                key: "ERROR",
                msg:data.error
            },dispatch,failure);
        } else {
            success(data.medias);
        }
    }).catch(error => {
        errorMiddleware({
            ...error,
            code: error.code,
            key: "ERROR",
            msg: error.message
        },dispatch,failure);
    });
}


/**
 *
 * @param dispatch
 * @param userId
 * @param success
 * @param failure
 */
export const getAuthLoadMediaAll = (dispatch, userId,success, failure) => {
    const taskURI = ConfigsApi.default[ConfigsEnv.deploy].endpoint + '/media/by-user/'+userId+'/other';
    axios.get(taskURI).then(v => {
        const data = v.data;
        if (data.error) {
            errorMiddleware({
                ...data,
                code: 500,
                key: "ERROR",
                msg:data.error
            },dispatch,failure);
        } else {
            success(data.medias);
        }
    }).catch(error => {
        errorMiddleware({
            ...error,
            code: error.code,
            key: "ERROR",
            msg: error.message
        },dispatch,failure);
    });
}