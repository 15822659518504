import React, {useState, useRef, useEffect} from 'react';
import Picker from 'emoji-picker-react';
import chatInput from "./ChatInput";

const EmojiPickerButton = ({onSelectEmoji,chatInputRef}) => {
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const buttonRef = useRef(null);
    const pickerParentRef = useRef(null);
    let preClassButton ='btn btn-icon btn-light btn-md btn-pill';
    const handleEmojiClick = (emojiObject,event) => {
        onSelectEmoji(emojiObject);
    };

    const toggleEmojiPicker = () => {
        setShowEmojiPicker(!showEmojiPicker);
    }

    const closeEmojiPicker = (event) => {
        console.log(event.target,showEmojiPicker);
        if (
            pickerParentRef.current &&
            !pickerParentRef.current.contains(event.target) &&
            showEmojiPicker
        ) {
            setShowEmojiPicker(false);
        }
    }

    // Ajoutez un écouteur d'événements pour fermer le panneau d'emoji lors d'un clic en dehors de celui-ci
    /*useEffect(() => {
       // let chatRootElem = document.getElementById('tynMain-' + prefixRootElem);

        document.addEventListener('mousedown', closeEmojiPicker);
        return () => {
            document.removeEventListener('mousedown', closeEmojiPicker);
        };
    }, [showEmojiPicker]);*/

    return (
        <li className="d-none d-sm-block" style={{ position:"relative"}}>
            <button className={preClassButton + (showEmojiPicker ?  ' active':'')} ref={buttonRef} onClick={toggleEmojiPicker}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     className="bi bi-emoji-smile-fill" viewBox="0 0 16 16">
                    <path
                        d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zM4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683zM10 8c-.552 0-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5S10.552 8 10 8z"/>
                </svg>
            </button>
            {showEmojiPicker && (
                <div style={{ position: 'absolute', top: '-450px', left: '-135px' }} ref={pickerParentRef}>
                    <Picker categories={['smileys_people']} onEmojiClick={handleEmojiClick} searchPlaceholder="Rechercher..." lazyLoadEmojis={true}/>
                </div>
            )}
        </li>
    );
};

export default EmojiPickerButton;
