//ConversationTab

export default  {
    NEW_CONNECTION: "newConnection",
    USER_DISCONNECTED: "userDisconnected",
    LOGIN_CONNECTION: "loginConnection",
    LOGIN_SUCCESS: "loginSuccess",
    FRIEND_ONLINE:"friendOnline",
    FRIEND_OFFLINE:"friendOffline",
    ONLINE_FRIENDS_LIST:"onlineFriendsList",

    SEND_PRIVATE_MESSAGE:"sendPrivateMessage",
    RECEIVED_PRIVATE_MESSAGE:"receivedPrivateMessage",
    UPDATE_MESSAGE_IN_PRIVATE:"updateMessageInPrivate"
};
