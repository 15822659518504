import axios from "axios";
import ConfigsApi from "../../../configs/Api";
import ConfigsEnv from "../../../configs/Env";

import errorMiddleware from "../errorMiddleware";

/**
 * @description Permet
 * @param dispatch
 * @param success
 * @param failure
 */
export const getAuthConversationsMessage = (dispatch, success, failure) => {
    const taskURI = ConfigsApi.default[ConfigsEnv.deploy].endpoint + '/user/conversations/message';
    axios.get(taskURI).then(v => {
        const data = v.data;
        if (data.error) {
            errorMiddleware({
                ...data.error,
                code: 500,
                key: "ERROR",
                msg: data.error
            }, dispatch, failure);
        } else {
            success(data.conversationsMessage);
        }
    }).catch(error => {
        errorMiddleware({
            ...error,
            code: error.code,
            key: "ERROR",
            msg: error.message
        }, dispatch, failure);
    });
}

/**
 * @description Permet de mettre a jour un message
 * @param dispatch
 * @param messageId
 * @param data
 * @param success
 * @param failure
 */
export const updateMessage = (dispatch,messageId, data,success, failure) => {
    const taskURI = ConfigsApi.default[ConfigsEnv.deploy].endpoint + '/message/find/'+messageId;
    axios.put(taskURI,data).then(v => {
        const data = v.data;
        if (data.error) {
            errorMiddleware({
                ...data.error,
                code: 500,
                key: "ERROR",
                msg: data.error
            }, dispatch, failure);
        } else {
            success(data);
        }
    }).catch(error => {
        errorMiddleware({
            ...error,
            code: error.code,
            key: "ERROR",
            msg: error.message
        }, dispatch, failure);
    });
}


/**
 * @description Permet
 * @param dispatch
 * @param recipient
 * @param data
 * @param success
 * @param failure
 */
export const updateUnreadMessage = (dispatch, recipient, data,success, failure) => {
    const taskURI = ConfigsApi.default[ConfigsEnv.deploy].endpoint + '/message/unread/'+recipient;
    axios.put(taskURI,data).then(v => {
        const data = v.data;
        if (data.error) {
            errorMiddleware({
                ...data.error,
                code: 500,
                key: "ERROR",
                msg: data.error
            }, dispatch, failure);
        } else {
            success(data);
        }
    }).catch(error => {
        errorMiddleware({
            ...error,
            code: error.code,
            key: "ERROR",
            msg: error.message
        }, dispatch, failure);
    });
}


/**
 * @description Permet de supprimer un message
 * @param dispatch
 * @param messageId
 * @param success
 * @param failure
 */
export const deleteMessage = (dispatch, messageId,success, failure) => {
    const taskURI = ConfigsApi.default[ConfigsEnv.deploy].endpoint + '/message/find/'+messageId;
    axios.delete(taskURI).then(v => {
        const data = v.data;
        if (data.error) {
            errorMiddleware({
                ...data.error,
                code: 500,
                key: "ERROR",
                msg: data.error
            }, dispatch, failure);
        } else {
            success(data);
        }
    }).catch(error => {
        errorMiddleware({
            ...error,
            code: error.code,
            key: "ERROR",
            msg: error.message
        }, dispatch, failure);
    });
}



/**
 * @description Permet
 * @param dispatch
 * @param recipient
 * @param data
 * @param success
 * @param failure
 */
export const updateUnReceiveMessage = (dispatch, recipient, data,success, failure) => {
    const taskURI = ConfigsApi.default[ConfigsEnv.deploy].endpoint + '/message/un-receive/'+recipient;
    axios.put(taskURI,data).then(v => {
        const data = v.data;
        if (data.error) {
            errorMiddleware({
                ...data.error,
                code: 500,
                key: "ERROR",
                msg: data.error
            }, dispatch, failure);
        } else {
            success(data);
        }
    }).catch(error => {
        errorMiddleware({
            ...error,
            code: error.code,
            key: "ERROR",
            msg: error.message
        }, dispatch, failure);
    });
}


/**
 * @description Permet
 * @param dispatch
 * @param contactId
 * @param success
 * @param failure
 */
export const getAuthFriendConversationMessages = (dispatch, contactId, success, failure) => {
    const taskURI = ConfigsApi.default[ConfigsEnv.deploy].endpoint + '/user/conversations/friend/messages/'+contactId;
    axios.get(taskURI).then(v => {
        const data = v.data;
        if (data.error) {
            errorMiddleware({
                ...data.error,
                code: 500,
                key: "ERROR",
                msg: data.error
            }, dispatch, failure);
        } else {
            success(data);
        }
    }).catch(error => {
        errorMiddleware({
            ...error,
            code: error.code,
            key: "ERROR",
            msg: error.message
        }, dispatch, failure);
    });
}



/**
 * @description Permet
 * @param dispatch
 * @param conversation
 * @param success
 * @param failure
 */
export const getAuthConversationMessages = (dispatch, conversation, success, failure) => {
    let taskURI = null;
    if (conversation.context ==="group"){
        taskURI = ConfigsApi.default[ConfigsEnv.deploy].endpoint + '/user/conversations/group/messages/'+conversation.id;
    }else{
        taskURI = ConfigsApi.default[ConfigsEnv.deploy].endpoint + '/user/conversations/friend/messages/'+conversation.id;
    }

    axios.get(taskURI).then(v => {
        const data = v.data;
        if (data.error) {
            errorMiddleware({
                ...data.error,
                code: 500,
                key: "ERROR",
                msg: data.error
            }, dispatch, failure);
        } else {
            success(data);
        }
    }).catch(error => {
        errorMiddleware({
            ...error,
            code: error.code,
            key: "ERROR",
            msg: error.message
        }, dispatch, failure);
    });
}


/**
 * @description Permet
 * @param dispatch
 * @param conversation
 * @param firstMessageCreatedAt
 * @param failure
 */
export const getAuthConversationLoadMoreMessages = async (dispatch, conversation, firstMessageCreatedAt,failure) => {
    let taskURI = null;
    if (conversation.context === "group") {
        taskURI = ConfigsApi.default[ConfigsEnv.deploy].endpoint + '/user/conversations/group/load-more-messages/' + conversation.id + '/' + firstMessageCreatedAt;
    } else {
        taskURI = ConfigsApi.default[ConfigsEnv.deploy].endpoint + '/user/conversations/friend/load-more-messages/' + conversation.id + '/' + firstMessageCreatedAt;
    }
    const response = await axios.get(taskURI);
    const data = response.data;
    if (data.error) {
        errorMiddleware({
            ...data.error,
            code: 500,
            key: "ERROR",
            msg: data.error
        }, dispatch, failure);
        return data;
    } else {
        return data;
    }
}


/**
 * @description Permet
 * @param dispatch
 * @param groupId
 * @param success
 * @param failure
 */
export const getAuthGroupConversationMessages = (dispatch, groupId, success, failure) => {
    const taskURI = ConfigsApi.default[ConfigsEnv.deploy].endpoint + '/user/conversations/group/messages/'+groupId;

    axios.get(taskURI).then(v => {
        const data = v.data;
        if (data.error) {
            errorMiddleware({
                ...data.error,
                code: 500,
                key: "ERROR",
                msg: data.error
            }, dispatch, failure);
        } else {
            success(data);
        }
    }).catch(error => {
        errorMiddleware({
            ...error,
            code: error.code,
            key: "ERROR",
            msg: error.message
        }, dispatch, failure);
    });
}



/**
 * @description
 * @param dispatch
 * @param data
 * @param success
 * @param failure
 */
export const requestToSendMessage = (dispatch, data,success, failure) => {
    const taskURI = ConfigsApi.default[ConfigsEnv.deploy].endpoint + '/message';
    axios.post(taskURI,data).then(v => {
        const data = v.data;
        if (data.error) {
            errorMiddleware({
                ...data,
                code: 500,
                key: "ERROR",
                msg:data.error
            },dispatch,failure);
        } else {
            success(data.message);
        }
    }).catch(error => {
        errorMiddleware({
            ...error,
            code: error.code,
            key: "ERROR",
            msg: error.message
        },dispatch,failure);
    });
}


/**
 *
 * @param dispatch
 * @param userId
 * @param friendId
 * @param success
 * @param failure
 */
export const getConversationFriendLoadMediaAll = (dispatch, userId,friendId,success, failure) => {
    const taskURI = ConfigsApi.default[ConfigsEnv.deploy].endpoint + '/media/by-conversation-friend/'+userId+'/'+friendId+'/other';
    axios.get(taskURI).then(v => {
        const data = v.data;
        if (data.error) {
            errorMiddleware({
                ...data,
                code: 500,
                key: "ERROR",
                msg:data.error
            },dispatch,failure);
        } else {
            success(data.medias);
        }
    }).catch(error => {
        errorMiddleware({
            ...error,
            code: error.code,
            key: "ERROR",
            msg: error.message
        },dispatch,failure);
    });
}


/**
 *
 * @param dispatch
 * @param userId
 * @param groupId
 * @param success
 * @param failure
 */
export const getConversationGroupLoadMediaAll = (dispatch, userId,groupId,success, failure) => {
    const taskURI = ConfigsApi.default[ConfigsEnv.deploy].endpoint + '/media/by-conversation-group/'+userId+'/'+groupId+'/other';
    axios.get(taskURI).then(v => {
        const data = v.data;
        if (data.error) {
            errorMiddleware({
                ...data,
                code: 500,
                key: "ERROR",
                msg:data.error
            },dispatch,failure);
        } else {
            success(data.medias);
        }
    }).catch(error => {
        errorMiddleware({
            ...error,
            code: error.code,
            key: "ERROR",
            msg: error.message
        },dispatch,failure);
    });
}