export const AUTH_ACTIONS = {
    SET_LOGGED_IN: "SET_LOGGED_IN",
    SET_REGISTER_IN: "SET_REGISTER_IN",
    SET_LOGGED_OUT: "SET_LOGGED_OUT"
};

export const APP_STATE_ACTIONS = {
    SET_RETRIEVE_LOADING: "SET_APP_STATE_RETRIEVE_LOADING"
};

export const THEME_ACTIONS = {
    SET_RETRIEVE: "SET_THEME_RETRIEVE"
};

export const ACCOUNT_ACTIONS = {
    SET_RETRIEVE: "SET_ACCOUNT_RETRIEVE",
    UPDATE_PROFILE: "UPDATE_ACCOUNT_PROFILE",
};


export const NOTIFICATION_ACTIONS = {
    DECREMENT_UNREAD_COUNT: "NOTIFICATION_DECREMENT_UNREAD_COUNT",
    INCREMENT_UNREAD_COUNT: "NOTIFICATION_INCREMENT_UNREAD_COUNT",
};

//ConversationTab

export const CONVERSATION_TAB = {
    SET_TO_OPENED_LIST: "SET_CONVERSATION_TAB_CURRENT_OPENED"
};
