import React, {useEffect, useRef} from "react";

const PeerVideo = (props) => {
    const videoRef = useRef();

    useEffect(() => {
        if (props.peer){
            props.peer.on("stream", stream => {
                videoRef.current.srcObject = stream;
            });
        }

    }, [props.peer]);

    return (
        <video playsInline autoPlay ref={videoRef}  className="w-100 h-100 object-fit-cover"/>
    );
}

export  default PeerVideo;