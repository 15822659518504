import React, {useContext, useState} from "react";
import {getPlaceholderImage} from "../../../utilities/common";
import {requestReceivedAccept, requestReceivedCancel} from "../../../actions/server/user/browser";
import toast from "react-hot-toast";
import {RootDispatchContext} from "../../../providers/RootProvider";
import {decrementUnreadCount} from "../../../actions/dispatch/notification";

const RequestSendItem = ({notification,onDelete}) => {

    const rootDispatchContext = useContext(RootDispatchContext);

    const [isRequestAction, setIsRequestAction] = useState({
        canceled : false,
        accepted : false,
    });

    /**
     * @description
     */
    const acceptedRequestHandler = () => {
        let _ = {
            ...isRequestAction,
            accepted:true
        };
        setIsRequestAction(_);
        requestReceivedAccept(rootDispatchContext,{id: notification.from._id},()=>{
            toast.success("Votre demande a " + + notification.from.name +" bien ete accepte");
            _.accepted=false;
            setIsRequestAction(_);
            decrementUnreadCount(rootDispatchContext,1);
            document.querySelector('[data-notification-id="'+notification._id+'"]').classList.add('d-none');
            onDelete(notification);
        },(error)=>{
            toast.error(error.msg);
        });
    }

    /**
     * @description
     */
    const  cancelRequestHandler=()=>{
        let _ = {
            ...isRequestAction,
            canceled:true
        };
        setIsRequestAction(_);
        requestReceivedCancel(rootDispatchContext,{id: notification.from._id},()=>{
            toast.success("La demande a bien ete annule a " + notification.from.name);
            _.canceled=false;
            setIsRequestAction(_);
            decrementUnreadCount(rootDispatchContext,1);
            document.querySelector('[data-notification-id="'+notification._id+'"]').classList.add('d-none');
            onDelete(notification);
        },(error)=>{
            toast.error(error.msg);
        });

    }


    return (
        <li data-notification-id={notification._id}>
            <div className="tyn-media-group align-items-start">
                <div className="tyn-media tyn-circle">
                    <img src={getPlaceholderImage(notification?.from?.photo)} alt=""/>
                </div>
                <div className="tyn-media-col">
                    <div className="tyn-media-row" style={{whiteSpace: "initial"}}>
                        <span className="message"><strong>{notification?.from?.name}</strong></span>
                    </div>
                    <div className="tyn-media-row" style={{whiteSpace: "initial"}}>
                        <span className="message">{notification.message}</span>
                    </div>
                    <div className="tyn-media-row has-dot-sap">
                        <span className="meta">{notification.createdAt}</span>
                    </div>
                    <div className="tyn-media-row">
                        <ul className="tyn-btn-inline gap gap-3 pt-1">
                            <li>
                                <button className="btn btn-md btn-primary" onClick={acceptedRequestHandler} disabled={isRequestAction.canceled || isRequestAction.accepted}>
                                    {isRequestAction.accepted ? (
                                        <>
                                            <span className="spinner-border spinner-border-sm" role="status"
                                                  aria-hidden="true"></span>
                                            <span className="sr-only">En cours...</span>
                                        </>

                                    ) :(
                                        <>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                 fill="currentColor" className="bi bi-check2-circle"
                                                 viewBox="0 0 16 16">
                                                <path
                                                    d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
                                                <path
                                                    d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
                                            </svg>
                                            <span>Oui</span>
                                        </>
                                    )}
                                </button>
                            </li>
                            <li>
                                <button className="btn btn-md btn-light" onClick={cancelRequestHandler} disabled={isRequestAction.accepted || isRequestAction.accepted}>
                                    {isRequestAction.canceled ? (
                                        <>
                                            <span className="spinner-border spinner-border-sm" role="status"
                                              aria-hidden="true"></span>
                                            <span className="sr-only">En cours...</span>
                                        </>

                                        ) :(
                                        <>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                 fill="currentColor" className="bi bi-x-circle"
                                                 viewBox="0 0 16 16">
                                                <path
                                                    d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                <path
                                                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                            </svg>
                                            <span>Non</span>
                                        </>
                                        )}
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </li>
    );
}
export default RequestSendItem;