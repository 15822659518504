import React from "react";
const  ImageItem =(props)=> {
    return (
        <div className="col-xxl-2 col-xl-3 col-lg-4 col-6">
            <a rel="noopener noreferrer" target="_blank" href={props?.url} className="glightbox tyn-thumb">
                <img src={props?.url} className="tyn-image" alt=""/>
            </a>
        </div>
    );
}
export default ImageItem;