import {Link} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import toast from "react-hot-toast";
import {getAllAuth} from "../../actions/server/notification";
import {RootDispatchContext, RootStateContext} from "../../providers/RootProvider";
import NotificationItem from "./NotificationItem";

const NotificationHeader = () => {
    const rootDispatchContext = useContext(RootDispatchContext);
    const rootStateContext = useContext(RootStateContext);
    const {user} = rootStateContext;

    const [tabViewMode,setTabViewMode] = useState("all");

    const [isFirstShow, setIsFirstShow] = useState(false);
    const [isFetchingApi, setIsFetchingApi] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [viewNotifications,setViewNotification] = useState([]);


    /**
     * @description
     * @returns {*}
     */
    const showNotificationHandler = () => {
        if (isFirstShow) {
            return void (0);
        }
        setIsFirstShow((prevState) => !prevState);
        getAllAuth(rootDispatchContext, (notifications) => {
                setNotifications(notifications);
                setViewNotification(notifications);
                setIsFetchingApi(false);
            },
            (error) => {
                toast.error(error.msg);
                setIsFetchingApi(false);
            }
        )
    }

    /**
     * @description
     * @param notification
     */
    const onRead =(notification)=>{

    }

    /**
     * @description
     * @param notification
     */
    const onDelete =(notification)=>{

    }


    useEffect(() => {
        if (tabViewMode === "all"){
            setViewNotification(notifications);
        }else{
            setViewNotification(notifications.filter((_)=> _.isRead===false));
        }

    }, [tabViewMode,notifications]);

    return (
        <li className="tyn-appbar-item">
            <Link className="tyn-appbar-link dropdown-toggle position-relative" data-bs-toggle="dropdown" to="#" data-bs-offset="0,10"
                  data-bs-auto-close="outside" onClick={showNotificationHandler}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     className="bi bi-app-indicator" viewBox="0 0 16 16">
                    <path
                        d="M5.5 2A3.5 3.5 0 0 0 2 5.5v5A3.5 3.5 0 0 0 5.5 14h5a3.5 3.5 0 0 0 3.5-3.5V8a.5.5 0 0 1 1 0v2.5a4.5 4.5 0 0 1-4.5 4.5h-5A4.5 4.5 0 0 1 1 10.5v-5A4.5 4.5 0 0 1 5.5 1H8a.5.5 0 0 1 0 1H5.5z"/>
                    <path d="M16 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                </svg>
                {user.unread_notifications_count > 0 && ( <div className="badge bg-primary position-absolute rounded-pill top-0 end-0 mt-n1 me-n1">{user.unread_notifications_count}</div>)}

                <span className="d-none">Notifications</span>
            </Link>
            <div className="dropdown-menu dropdown-menu-rg dropdown-menu-end" style={{ maxWidth:'350px'}}>
                <div className="dropdown-head">
                    <div className="title">
                        <h6>Notifications</h6>
                    </div>
                    <ul className="nav nav-tabs nav-tabs-line">
                        <li className="nav-item">
                            <button className="nav-link" data-bs-toggle="tab" data-bs-target="#notifications-unread"
                                    type="button" onClick={()=> setTabViewMode("unread")}> Non lues
                            </button>
                        </li>
                        <li className="nav-item">
                            <button className="nav-link active" data-bs-toggle="tab"
                                    data-bs-target="#notifications-all" type="button" onClick={()=> setTabViewMode("all")}> Tout
                            </button>
                        </li>
                    </ul>
                </div>
                <div className="dropdown-gap">
                    {isFetchingApi && (
                        <span className="spinner-border spinner-border-sm" role="status"
                              aria-hidden="true"></span>
                    )}
                    <ul className="tyn-media-list gap gap-3">
                        {viewNotifications && viewNotifications.map((_notification, _key) => (
                            <NotificationItem onRead={onRead} onDelete={onDelete} notification={_notification} key={_notification._id}/>
                        ))}
                        {viewNotifications.length ===0 && (
                            <div className="text-center">Aucune notification</div>
                        )}
                    </ul>
                </div>
            </div>
        </li>
    );
}


export default NotificationHeader;