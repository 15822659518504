import React, {useEffect, useState} from "react";
import {wait} from "../utilities/common";

function Index(){
    const [postEventView, setPostEventView] = useState(false);

    useEffect(() => {
        wait(1000).then(()=> setPostEventView((prevState)=> !prevState));
    }, []);
    return (
       <div className="d-flex justify-content-center align-content-center align-items-center" style={{ minHeight:"100vh"}}>
           <div>
               {postEventView && <span>404 Page Introuvable</span>}
           </div>
       </div>
    );
}

export  default  Index;