import React from "react";
import {APP_STATE_ACTIONS} from "../actions/constants/Index";
import {Link} from "react-router-dom";

const CryptoJS = require("crypto-js");

export const wait = (timeout) => {
    return new Promise((resolve) => setTimeout(resolve, timeout));
};

/**
 * @default Permet d'exclure les cle d'un objet
 * @param data
 * @param keys
 * @returns {T}
 */
export const excludeObjectByKeys = (data, keys) => {
    return Object.keys(data)
        .filter((key) => !keys.includes(key))
        .reduce((obj, key) => {
            obj[key] = data[key];
            return obj;
        }, {});
};

/**
 *
 * @param dispatch
 * @param timeout
 * @param callback
 */
export const setIsPreventLoading = (dispatch, timeout, callback) => {
    dispatch({
        type: APP_STATE_ACTIONS.SET_RETRIEVE_LOADING,
        payload: {isPreventLoading: true},
    });
    wait(timeout).then(() => {
        dispatch({
            type: APP_STATE_ACTIONS.SET_RETRIEVE_LOADING,
            payload: {isPreventLoading: false},
        });

        if (callback) callback();
    });
};

/**
 * @description Permet de renvoyer une image par defaut
 * @param preventSrc
 */
export const getPlaceholderImage = (preventSrc) => {
    return preventSrc || "https://static.thenounproject.com/png/5034901-200.png";
};

/**
 *
 * @returns {string}
 */
export const getBaseUrlLocation = () => {
    const urlLocation = window.location;
    return urlLocation.protocol + "//" + urlLocation.host;
};

/**
 *
 * @param date
 * @returns {string}
 */
export const dateFromApiTo = (date) => {
    const _date = new Date(date);
    return (
        _date.getFullYear().toString() +
        "-" +
        ((_date.getMonth() + 1).toString().length === 2
            ? (_date.getMonth() + 1).toString()
            : "0" + (_date.getMonth() + 1).toString()) +
        "-" +
        (_date.getDate().toString().length === 2
            ? _date.getDate().toString()
            : "0" + _date.getDate().toString()) +
        " " +
        (_date.getHours().toString().length === 2
            ? _date.getHours().toString()
            : "0" + _date.getHours().toString()) +
        ":" +
        ((parseInt(_date.getMinutes() / 5) * 5).toString().length === 2
            ? (parseInt(_date.getMinutes() / 5) * 5).toString()
            : "0" + (parseInt(_date.getMinutes() / 5) * 5).toString()) +
        ":00"
    );
};

/**
 *
 * @param src
 * @param cb
 * @param ordered
 * @returns {HTMLScriptElement}
 */
export const loadJS = (src, cb, ordered) => {
    var tmp;
    var ref = window.document.getElementsByTagName("script")[0];
    var script = window.document.createElement("script");

    if (typeof cb === "boolean") {
        tmp = ordered;
        ordered = cb;
        cb = tmp;
    }

    script.src = src;
    script.async = !ordered;
    ref.parentNode.insertBefore(script, ref);

    if (cb && typeof cb === "function") {
        script.onload = cb;
    }
    return script;
};

/**
 * @description Permet de convertir des text lien en lien
 * @param text
 * @returns {unknown[]}
 */
export const detectAndConvertLinks = (text) => {
    // Expression régulière pour détecter les URLs
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    return text.split(urlRegex).map((part, index) => {
        if (part.match(urlRegex)) {
            return (
                <Link key={index} target="_blank" rel="noopener noreferrer" to={part}>
                    {part}
                </Link>
            );
        } else {
            return part;
        }
    });
};

/**
 *
 * @param func
 * @param delay
 * @returns {(function(...[*]): void)|*}
 */
export const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
        clearTimeout(timeoutId);

        timeoutId = setTimeout(() => {
            func.apply(this, args);
        }, delay);
    };
};

/**
 * @description Permet de restaurer la barre de scroll d'une conversation
 * @param conversation
 * @param chatBodyRef
 */
export const restoreScrollPositionWithConversation = (
    conversation,
    chatBodyRef
) => {
    let chatScrollPosition = localStorage.getItem("chatScrollPosition");
    if (chatScrollPosition) {
        chatScrollPosition = JSON.parse(chatScrollPosition);
        if (chatScrollPosition[conversation.id]) {
            animateScroll(
                chatBodyRef.current,
                chatScrollPosition[conversation.id],
                500
            );
        } else {
            animateScroll(
                chatBodyRef.current,
                chatBodyRef.current.scrollHeight,
                500,
                () => {
                }
            );
        }
    } else {
        animateScroll(
            chatBodyRef.current,
            chatBodyRef.current.scrollHeight,
            500,
            () => {
            }
        );
    }
};

// Fonction pour animer le défilement
export const animateScroll = (element, to, duration, callback) => {
    duration = duration || 500; // Durée de l'animation en millisecondes
    const start = element.scrollTop;
    const change = to - start;
    let currentTime = 0;

    const animate = () => {
        currentTime += 16; // Temps écoulé depuis le dernier appel, en millisecondes

        const easeInOutQuad = (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);

        const val = easeInOutQuad(currentTime / duration) * change + start;

        element.scrollTop = val;

        if (currentTime < duration) {
            requestAnimationFrame(animate);
        } else if (callback) {
            callback();
        }
    };

    animate();
};

/**
 * @description Permet de retourner les information de l'expediteur
 * @param userInfo
 * @returns {{contact: (string|*), name, photo: (string|*), id, email: *, username: *}}
 */
export const senderInfoFromUser = (userInfo) => {
    return {
        id: userInfo.id,
        name: userInfo.name,
        username: userInfo.username,
        email: userInfo.email,
        photo: userInfo.photo,
        contact: userInfo.contact,
    };
};

/**
 *
 * @param func
 * @param delay
 * @param resolveFunc
 * @returns {(function(...[*]): void)|*}
 */
export const throttle = (func, delay, resolveFunc) => {
    let lastCalledTime = 0;

    return function (...args) {
        const now = Date.now();

        if (now - lastCalledTime >= delay) {
            func(...args);
            resolveFunc && resolveFunc(...args);
            lastCalledTime = now;
        }
    };
};

/**
 *
 * @param sizeBytes
 * @param isThresh
 * @param dp
 * @returns {string}
 */
export const humanFileSize = (sizeBytes, isThresh, dp) => {
    isThresh = isThresh || false;
    dp = dp || 1;
    const thresh = isThresh ? 1000 : 1024;

    if (Math.abs(sizeBytes) < thresh) {
        return sizeBytes + " B";
    }

    const units = isThresh
        ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
        : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    const r = 10 ** dp;
    do {
        sizeBytes /= thresh;
        ++u;
    } while (
        Math.round(Math.abs(sizeBytes) * r) / r >= thresh &&
        u < units.length - 1
        );
    return sizeBytes.toFixed(dp) + " " + units[u];
}


export const clearInputFile = (fieldInput) => {
    if (fieldInput.value) {
        try {
            fieldInput.value = ''; //for IE11, latest Chrome/Firefox/Opera...
        } catch (err) {
        }
        if (fieldInput.value) { //for IE5 ~ IE10
            let form = document.createElement('form'),
                parentNode = fieldInput.parentNode, ref = fieldInput.nextSibling;
            form.appendChild(fieldInput);
            form.reset();
            parentNode.insertBefore(fieldInput, ref);
        }
    }
}


export const generateVideoCallId = (id1, id2) => {
    // Convertir les identifiants en ordre fixe, par exemple alphabétique
    const orderedIds = [id1, id2].sort();

    // Convertir l'array d'identifiants en une chaîne de caractères
    const combinedIds = orderedIds.join('');

    // Générer un hash avec la chaîne de caractères
    // Retourner l'identifiant de chat
    return CryptoJS.SHA256(combinedIds).toString(CryptoJS.enc.Hex);
}


export const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}