import {APP_STATE_ACTIONS} from "../actions/constants/Index";

const defaultState = {
    isPreventLoading: false
}
const reducer = (prevState = defaultState, action) => {
    switch (action.type) {
        case APP_STATE_ACTIONS.SET_RETRIEVE_LOADING:
            return {
                ...prevState,
                isPreventLoading: action.payload.isPreventLoading
            };
        default:
            return prevState;
    }
};
export default reducer;
