import React, {useCallback, useContext, useEffect, useState} from "react";
import ChatNewForm from "../../../components/layouts/default/modals/chat/NewForm/Index";
import GroupNewForm from "../../../components/layouts/default/modals/group/NewForm";
import {RootDispatchContext, RootStateContext} from "../../../providers/RootProvider";
import {getBaseUrlLocation, wait} from "../../../utilities/common";
import {getAuthInfoDetails} from "../../../actions/server/account/profile";
import toast from "react-hot-toast";
import {useSocket} from "../../../providers/SocketProvider";
import ConversationMenu from "./ConversationMenu";
import ConversationList from "./ConversationList";

import SOCKET_TYPE_EVENTS_GROUP from "../../../actions/constants/sockets/group";
import SOCKET_TYPE_EVENTS_USER from "../../../actions/constants/sockets/user";
import SOCKET_TYPE_EVENTS_DEFAULT from "../../../actions/constants/sockets/default";
import {updateMessage, updateUnreadMessage} from "../../../actions/server/conversation";
import {v4 as uuidv4} from "uuid";


function Home() {
    const socket = useSocket();
    const rootStateContext = useContext(RootStateContext);
    const rootDispatchContext = useContext(RootDispatchContext);
    const {user} = rootStateContext;
    const [isLoadingProfileDetails, setIsLoadingProfileDetails] = useState(true);
    const [profileDetails, setProfileDetails] = useState({});
    const [openConversations, setOpenConversations] = useState([]);
    const [newPushMessage, setNewPushMessage] = useState(null);
    const [successReadMessage, setSuccessReadMessage] = useState(null);
    const [updateConversationMenuItem, setUpdateConversationMenuItem] = useState(null);
    const [stateToReadMessage, setStateToReadMessage] = useState(null);
    const [readForMessages,setReadForMessages] = useState(null);
    const [hasReceivedMessage, setHasReceivedMessage] = useState(null);


    useEffect(() => {
        if (user.friends.length === 0) {
            window.location = getBaseUrlLocation() + "/contact/browser";
        }
        getAuthInfoDetails(rootDispatchContext, (userInfo) => {
            setIsLoadingProfileDetails(false);
            setProfileDetails(userInfo);
        }, (error) => {
            toast.error(error.msg);
            setIsLoadingProfileDetails(false);
        });

    }, [rootDispatchContext]);


    /**
     * @description Permet de deplacer la conversation au plus haut
     * @param clickedConversation
     */
    const moveConversationToTop = (clickedConversation) => {
        // Déplacer la conversation au début du tableau des conversations ouvertes
        const updatedOpenConversations = [
            clickedConversation,
            ...openConversations.filter(conversation => conversation.id !== clickedConversation.id)
        ];
        setOpenConversations(updatedOpenConversations);
    };

    /**
     * @description Permet de restaurer la position du scrollbar losque la conversation est de nouveau mis au premeier plan
     * @param openConversation
     */
    const restoreScrollPosition = (openConversation) => {
        wait(0).then(() => {
            const _container = document.querySelector('#tynChatBody-' + openConversation.prefixElem + '-' + openConversation.id);
            let chatScrollPosition = localStorage.getItem('chatScrollPosition');
            if (chatScrollPosition) {
                chatScrollPosition = JSON.parse(chatScrollPosition);
                if (chatScrollPosition[openConversation.id]) {
                    _container.scrollTop = chatScrollPosition[openConversation.id];
                }
            }
        });
    }

    /**
     * @description Evenement pour
     * @param dataMessage
     */
    const onSendMessageInConversationHandler = (dataMessage) => {
        setUpdateConversationMenuItem({
            conversationId:dataMessage.conversationId,
            lastMessage:dataMessage.message
        });
    }

    /**
     * @description
     * @param clickedConversation
     */
    const openConversation = (clickedConversation) => {
        // Ajouter la conversation au début du tableau des conversations ouvertes
        setOpenConversations([clickedConversation, ...openConversations]);
    }

    /**
     * @description
     * @param dataMessage
     */
    const receiveMessageWithBadgeUnread =(dataMessage)=>{
        //Ici la conversation est ouverte
        if (openConversations.length > 0 && openConversations[0].id === dataMessage.conversationId) {
            setTimeout(()=>{
                //On envoi un message pour dire qu'on lu le message qu'on recu
                socket.emit(SOCKET_TYPE_EVENTS_DEFAULT.SUCCESS_READ_MESSAGE_IN_CONVERSATION, {
                    ...dataMessage,
                    message:{
                        ...dataMessage.message,
                        readBy: [...dataMessage.message.readBy, {user: user.id, readAt: new Date()}]
                    }
                });
            },500);

            setUpdateConversationMenuItem({
                conversationId:dataMessage.conversationId,
                lastMessage:dataMessage.message,
            });
        }else{
            setUpdateConversationMenuItem({
                conversationId:dataMessage.conversationId,
                lastMessage:dataMessage.message,
                unreadCount:1,
                type:"INCREMENT"
            });
        }
    }

    /**
     *@description Ouverture de la conversation
     * @param clickedConversation
     */
    const openConversationHandler = useCallback((clickedConversation) => {
        if (clickedConversation?.unreadCount > 0) {
            socket.emit(clickedConversation.context === "group" ? SOCKET_TYPE_EVENTS_DEFAULT.OPEN_CONVERSATION_IN_GROUP
                : SOCKET_TYPE_EVENTS_DEFAULT.OPEN_CONVERSATION_IN_PRIVATE, {
                toUserId: clickedConversation.id,
                conversationId: clickedConversation.context === "group" ? clickedConversation.id : user.id,//On prend l'id de l'user qui envoi
                id: clickedConversation.context === "group" ? clickedConversation.id : user.id,//On prend l'id de l'user qui envoi
                type: clickedConversation.context === "group" ? "UPDATE_UNREAD_MESSAGES_IN_GROUP" : "UPDATE_UNREAD_MESSAGES_IN_PRIVATE",
                context: clickedConversation.context,
                unreadCount: clickedConversation.unreadCount
            });
            const updateClickedConversation = {
                ...clickedConversation,
                conversationId:clickedConversation.id,
                unreadCount: 0,
                type:"RESET"
            }

            setUpdateConversationMenuItem(updateClickedConversation);

            updateUnreadMessage(rootDispatchContext, clickedConversation.id, {
                recipientType: clickedConversation.context === "group" ? "group" : "user",
                recipient: clickedConversation.id,
            }, () => {
            }, () => {

            });
        }

        // Vérifier si la conversation a déjà été ouverte
        const isOpen = openConversations.some(conversation => conversation.id === clickedConversation.id);
        if (isOpen) {
            // La conversation est déjà ouverte, la déplacer au début du tableau
            moveConversationToTop(clickedConversation);
            restoreScrollPosition(clickedConversation);
        } else {
            // La conversation n'est pas encore ouverte, l'ajouter au début du tableau
            openConversation(clickedConversation);
        }
    }, [openConversations, socket]);


    useEffect(() => {
        if (!socket) return;

        // Souscrire à l'événement ouverture de la conversation des messages non lus qui ont ete lu
        socket.on(SOCKET_TYPE_EVENTS_DEFAULT.OPEN_CONVERSATION_IN_PRIVATE, (conversationInfo) => {
            setStateToReadMessage(conversationInfo);
        });


        // Souscrire à l'événement de nouveau message recu dans le groupe
        socket.on(SOCKET_TYPE_EVENTS_GROUP.RECEIVED_MESSAGE_IN_GROUP, (dataMessage) => {
            setNewPushMessage(dataMessage);
            setHasReceivedMessage(dataMessage);
        });

        // Souscrire à l'événement de son message qui a ete lu avec succes dans une conversation
        socket.on(SOCKET_TYPE_EVENTS_DEFAULT.SUCCESS_READ_MESSAGE_IN_CONVERSATION, (successReadMessage) => {
            setNewPushMessage(successReadMessage);
            updateMessage(rootDispatchContext, successReadMessage.message._id, {
                readBy: successReadMessage.message.readBy
            }, () => {

            }, () => {

            });
        });

        // Écoutez l'événement pour savoir quand un autre utilisateur commence à écrire
        socket.on('userTypingConversation', (conversation) => {
            const contextId = conversation.context === "group" ? conversation.id : conversation.fromUserId;
            Array.from(document.querySelectorAll('[data-typing="' + contextId + '"]') || []).forEach((_elemTyping) => {
                if (conversation.context === "group") {
                    _elemTyping.textContent = conversation.fromUserName + " est en train d'ecrire..."
                } else {
                    _elemTyping.textContent = "est en train d'ecrire..."
                }
                _elemTyping.classList.remove('d-none');
            });
        });

        // Écoutez l'événement pour savoir quand un autre utilisateur arrête d'écrire
        socket.on('userStoppedTypingConversation', (conversation) => {
            const contextId = conversation.context === "group" ? conversation.id : conversation.fromUserId;
            Array.from(document.querySelectorAll('[data-typing="' + contextId + '"]') || []).forEach((_elemTyping) => {
                _elemTyping.classList.add('d-none');
            });
        });


        // Souscrire à l'événement de nouveau message recu de la part des membres qu'il a envoye dans le groupe
        socket.on(SOCKET_TYPE_EVENTS_GROUP.UPDATE_MESSAGE_IN_GROUP, (dataMessage) => {
            setNewPushMessage(dataMessage);
            if (dataMessage.type && dataMessage.type === "ONLINE_MEMBER_RECEIVE_MESSAGE") {
                //On met a jour le message envoye par l'utilisateur que son message group a ete recu par un memebre
                updateMessage(rootDispatchContext, dataMessage.message._id, {
                    receiveBy: dataMessage.message.receiveBy
                }, () => {

                }, () => {

                });
            }
        });

        // Souscrire à l'événement de suppression de nouveau messsage recu
        socket.on(SOCKET_TYPE_EVENTS_DEFAULT.DELETE_MESSAGE_IN_CONVERSATION, (message) => {
            setNewPushMessage(message);
        });

        // Souscrire à l'événement de nouveau message prive recu
        socket.on(SOCKET_TYPE_EVENTS_USER.RECEIVED_PRIVATE_MESSAGE, (dataMessage) => {
            setNewPushMessage(dataMessage);
            setHasReceivedMessage(dataMessage);
        });

        // Souscrire à l'événement d'un message prive envoye qui a ete bien recu
        socket.on(SOCKET_TYPE_EVENTS_USER.UPDATE_MESSAGE_IN_PRIVATE, (dataMessage) => {
            setNewPushMessage(dataMessage);
            if (dataMessage.type && dataMessage.type === "ONLINE_MEMBER_RECEIVE_MESSAGE") {
                updateMessage(rootDispatchContext, dataMessage.message._id, {
                    receiveBy: dataMessage.message.receiveBy
                }, () => {

                }, () => {

                });
            }
        });
        // Nettoyer l'écouteur d'événement lors du démontage du composant
        return () => {
            //socket.off(SOCKET_TYPE_EVENTS_GROUP.RECEIVED_MESSAGE_IN_GROUP);
            //socket.off(SOCKET_TYPE_EVENTS_USER.RECEIVED_PRIVATE_MESSAGE);
        };
    }, [socket]);


    //Effet pour gerer le cas ou je recois un nouveau message
    useEffect(() => {
        if (socket){
            if (hasReceivedMessage) {
                receiveMessageWithBadgeUnread(hasReceivedMessage);
            }
        }

    }, [hasReceivedMessage,socket]);



    //Effet de gestion si un message que j'ai envoye a ete lu
    useEffect(() => {
        if (socket && successReadMessage !== null) {
            const fromConvId = (successReadMessage?.groupId || successReadMessage?.fromUserId);
            //Ici la conversation est ouverte
            if (openConversations.length > 0 && openConversations[0].id === fromConvId) {
                //Envoyer un evenement qui'il a lu le message vu qu'il est dans la conversation deja
                socket.emit(SOCKET_TYPE_EVENTS_DEFAULT.SUCCESS_READ_MESSAGE_IN_CONVERSATION, successReadMessage);

                setUpdateConversationMenuItem(successReadMessage);
            }
            setSuccessReadMessage(null);
        }
    }, [successReadMessage, socket]);

    useEffect(() => {
        if(stateToReadMessage){
            setReadForMessages({
                ...stateToReadMessage,
                uniqueId: uuidv4(),
            });
            wait(500).then(()=>{
                setReadForMessages(null);
            })
        }
    }, [stateToReadMessage]);


    if (isLoadingProfileDetails) {
        return (
            <div className="d-flex justify-content-center">
               <span className="spinner-border spinner-border-sm" role="status"
                     aria-hidden="true"></span>
                <span className="sr-only">En cours...</span>
            </div>
        );
    }
    return (
        <>
            <div className="tyn-content tyn-content-full-height tyn-chat has-aside-base">

                <ConversationMenu openConversationHandler={openConversationHandler}
                                  updateConversationMenuItem={updateConversationMenuItem}/>
                <ConversationList
                                  openConversations={openConversations} newPushMessage={newPushMessage}
                                  onSendMessageInConversationHandler={onSendMessageInConversationHandler} readForMessages={readForMessages}/>
            </div>
            <ChatNewForm contacts={profileDetails.friends}/>
            <GroupNewForm contacts={profileDetails.friends}/>
        </>
    );
}

export default Home;