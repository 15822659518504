import {register} from 'timeago.js';
const localeFunc = (number, index, totalSec) => {
    // number: the timeago / timein number;
    // index: the index of array below;
    // totalSec: total seconds between date to be formatted and today's date;
    return [
        ["à l'instant", 'dans un instant'],
        ['il y a %s sec', 'dans %s secondes'],
        ['il y a 1 mi', 'dans 1 minute'],
        ['il y a %s mins', 'dans %s minutes'],
        ['il y a 1 h', 'dans 1 heure'],
        ['il y a %s h', 'dans %s heures'],
        ['il y a 1 j', 'dans 1 jour'],
        ['il y a %s j', 'dans %s jours'],
        ['il y a 1 sem', 'dans 1 semaine'],
        ['il y a %s sem', 'dans %s semaines'],
        ['il y a 1 mois', 'dans 1 mois'],
        ['il y a %s mois', 'dans %s mois'],
        ['il y a 1 an', 'dans 1 an'],
        ['il y a %s ans', 'dans %s ans'],
    ][index];
};
// register your locale with timeago
export default ()=>{
    register('fr_FR', localeFunc);
}