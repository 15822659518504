import React, {memo, useContext, useEffect, useRef, useState} from "react";
import {
    deleteMessage,
    getAuthConversationLoadMoreMessages,
    getAuthConversationMessages,
    requestToSendMessage
} from "../../../actions/server/conversation";
import {RootDispatchContext, RootStateContext} from "../../../providers/RootProvider";
import ChatHeader from "../../../components/conversation/work_space/Aside/AsideContent/ChatHeader";
import ReplyBubbleItem from "../../../components/conversation/work_space/Aside/AsideContent/ReplyBubble/Index";
import PreviewAttachInputImages
    from "../../../components/conversation/work_space/Aside/AsideContent/PreviewAttachInputImages";
import PreviewAttachAudio from "../../../components/conversation/work_space/Aside/AsideContent/PreviewAttachAudio";
import FormListMore from "../../../components/conversation/work_space/Aside/AsideContent/FormListMore";
import EmojiPickerButton from "../../../components/conversation/work_space/Aside/AsideContent/EmojiPickerButton";
import AudioRecorder from "../../../components/conversation/work_space/Aside/AsideContent/VoiceRecorder/AudioRecorder";
import AsideRightInfo from "../../../components/conversation/work_space/Aside/AsideContent/AsideRightInfo";
import SOCKET_TYPE_EVENTS_GROUP from "../../../actions/constants/sockets/group";
import SOCKET_TYPE_EVENTS_USER from "../../../actions/constants/sockets/user";
import SOCKET_TYPE_EVENTS_DEFAULT from "../../../actions/constants/sockets/default";
import {useSocket} from "../../../providers/SocketProvider";
import toast from "react-hot-toast";
import {v4 as uuidv4} from "uuid";
import axios from "axios";
import ConfigsApi from "../../../configs/Api";
import ConfigsEnv from "../../../configs/Env";

import {
    animateScroll,
    debounce,
    restoreScrollPositionWithConversation,
    senderInfoFromUser,
    throttle,
    clearInputFile,
    wait, generateVideoCallId
} from "../../../utilities/common";
import {ChatAside, ChatReplySearch, ChatSettleInputFocus} from "../../../web_modules/WebAppModule";
import ReplyToMessage from "../../../components/conversation/work_space/Aside/AsideContent/ReplyBubble/ReplyToMessage";
import PreviewAttachInputVideos
    from "../../../components/conversation/work_space/Aside/AsideContent/PreviewAttachInputVideos";
import PreviewAttachInputFiles
    from "../../../components/conversation/work_space/Aside/AsideContent/PreviewAttachInputFiles";


const ConversationNotMemo = ({conversation,newPushMessage,onSendMessageInConversationHandler,readForMessages}) => {
    const rootStateContext = useContext(RootStateContext);
    const rootDispatchContext = useContext(RootDispatchContext);
    const {user} = rootStateContext;
    const socket = useSocket();
    const [selectedImages, setSelectedImages] = useState([]);
    const [selectedVideos, setSelectedVideos] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [messages, setMessages] = useState([]);
    const [audioRecorder, setAudioRecorder] = useState(null);
    const [miscVocal, setMiscVocal] = useState(null);
    const [viewAudioPreview, setViewAudioPreview] = useState(null);
    const cursorPosition = useRef(null);
    const [loadingMessages, setLoadingMessages] = useState(true);
    const [replyToMessage, setReplyToMessage] = useState(false);

    const [hasMoreMessages, setHasMoreMessages] = useState(true);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [initialLoadComplete, setInitialLoadComplete] = useState(false);

    //Ref
    const chatInputRef = useRef(null);
    const chatButtonSendRef = useRef(null);
    const chatReplyRef = useRef(null);
    const chatBodyRef = useRef(null);
    const imagesBrowserButtonRef = useRef(null);
    const imagesBrowserInputRef = useRef(null);
    const observerRef = useRef(null);

    useEffect(() => {

        if (!uploading && selectedImages.length > 0) {
            const  inputControlClearImage = document.querySelectorAll('input.controlClearImage');
            Array.from(inputControlClearImage).forEach((_)=>{
                clearInputFile(_);
            });
        }

        if (!uploading && selectedVideos.length > 0) {
            const  inputControlClearVideo = document.querySelectorAll('input.controlClearVideo');
            Array.from(inputControlClearVideo).forEach((_)=>{
                clearInputFile(_);
            });
        }

        if (!uploading && selectedFiles.length > 0) {
            const  inputControlClearFile = document.querySelectorAll('input.controlClearFile');
            Array.from(inputControlClearFile).forEach((_)=>{
                clearInputFile(_);
            });
        }

    }, [uploading]);

    /**
     * @description Permet de formater les messages en provenances du serveur
     * @param message
     * @returns {*}
     */
    const formatMessagesFromServer = (message) => {
        return message.map((_) => ({
            ..._,
            id: _._id,
            senderInfo: _.sender,
            sender: _.sender._id
        }));
    }

    /**
     * @description Fonction Permet de repondre a un message
     * @param replyToMessage
     */
    const handleSetReplyTo = (replyToMessage) => {
        setReplyToMessage(replyToMessage);
    }

    /**
     * @description Fonction Annuler le fait de repondre a un message
     */
    const handleCancelReplyTo = () => {
        setReplyToMessage(null);
    }

    /**
     * @description Fonction Permet de supprimer un message
     * @param message
     */
    const handleDeleteMessage = (message) => {
        message.type = 'ONLINE_MEMBER_DELETE_MESSAGE';
        if (message.recipientType === "group") {
            message.groupId = conversation.id;
        } else {
            message.toUserId = conversation.id;
            message.fromUserId = user.id;
        }

        setMessages((preMessages)=> preMessages.filter((_m)=> _m.id !== message.id ));

        deleteMessage(rootDispatchContext,message.id,()=>{
            toast.success("Message supprime avec succes !");
            socket.emit(SOCKET_TYPE_EVENTS_DEFAULT.DELETE_MESSAGE_IN_CONVERSATION,message);
        },(error)=>{
            toast.error("Oups ! Une erreur est survenue lors de la suppression du message");

        })
    }


    /**
     * @description  Fonction pour mettre à jour les n derniers messages non lus par un utilisateur
     * @param userId
     * @param n
     */
    const updateLastUnreadMessages = (userId, n) => {
        setMessages(prevMessages => {
            const messagesReverse = prevMessages.reverse();
            let count = 0;
            const _ = messagesReverse.map(message => {
                if (count >= n) {
                    return message; // Ne rien changer une fois le nombre nécessaire de messages mis à jour
                }
                if (!message.readBy.some(reader => reader.user === userId)) {
                    // Mettre à jour le message en ajoutant l'utilisateur à la liste des lus
                    message = { ...message, readBy: [...message.readBy, { user, readAt: new Date() }] };
                    count++;
                }
                return message;
            });
            return _.reverse();
        });
    };

    //Effet permettant de mettre tous les messages a lus apres l'ouverture d'une conversation
    useEffect(() => {
        if (readForMessages && readForMessages.conversationId === conversation.id){

            if(readForMessages.context ==="group"){

            }else{
                updateLastUnreadMessages(conversation.id, readForMessages.unreadCount);
            }

        }
    }, [readForMessages,conversation.id]); // Déclencher la mise à jour au montage du composant

    /**
     *@description Permet de mettre a jour
     * @param messageFrom
     */
    const addOrUpdateMessageFromSocket = (messageFrom) => {
        const messageIndex = messages.findIndex(_message => _message.id === messageFrom.message.id);
        if (messageIndex !== -1) {
            const filterMessages = messages.map(_message => {
                if (_message.id === messageFrom.message.id) {
                    return {..._message, ...messageFrom.message};
                } else {
                    return _message;
                }
            });
            setMessages(filterMessages);
        } else {
            setMessages((prevState) => [...prevState, messageFrom.message]);
            animateScroll(chatBodyRef.current, chatBodyRef.current.scrollHeight, 500);
        }
    }

    /**
     * @description Permet de supprimer un message que quelqu'un a supprimer
     */
    const deleteMessageFromSocket = (message) => {
        setMessages((preMessages)=> preMessages.filter((_m)=> _m.id !== message.id ));
    }

    useEffect(() => {
        if (newPushMessage) {
            if (newPushMessage?.type) {
                if (newPushMessage.type === "ONLINE_MEMBER_OPEN_CONVERSATION") {
                    console.log('Conversation ouverte ', newPushMessage);

                } else if (newPushMessage.type === "ONLINE_MEMBER_RECEIVE_MESSAGE") {//On me retourne mon message que j'ai envoye par ce que certains membre l'on lu
                    if (newPushMessage.message.recipientType === "group" && newPushMessage.groupId === conversation.id) {
                        addOrUpdateMessageFromSocket(newPushMessage);
                    } else if (newPushMessage.message.recipientType === "user" && newPushMessage.fromUserId === conversation.id) {
                        addOrUpdateMessageFromSocket(newPushMessage);
                    } else if (newPushMessage.message.recipientType === "user" && newPushMessage.toUserId === conversation.id) {
                        addOrUpdateMessageFromSocket(newPushMessage);
                    }
                } else if(newPushMessage.type ==="ONLINE_MEMBER_DELETE_MESSAGE"){
                    if (newPushMessage.recipientType === "group" && newPushMessage.groupId === conversation.id) {
                        deleteMessageFromSocket(newPushMessage);
                    }else if(newPushMessage.recipientType === "user" && newPushMessage.fromUserId === conversation.id){
                        deleteMessageFromSocket(newPushMessage);
                    }
                }
            } else {
                if (newPushMessage.message.recipientType === "group" && newPushMessage.groupId === conversation.id) {
                    addOrUpdateMessageFromSocket(newPushMessage);
                } else if (newPushMessage.message.recipientType === "user" && newPushMessage.fromUserId === conversation.id) {
                    addOrUpdateMessageFromSocket(newPushMessage);
                } else if (newPushMessage.message.recipientType === "user" && newPushMessage.toUserId === conversation.id) {
                    addOrUpdateMessageFromSocket(newPushMessage);
                }
            }
        }
    }, [newPushMessage]);


    /*useEffect(() => {
        if (chatBodyRef.current) {
            if (!isLoadingMore){
                animateScroll(chatBodyRef.current, chatBodyRef.current.scrollHeight, 500, () => {
                });
            }else{
                restoreScrollPositionWithConversation(conversation,chatBodyRef);
            }

        }
    }, [messages]);*/

    useEffect(() => {
        if (chatBodyRef.current) {
            animateScroll(chatBodyRef.current, chatBodyRef.current.scrollHeight, 500, () => {
            });
        }
    }, []);


    /**
     *@description Permet de gerer l'evenement sur la selection des fichier images
     */
    const handleImagesBrowserChange = (_event) => {
        if (selectedVideos.length > 0){
            toast.error("Vous ne pouvez pas joindre des images car vous avez deja des videos en piece jointe", {
                position: 'bottom-right',
                duration: 2000
            });
            _event.value = null;
            return  void(0);
        }
        if (selectedFiles.length > 0){
            toast.error("Vous ne pouvez pas joindre des images car vous avez deja des fichiers en piece jointe", {
                position: 'bottom-right',
                duration: 2000
            });
            _event.value = null;
            return  void(0);
        }
        setUploading(true);
        (async () => {
            const files = Array.from(_event.target.files);
            const updatedSelectedImages = await files.map((file) => ({
                id: uuidv4(), // Utilisez une bibliothèque pour générer des UUID uniques
                uploadProgress: 0,
                src: null,
                file
            }));
            const _addedImagesState = [...selectedImages, ...updatedSelectedImages];
            setSelectedImages(_addedImagesState);
            const promises = updatedSelectedImages.map(async (updatedSelectedImage, index) => {
                const formData = new FormData();
                formData.append('image', updatedSelectedImage.file);
                formData.append('id', updatedSelectedImage.id);
                formData.append('uniqueFileName', updatedSelectedImage.id);
                formData.append('customPath', 'src/public/upload/images/message');
                try {
                    const response = await axios.post(ConfigsApi.default[ConfigsEnv.deploy].endpoint + '/conversation/upload/image', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                        onUploadProgress: progressEvent => {
                            wait(2000).then(() => {
                                const progress = (progressEvent.loaded / progressEvent.total) * 100;
                                setSelectedImages((prevState) => (
                                    prevState.map(_ => {
                                        if (_.id === updatedSelectedImage.id) {
                                            return {..._, ...{uploadProgress: progress}};
                                        } else {
                                            return _;
                                        }
                                    })
                                ));
                            });

                        }
                    });
                    const dataResponse = response.data;
                    setSelectedImages((prevState) => (
                        prevState.map(_ => {
                            if (_.id === updatedSelectedImage.id) {
                                return {..._, ...{src: dataResponse.image.src}};
                            } else {
                                return _;
                            }
                        })
                    ));
                } catch (error) {
                    console.error('Error uploading file:', error);
                }
            });
            try {
                await Promise.all(promises);
            } finally {
                setUploading(false);
            }
        })();

    }


    /**
     *@description Permet de gerer l'evenement sur la selection des fichier images
     */
    const handleVideosBrowserChange = (_event) => {
        if (selectedImages.length > 0){
            toast.error("Vous ne pouvez pas joindre des videos car vous avez deja des images en piece jointe", {
                position: 'bottom-right',
                duration: 2000
            });
            _event.value = null;
            return  void(0);
        }
        if (selectedFiles.length > 0){
            toast.error("Vous ne pouvez pas joindre des videos car vous avez deja des fichiers en piece jointe", {
                position: 'bottom-right',
                duration: 2000
            });
            _event.value = null;
            return  void(0);
        }
        setUploading(true);
        (async () => {
            const files = Array.from(_event.target.files);
            const updatedSelectedVideos = await files.map((file) => ({
                id: uuidv4(), // Utilisez une bibliothèque pour générer des UUID uniques
                uploadProgress: 0,
                src: null,
                file
            }));
            const _addedVideosState = [...selectedVideos, ...updatedSelectedVideos];
            setSelectedVideos(_addedVideosState);
            const promises = updatedSelectedVideos.map(async (updatedSelectedVideo, index) => {
                const formData = new FormData();
                formData.append('video', updatedSelectedVideo.file);
                formData.append('id', updatedSelectedVideo.id);
                formData.append('uniqueFileName', updatedSelectedVideo.id);
                formData.append('customPath', 'src/public/upload/video/message');
                try {
                    const response = await axios.post(ConfigsApi.default[ConfigsEnv.deploy].endpoint + '/conversation/upload/video', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                        onUploadProgress: progressEvent => {
                            wait(2000).then(() => {
                                const progress = (progressEvent.loaded / progressEvent.total) * 100;
                                setSelectedVideos((prevState) => (
                                    prevState.map(_ => {
                                        if (_.id === updatedSelectedVideo.id) {
                                            return {..._, ...{uploadProgress: progress}};
                                        } else {
                                            return _;
                                        }
                                    })
                                ));
                            });

                        }
                    });
                    const dataResponse = response.data;
                    setSelectedVideos((prevState) => (
                        prevState.map(_ => {
                            if (_.id === updatedSelectedVideo.id) {
                                return {..._, ...{src: dataResponse.video.src}};
                            } else {
                                return _;
                            }
                        })
                    ));
                } catch (error) {
                    console.error('Error uploading file:', error);
                }
            });
            try {
                await Promise.all(promises);
            } finally {
                setUploading(false);
            }
        })();

    }

    /**
     *@description Permet de gerer l'evenement sur la selection des fichier Joints
     */
    const handleFilesBrowserChange = (_event) => {
        if (selectedImages.length > 0){
            toast.error("Vous ne pouvez pas joindre des fichiers car vous avez deja des images en piece jointe", {
                position: 'bottom-right',
                duration: 2000
            });
            _event.value = null;
            return  void(0);
        }
        if (selectedVideos.length > 0){
            toast.error("Vous ne pouvez pas joindre des fichiers car vous avez deja des videos en piece jointe", {
                position: 'bottom-right',
                duration: 2000
            });
            _event.value = null;
            return  void(0);
        }
        setUploading(true);
        (async () => {
            const files = Array.from(_event.target.files);
            const updatedSelectedFiles = await files.map((file) => ({
                id: uuidv4(), // Utilisez une bibliothèque pour générer des UUID uniques
                uploadProgress: 0,
                src: null,
                name: file.name,
                size: file.size,
                file
            }));
            const _addedFilesState = [...selectedFiles, ...updatedSelectedFiles];
            setSelectedFiles(_addedFilesState);
            const promises = updatedSelectedFiles.map(async (updatedSelectedFile, index) => {
                const formData = new FormData();
                formData.append('file', updatedSelectedFile.file);
                formData.append('id', updatedSelectedFile.id);
                formData.append('uniqueFileName', updatedSelectedFile.id);
                formData.append('customPath', 'src/public/upload/file/message');
                try {
                    const response = await axios.post(ConfigsApi.default[ConfigsEnv.deploy].endpoint + '/conversation/upload/file', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                        onUploadProgress: progressEvent => {
                            wait(2000).then(() => {
                                const progress = (progressEvent.loaded / progressEvent.total) * 100;
                                setSelectedFiles((prevState) => (
                                    prevState.map(_ => {
                                        if (_.id === updatedSelectedFile.id) {
                                            return {..._, ...{uploadProgress: progress}};
                                        } else {
                                            return _;
                                        }
                                    })
                                ));
                            });

                        }
                    });
                    const dataResponse = response.data;
                    setSelectedFiles((prevState) => (
                        prevState.map(_ => {
                            if (_.id === updatedSelectedFile.id) {
                                return {..._, ...{src: dataResponse.file.src,name:dataResponse.file.name}};
                            } else {
                                return _;
                            }
                        })
                    ));
                } catch (error) {
                    console.error('Error uploading file:', error);
                }
            });
            try {
                await Promise.all(promises);
            } finally {
                setUploading(false);
            }
        })();
    }


    /**
     * @description
     * @returns {{senderInfo: {contact: (string|*), name, photo: (string|*), id, email: *, username: *}, recipientType: (string), sender, replyTo: null, recipient, readBy: [{readAt: Date, user}], id: (`${string}-${string}-${string}-${string}-${string}`|string|*|string), media: null, audio: null, receiveBy: [{receiveAt: Date, user}], content: *}|boolean}
     */
    const checkValidateMessage = () => {
        const senderInfo = senderInfoFromUser(user);
        const replyToMessageId = replyToMessage ? replyToMessage.id : null;
        const newMessageSend = {
            id: uuidv4(),
            senderInfo: senderInfo,
            sender: user.id,
            content: getMessageContentWithForm(),
            media: null,
            audio: null,
            replyTo: replyToMessageId,// ID du message auquel il répond
            recipientType: conversation.prefixElem === "Group" ? "group" : "user", // Type de destinataire (personne ou groupe)
            recipient: conversation.id, // ID de la personne ou du groupe destinataire
            readBy: [{user: user.id, readAt: new Date()}],
            receiveBy: [{user: user.id, receiveAt: new Date()}]
        }
        if (miscVocal && miscVocal.src) {
            newMessageSend.audio = {
                type: 'audio',
                url: miscVocal.src
            };
        }
        if (uploading) {
            toast.error("Veuillez patienter le temps que les media soient charges", {
                position: 'bottom-right',
                duration: 2000
            });
            return false;
        }
        if (!uploading && selectedImages.length > 0) {
            newMessageSend.media = selectedImages.map((_imgObject) => ({
                type: 'photo',
                url: _imgObject.src
            }));
        }

        if (!uploading && selectedVideos.length > 0) {
            newMessageSend.media = selectedVideos.map((_videoObject) => ({
                type: 'video',
                url: _videoObject.src
            }));
        }

        if (!uploading && selectedFiles.length > 0) {
            newMessageSend.media = selectedFiles.map((_fileObject) => ({
                type: 'file',
                url: _fileObject.src,
                name: _fileObject.name,
                size: _fileObject.size
            }));
        }

        if ((newMessageSend.audio === null && newMessageSend.media === null) && chatInputRef.current.innerHTML.trim() === "") {
            toast.error("Veuillez saisir un message ou selectionner un media ou un selectionner un media", {
                position: 'bottom-right',
                duration: 2000
            });
            return false;
        }
        return newMessageSend;
    }

    /**
     * @description Permet de retourner le contenu du message saisi
     * @returns {*}
     */
    const getMessageContentWithForm = () => {
        return chatInputRef.current.innerHTML;
    }

    /**
     * @description
     * @param _event
     */
    const chatButtonSendClickHandler = (_event) => {
        _event.preventDefault();
        const message = checkValidateMessage();
        if (message) {
            setMessages((prevState) => [...prevState, message]);
            chatInputRef.current.innerHTML = "";
            setSelectedImages([]);
            setSelectedVideos([]);
            setSelectedFiles([]);
            setMiscVocal(null);
            setViewAudioPreview(false);
            setReplyToMessage(null);
            requestToSendMessage(rootDispatchContext, message, (_message) => {
                let dataMessage = {
                    fromUserId: message.sender,
                    message: {...message, ..._message}
                }
                if (message.recipientType === "group") {
                    dataMessage.groupId = message.recipient;
                    dataMessage.conversationId = dataMessage.groupId;
                } else {
                    dataMessage.toUserId = message.recipient;
                    dataMessage.conversationId = message.sender;
                }
                socket.emit(message.recipientType === "group" ? SOCKET_TYPE_EVENTS_GROUP.SEND_MESSAGE_IN_GROUP :
                        SOCKET_TYPE_EVENTS_USER.SEND_PRIVATE_MESSAGE,
                    dataMessage
                );
                onSendMessageInConversationHandler({
                    ...dataMessage,
                    conversationId:conversation.id
                });
                animateScroll(chatBodyRef.current, chatBodyRef.current.scrollHeight, 500);
            }, () => {

            });
        }
    }

    /**
     * @description permet de supprimer une image de la selection
     * @param id
     */
    const handleRemoveImage = (id) => {
        setSelectedImages((prevImages) => prevImages.filter((img) => img.id !== id));
    }

    /**
     * @description permet de supprimer une video de la selection
     * @param id
     */
    const handleRemoveVideo = (id) => {
        setSelectedVideos((prevVideos) => prevVideos.filter((video) => video.id !== id));
    }

    /**
     * @description permet de supprimer un fichier de la selection
     * @param id
     */
    const handleRemoveFile = (id) => {
        setSelectedFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
    }


    useEffect(() => {
        if(!loadingMessages){
            ChatAside(conversation.prefixElem + "-" + conversation?.id);
            ChatReplySearch(conversation.prefixElem + "-" + conversation?.id);
            ChatSettleInputFocus(conversation.prefixElem + "-" + conversation?.id);

            if (chatBodyRef && chatBodyRef.current) {
                let chatScrollPosition = localStorage.getItem('chatScrollPosition');
                if (chatScrollPosition) {
                    chatScrollPosition = JSON.parse(chatScrollPosition);
                    if (chatScrollPosition[conversation.id]) {
                        animateScroll(chatBodyRef.current, chatScrollPosition[conversation.id], 0);
                    } else {
                        animateScroll(chatBodyRef.current, chatBodyRef.current.scrollHeight, 0);
                    }
                } else {
                    animateScroll(chatBodyRef.current, chatBodyRef.current.scrollHeight, 0);
                }
            }
            if (imagesBrowserButtonRef && imagesBrowserButtonRef.current) {
                imagesBrowserButtonRef.current.addEventListener('click', () => imagesBrowserInputRef.current.click());
            }

            if (chatInputRef && chatInputRef.current) {
                chatInputRef.current.focus();
            }
        }

    }, [loadingMessages]);

    /**
     *
     * @param emojiObject
     */
    const onSelectEmoji = (emojiObject) => {
        const contentEditable = chatInputRef.current;
        contentEditable.focus();
        let _img = '<img style="max-width: 24px;max-height: 24px" src="' + emojiObject.getImageUrl() + '"/>';
        let sel, range;
        if (window.getSelection) {
            // IE9 and non-IE
            sel = window.getSelection();
            if (sel.getRangeAt && sel.rangeCount) {
                range = sel.getRangeAt(cursorPosition.current || 0);
                range.deleteContents();
                const el = document.createElement("span");
                el.innerHTML = _img;
                let frag = document.createDocumentFragment(),
                    node,
                    lastNode;
                while ((node = el.firstChild)) {
                    lastNode = frag.appendChild(node);
                }
                range.insertNode(frag);

                // Preserve the selection
                if (lastNode) {
                    range = range.cloneRange();
                    range.setStartAfter(lastNode);
                    range.collapse(true);
                    sel.removeAllRanges();
                    sel.addRange(range);
                }
            }
        } else if (document.selection && document.selection.type !== "Control") {
            // IE < 9
            document.selection.createRange().pasteHTML(_img);
        }
    }


    // Utilisez la fonction throttle pour limiter les appels
    const throttledEmitTyping = throttle(() => {
        socket.emit('startTypingConversation', {...conversation, fromUserId: user.id, fromUserName: user.name, text:  getMessageContentWithForm()});
    }, 1000); // Limitez à un appel par seconde (ajustez selon vos besoins)



    // Gérez l'arrêt de la saisie
    const handleStopTyping = () => {
        socket.emit('stopTypingConversation', {...conversation, fromUserId: user.id, fromUserName: user.name, text:  getMessageContentWithForm()});
    };

    /**
     *
     */
    const onInputHandler = () => {
        throttledEmitTyping();
    }


    /**
     * @description
     * @param _event
     */
    const chatInputKeyPressHandler = (_event) => {
        if (_event.key === "Enter" && !_event.shiftKey) {
            _event.preventDefault();
            chatButtonSendClickHandler(_event);
        }
    }


    // Mettre à jour le localStorage avec debounce
    const debouncedHandleScroll = useRef(debounce(() => {
        if (chatBodyRef.current){
            let scrollPositions = localStorage.getItem('chatScrollPosition');
            if (scrollPositions) {
                scrollPositions = JSON.parse(scrollPositions);
                scrollPositions = {
                    ...scrollPositions,
                    [conversation.id]: chatBodyRef.current.scrollTop
                }
                localStorage.setItem('chatScrollPosition', JSON.stringify(scrollPositions));
            } else {
                localStorage.setItem('chatScrollPosition', JSON.stringify({
                    [conversation.id]: chatBodyRef.current.scrollTop
                }));
            }
        }

    }, 500)).current;


    const onPreviewAudioRecorder = (audioStream) => {
        setViewAudioPreview(true);
        setAudioRecorder(audioStream);
    }

    const onRecorderUploadSuccess = (dataResp) => {
        setMiscVocal(dataResp.audio);
    }

    const handleRemoveAudioRecorder = () => {
        setViewAudioPreview(false);
        setMiscVocal(null);
    }

    /**
     * @description Permet d'envoyer l'audio
     * @param _event
     */
    const handleSendAudioRecorder = (_event) => {
        if (miscVocal && miscVocal.src) {
            chatButtonSendClickHandler(_event);
        } else {
            toast.error("Veuillez attendre la fin de chargement de votre audio");
        }
    }


    // Mise à jour du localStorage à chaque changement de la position de défilement
    const handleScroll = () => {
        debouncedHandleScroll();
    };


    useEffect(() => {
        getAuthConversationMessages(rootDispatchContext, conversation, (data) => {
            let messages = formatMessagesFromServer(data.messages);
            setMessages(messages);
            setLoadingMessages(false);
            if (messages.length ===0){
                setHasMoreMessages(false);
            }
            setInitialLoadComplete(true);
        }, () => {

        });
    }, []);


    useEffect(() => {
        // Observer pour détecter quand l'élément est visible
        const observer = new IntersectionObserver(
            entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting && hasMoreMessages && !isLoadingMore && initialLoadComplete) {
                        // L'élément est visible, déclencher le chargement de plus de messages
                        loadMoreMessages().then(r => {});
                    }
                });
            },
            { threshold: 1 } // L'élément est considéré comme visible lorsque 100% de celui-ci est visible
        );

        if (observerRef.current) {
            observer.observe(observerRef.current);
        }

        return () => {
            if (observerRef.current) {
                observer.unobserve(observerRef.current);
            }
        };
    }, [hasMoreMessages, isLoadingMore,initialLoadComplete]);


    /**
     * @description Permet de charger les messages
     * @returns {Promise<void>}
     */
    const loadMoreMessages = async () => {
        // Charger plus de messages de la conversation
        setIsLoadingMore(true);
        try {
            const firstMessageCreatedAt = messages.length > 0 ? messages[0].createdAt : null;
            const newMessages = await fetchMoreMessages(firstMessageCreatedAt);
            if (newMessages.length > 0){
                setMessages(prevMessages => [...newMessages, ...prevMessages]);
                restoreScrollPositionWithConversation(conversation,chatBodyRef);
                setIsLoadingMore(false);
            }
            if(newMessages.length === 0){
                // Si vous avez atteint le début des messages, désactivez le chargement supplémentaire
                setHasMoreMessages(false);
                setIsLoadingMore(false);
            }
        } catch (error) {
            console.error('Error loading more messages:', error);
            setIsLoadingMore(false);
        } finally {
            setIsLoadingMore(false);
        }
    }

    /**
     *
     * @param firstMessageCreatedAt
     * @returns {Promise<*>}
     */
    const fetchMoreMessages = async (firstMessageCreatedAt) => {
        const dataMessages = await getAuthConversationLoadMoreMessages(rootDispatchContext, conversation, firstMessageCreatedAt, (error) => {
        });
       return formatMessagesFromServer(dataMessages.messages);
    }

    return (
        <div className="tyn-main tyn-chat-content aside-shown" id={"tynMain-" + conversation.prefixElem + "-" + conversation?.id} data-conversation-aside={conversation.id}>
            <ChatHeader conversation={conversation} user={user}/>
            <div className="tyn-chat-body js-scroll-to-end" ref={chatBodyRef} id={"tynChatBody-" + conversation.prefixElem + "-" + conversation.id} onScroll={handleScroll}>
                <div>
                    <div className="tyn-reply" ref={chatReplyRef}
                         id={"tynReply-" + conversation.prefixElem + "-" + conversation.id}>
                        {isLoadingMore &&
                            (
                                <div className="d-flex justify-content-center">
                               <span className="spinner-border spinner-border-sm" role="status"
                                     aria-hidden="true"></span>
                                </div>
                            )
                        }
                        <div ref={observerRef} style={{ height: '10px' }} />
                        {loadingMessages && (
                            <div className="d-flex justify-content-center">
                               <span className="spinner-border spinner-border-sm" role="status"
                                     aria-hidden="true"></span>
                            </div>
                        )}
                        {!loadingMessages && messages && messages.map((message) => (
                            <ReplyBubbleItem handleReplyTo={{
                                handleSetReplyTo: handleSetReplyTo,
                                handleCancelReplyTo: handleCancelReplyTo
                            }} handleDeleteMessage={handleDeleteMessage} key={'ReplyBubbleItem-' + message.id} conversation={conversation}
                                             message={message}
                                             isSameDirectAuthor={false}/>
                        ))}
                    </div>
                </div>
            </div>
            <PreviewAttachInputImages selectedImages={selectedImages} handleRemoveImage={handleRemoveImage}/>
            <PreviewAttachInputVideos selectedVideos={selectedVideos} handleRemoveVideo={handleRemoveVideo}/>
            <PreviewAttachInputFiles selectedFiles={selectedFiles} handleRemoveFile={handleRemoveFile}/>

            {viewAudioPreview && (
                <PreviewAttachAudio miscVocal={miscVocal} audioRecorder={audioRecorder}
                                    handleRemoveAudioRecorder={handleRemoveAudioRecorder}
                                    handleSendAudioRecorder={handleSendAudioRecorder}/>
            )}
            {replyToMessage && (
                <div className="d-flex gap gap-3 align-items-center px-3 border-top">
                    <div className="p-1">
                        <div className="p-2" style={{
                            background: 'var(--bs-white)',
                            border: '1px solid var(--border-color)',
                            borderRadius: '.5rem'
                        }}>
                            <ReplyToMessage message={replyToMessage} conversation={conversation}/>
                        </div>
                    </div>
                    <div>
                        <button className="btn btn-icon btn-white btn-md btn-pill" onClick={handleCancelReplyTo}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-trash" viewBox="0 0 16 16">
                                <path
                                    d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"></path>
                                <path fillRule="evenodd"
                                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"></path>
                            </svg>
                        </button>
                    </div>
                </div>
            )}
            <div className="tyn-chat-form">
                <div className="tyn-chat-form-insert">
                    <ul className="tyn-list-inline gap gap-3">
                        <FormListMore prefixElement={conversation.prefixElem} handleVideosBrowserChange={handleVideosBrowserChange} handleFilesBrowserChange={handleFilesBrowserChange}/>
                        <li className="d-none d-sm-block">
                            <button className="btn btn-icon btn-light btn-md btn-pill" ref={imagesBrowserButtonRef}>
                                <input type="file" className="d-none controlClearImage" ref={imagesBrowserInputRef}
                                       onChange={handleImagesBrowserChange} multiple
                                       accept="image/png, image/jpg, image/jpeg"/>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-card-image" viewBox="0 0 16 16">
                                    <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                    <path
                                        d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54A.505.505 0 0 1 1 12.5v-9a.5.5 0 0 1 .5-.5h13z"/>
                                </svg>
                            </button>
                        </li>
                        <EmojiPickerButton onSelectEmoji={onSelectEmoji} chatInputRef={chatInputRef}/>
                    </ul>
                </div>
                <div className="tyn-chat-form-enter">
                    <div data-ph="Votre message ici..." ref={chatInputRef} onKeyDown={chatInputKeyPressHandler}
                         className="tyn-chat-form-input"
                         data-id="tynChatInput" id={"tynChatInput-" + conversation.prefixElem}
                         onBlur={handleStopTyping}
                         onInput={_event => onInputHandler(_event.currentTarget.innerHTML)} contentEditable></div>
                    <ul className="tyn-list-inline me-n2 my-1">
                        <AudioRecorder onPreviewAudioRecorder={onPreviewAudioRecorder}
                                       onUploadSuccess={onRecorderUploadSuccess}/>
                        <li>
                            <button className="btn btn-icon btn-white btn-md btn-pill" id="tynChatSend"
                                    ref={chatButtonSendRef} onClick={chatButtonSendClickHandler}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-send-fill" viewBox="0 0 16 16">
                                    <path
                                        d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z"/>
                                </svg>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            <AsideRightInfo conversation={conversation}/>
        </div>
    )

}
function propsAreEqual(prevProps, nextProps) {
    return prevProps.conversation === nextProps.conversation &&
        prevProps.newPushMessage === nextProps.newPushMessage &&
        prevProps.readForMessages === nextProps.readForMessages;
}
const Conversation = memo(ConversationNotMemo,propsAreEqual);
export default Conversation;