import axios from "axios";
import ConfigsApi from "../../../configs/Api";
import ConfigsEnv from "../../../configs/Env";

import errorMiddleware from "../errorMiddleware";

/**
 * @description Permet
 * @param dispatch
 * @param success
 * @param failure
 */
export const getAllAuth = (dispatch, success, failure) => {
    const taskURI = ConfigsApi.default[ConfigsEnv.deploy].endpoint + '/notification/all-by-auth';
    axios.get(taskURI).then(v => {
        const data = v.data;
        if (data.error) {
            errorMiddleware({
                ...data,
                code: 500,
                key: "ERROR",
                msg:data.error
            },dispatch,failure);
        } else {
            success(data.notifications);
        }
    }).catch(error => {
        errorMiddleware({
            ...error,
            code: error.code,
            key: "ERROR",
            msg: error.message
        },dispatch,failure);
    });
}
