import React, {memo, useContext} from "react";
import {dateFromApiTo} from "../../../../../../utilities/common";
import TimeAgo from "timeago-react";
import UserReadCheckSign from "./UserReadCheckSign";

const TimeAgoBubble = (props) => {
    const _ = dateFromApiTo(props.message?.createdAt);

    return (<div style={{fontSize:'0.6rem',textAlign:props.context==="outgoing" ?'end':'initial' }} className="meta" title={_}>
        <div className="d-flex gap-1 justify-content-end">
            <TimeAgo
                datetime={_}
                locale='fr_FR'/>
            {(props.message?.recipientType === "group" || props.context==="outgoing") && (
                <UserReadCheckSign {...props}/>
            )}

        </div>
    </div>);
}
export default TimeAgoBubble;