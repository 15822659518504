import React, {useContext, useEffect, useRef, useState} from "react";
import Peer from "simple-peer";
import { useParams } from "react-router-dom";
import {useSocket} from "../../../../providers/SocketProvider";
import PeerVideo from "./PeerVideo";
import {useNavigate} from "react-router-dom";
import toast from "react-hot-toast";
import ConfigsApi from "../../../../configs/Api";
import ConfigsEnv from "../../../../configs/Env";
import axios from "axios";
import {excludeObjectByKeys, formatTime} from "../../../../utilities/common";
import {RootDispatchContext, RootStateContext} from "../../../../providers/RootProvider";
import LayoutVideoManagerComponent from "./LayoutVideoManagerComponent";


const videoConstraints = {
    height: window.innerHeight / 2,
    width: window.innerWidth / 2
};


const ReceivedCalling = () => {
    const navigate = useNavigate();
    const params = useParams();
    const socket = useSocket();
    const {callingId, roomID} = params;
    const [peers, setPeers] = useState([]);
    const userVideo = useRef();
    const [userMediaConfig, setUserMediaConfig] = useState({
        microphone:true,
        video: true
    });
    const peersRef = useRef([]);
    const userStreamDataRef = useRef(null);
    const [callDuration, setCallDuration] = useState(0);



    const [isLoadingInfo, setIsLoadingInfo] = useState(true);
    const [callProfile, setCallProfile] = useState(null);

    const rootStateContext = useContext(RootStateContext);
    const {user} = rootStateContext;
    const rootDispatchContext = useContext(RootDispatchContext);


    /**
     * @description Fonction pour activer/désactiver le microphone
     */
    const toggleMicrophoneHandler = () => {
        const newMicrophoneState = !userMediaConfig.microphone;
        userStreamDataRef.current.getAudioTracks().forEach(track => {
            track.enabled = newMicrophoneState;
        });
        setUserMediaConfig(prevConfig => ({
            ...prevConfig,
            microphone: newMicrophoneState
        }));
    };

    /**
     * @description Fonction pour activer/désactiver la vidéo
     */
    const toggleVideoHandler = () => {
        const newVideoState = !userMediaConfig.video;
        userStreamDataRef.current.getVideoTracks().forEach(track => {
            track.enabled = newVideoState;
        });
        setUserMediaConfig(prevConfig => ({
            ...prevConfig,
            video: newVideoState
        }));
    };
    
    
    /**
     * @description Permet de stopper le flux
     * @param _stream
     */
    const stopStream = (_stream) => {
        _stream.getTracks().forEach(track => {
            track.stop();
            track.enabled = false
        });
    }



    useEffect(() => {
        if (socket){
            navigator.mediaDevices.getUserMedia({ video: videoConstraints, audio: true }).then(stream => {
                userVideo.current.srcObject = stream;
                userStreamDataRef.current = stream;
                socket.emit("requestJoinCallVideoRoom", roomID);

                socket.on("offerAllUsersCallVideoRoom", users => {
                    const peers = [];
                    users.forEach(userID => {
                        const peer = createPeer(userID, socket.id, stream);
                        peersRef.current.push({
                            peerID: userID,
                            peer,
                        })
                        peers.push(peer);
                    })
                    setPeers(peers);
                })

                socket.on("joinedCallVideoRoom", payload => {
                    const peer = addPeer(payload.signal, payload.callerID, stream);
                    peersRef.current.push({
                        peerID: payload.callerID,
                        peer,
                    })

                    setPeers(users => [...users, peer]);
                });

                socket.on('tryRoomFullCallVideoRoom', dataRoomInfo => {
                    //dataRoomInfo => {roomID:roomID}
                    toast.error("Est deja en communication");
                    navigate("/",{replace: true});
                });


                socket.on("receivingReturnedSignalCallVideoRoom", payload => {
                    const item = peersRef.current.find(p => p.peerID === payload.id);
                    item.peer.signal(payload.signal);
                });

                socket.on('resumeCallVideoRoom',(roomInfo) => {
                    if (peersRef.current[roomInfo.socketId]) {
                        peersRef.current[roomInfo.socketId].destroy();
                    }
                    const filteredPeers = peersRef.current.filter(peer => peer.peerID !== roomInfo.socketId);
                    if (filteredPeers.length ===0){//C'est la derniere personne a quitter l'appel video
                        stopStream(userStreamDataRef.current);
                        toast.success("L'appel à été coupé par ...");
                        navigate("/", { replace: true });
                    }
                    setPeers(filteredPeers);
                });


                socket.on('user-disconnected', userId => {
                    if (peersRef.current[userId]) {
                        peersRef.current[userId].destroy();
                    }
                    const filteredPeers = peersRef.current.filter(peer => peer.peerID !== userId);
                    if (filteredPeers.length ===0){//C'est la derneiere personne a quitter l'appel video
                        toast.error("L'appel a ete coupe. Veuillez retender l'appel");
                        navigate("/video-call/request-calling/"+callingId+"/"+roomID, { replace: true });
                    }
                    setPeers(filteredPeers);
                });
            });


            return ()=>{
                stopStream(userStreamDataRef.current);
                socket.off('resumeCallVideoRoom');
                socket.off('user-disconnected');
                socket.off('receivingReturnedSignalCallVideoRoom');
                socket.off('joinedCallVideoRoom');
                socket.off('offerAllUsersCallVideoRoom');
                socket.off('returningSignalCallVideoRoom');
            }
        }
    }, [socket,roomID,callingId]);


    useEffect(() => {
        try {
            (async () => {
                const taskURI = ConfigsApi.default[ConfigsEnv.deploy].endpoint + '/user/minify-info/' + callingId;
                const apiResponse = await axios.get(taskURI);
                const data = apiResponse.data;
                if (data.error) {
                    toast.error(data.error);
                    setIsLoadingInfo(false);
                } else {
                    const _userInfo = data.userInfo;
                    let userInfo = excludeObjectByKeys(_userInfo, ["__v", "_id", "password"]);
                    userInfo.id = _userInfo._id;
                    setCallProfile(userInfo);
                    setIsLoadingInfo(false);
                }
            })();

        } catch (error) {
            toast.error(error.msg);
            setIsLoadingInfo(false);
        }
    }, [rootDispatchContext]);


    /**
     * @description Permet de creer un channel peer
     * @param userToSignal
     * @param callerID
     * @param stream
     * @returns {Peer}
     */
    const createPeer =  (userToSignal, callerID, stream) => {
        const peer = new Peer({
            initiator: true,
            trickle: false,
            stream,
        });

        peer.on("signal", signal => {
            socket.emit("sendingSignalCallVideoRoom", { userToSignal, callerID, signal })
        });

        peer.on('error', (e) => {
            console.log('Error' + e);
        });

        peer.on('close', () => {
            alert('Déconnecté. peer');
            // Vous pouvez ajouter d'autres logique ici
        });
        setInterval(() => {
            if (!peer.connected) {
                console.log('Déconnecté du peer.');
            }
        }, 1000);
        return peer;
    }

    /**
     * @description Ajout de peer channel
     * @param incomingSignal
     * @param callerID
     * @param stream
     * @returns {Peer}
     */
    const addPeer =  (incomingSignal, callerID, stream) => {
        const peer = new Peer({
            initiator: false,
            trickle: false,
            stream,
        })

        peer.on("signal", signal => {
            socket.emit("returningSignalCallVideoRoom", { signal, callerID });
        })

        peer.signal(incomingSignal);


        peer.on('close', () => {
            alert('Déconnecté. peer');
            // Vous pouvez ajouter d'autres logique ici
        });

        return peer;
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setCallDuration(prevDuration => prevDuration + 1);
        }, 1000);

        return () => clearInterval(interval);
    }, []); // Ce useEffect s'exécute une seule fois au montage du composant


    /**
     * @description Permet de stopper video
     */
    const onResumeChatVideoRoomHandler = ()=>{
        stopStream(userStreamDataRef.current);
        socket.emit("resumeCallVideoRoom", {roomID:roomID,fromUserId:user.id,toUserId:callingId,socketId: socket.id});
        socket.off('user-disconnected');
        socket.off('receivingReturnedSignalCallVideoRoom');
        socket.off('joinedCallVideoRoom');
        socket.off('offerAllUsersCallVideoRoom');
        socket.off('returningSignalCallVideoRoom');
        navigate("/", { replace: true });
    }



    return (
        <LayoutVideoManagerComponent userMediaConfig={userMediaConfig} callDuration={callDuration} callProfile={callProfile} userVideo={userVideo}
                                     peers={peers} onResumeChatVideoRoomHandler={onResumeChatVideoRoomHandler}
                                     toggleMicrophoneHandler={toggleMicrophoneHandler} toggleVideoHandler={toggleVideoHandler}/>
    );
};

export default ReceivedCalling;