import React from "react";
import {humanFileSize} from "../../../../../utilities/common";

export default ({selectedFiles,handleRemoveFile})=>{

    return (
        <div className={"tyn-reply " + (selectedFiles.length===0 ? 'd-none':'')} style={{paddingBottom:0,position:'relative'}}>
            <div className="tyn-reply-item" style={{paddingBottom:0, position:'absolute', bottom:0}}>
                <div className="tyn-reply-group">
                    <div className="tyn-reply-bubble w-100">
                                {selectedFiles.map((file) => (
                                    <div className="tyn-reply-file" key={file.id}>
                                        <div className="glightbox tyn-thumb position-relative"
                                             data-gallery="media-photo"
                                             key={file.id}>
                                            {file?.uploadProgress === 100 && (
                                                <button className="position-absolute link-danger btn btn-link"
                                                        onClick={() => handleRemoveFile(file.id)}
                                                        style={{zIndex: 10}}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                         fill="currentColor" className="bi bi-trash"
                                                         viewBox="0 0 16 16">
                                                        <path
                                                            d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"></path>
                                                        <path fillRule="evenodd"
                                                              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"></path>
                                                    </svg>
                                                </button>
                                            )}
                                            <a rel="noopener noreferrer" href={URL.createObjectURL(file.file)} target="_blank"
                                               className="tyn-file">
                                                <div className="tyn-media-group">
                                                    <div className="tyn-media tyn-size-lg text-bg-light">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                             fill="currentColor"
                                                             className="bi bi-file-earmark-arrow-down"
                                                             viewBox="0 0 16 16">
                                                            <path
                                                                d="M8.5 6.5a.5.5 0 0 0-1 0v3.793L6.354 9.146a.5.5 0 1 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 10.293V6.5z"/>
                                                            <path
                                                                d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
                                                        </svg>
                                                    </div>
                                                    <div className="tyn-media-col">
                                                        <h6 className="name">{file.name}</h6>
                                                        <div className="meta">{humanFileSize(file.size,true)}</div>
                                                    </div>
                                                </div>
                                            </a>
                                            {file?.uploadProgress !== 100 && (
                                                <div
                                                    className="d-flex justify-content-center position-absolute align-items-center"
                                                    style={{
                                                        left: 0,
                                                        top: 0,
                                                        zIndex: 10,
                                                        width: '100%',
                                                        height: '100%',
                                                        background: '#0f172a85'
                                                    }}>
                                                <span className="spinner-border spinner-border-sm text-white"
                                                      role="status" aria-hidden="true"></span>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                ))}
                            </div>
                            </div>
                            </div>
        </div>
    );
}
