import React from "react";

export default ()=>{
    return (
        <div className="tyn-footer border-top">
            <div className="bg-white text-center py-3">
                <p className="mb-0 small">Copyright © 2023. Tous droits reservés | Développé par <a href="https://jarstechnologies.com" target="_blank" className="fw-semibold"  rel="noopener noreferrer">Jars Technologies International</a> </p>
            </div>
        </div>
    );
}