import React from "react";
import Bubble from "./Bubble";

const Incoming = ({message,conversation,isSameDirectAuthor,handleDeleteMessage,handleReplyTo}) => {

    const IncomingWrapper = ({children}) => {
        return (
            <div className="tyn-reply-item outgoing">
                <div className="tyn-reply-group">
                    {children}
                </div>
            </div>
        );
    }
    return isSameDirectAuthor ?  <Bubble handleReplyTo={handleReplyTo} handleDeleteMessage={handleDeleteMessage} context="outgoing" message={message} conversation={conversation}/> :
        <IncomingWrapper>
            <Bubble handleReplyTo={handleReplyTo} handleDeleteMessage={handleDeleteMessage} message={message} conversation={conversation} context="outgoing"/>
        </IncomingWrapper>;
}
export default Incoming;