import React, {memo, useContext} from "react";
import {RootStateContext} from "../../../../../../providers/RootProvider";
import Outgoing from "./Outgoing";
import Incoming from "./Incoming";

const Index = ({message,conversation,isSameDirectAuthor,handleDeleteMessage,handleReplyTo}) => {

    const rootStateContext = useContext(RootStateContext);
    const {user} = rootStateContext;


    return message.sender === user.id ?
            <Outgoing message={message} handleReplyTo={handleReplyTo} handleDeleteMessage={handleDeleteMessage} conversation={conversation} isSameDirectAuthor={isSameDirectAuthor}/>
         :
            <Incoming message={message} handleReplyTo={handleReplyTo} handleDeleteMessage={handleDeleteMessage} conversation={conversation} isSameDirectAuthor={isSameDirectAuthor}/>
    ;
}
const MemoIndex = memo(Index, (prevProps, nextProps)=> prevProps.message === nextProps.message);
export default MemoIndex;