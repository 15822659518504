import React, {useContext} from "react";
import {requestSendAdd, requestSendCancel} from "../../actions/server/user/browser";
import toast from "react-hot-toast";
import {RootDispatchContext} from "../../providers/RootProvider";
import {getPlaceholderImage} from "../../utilities/common";
const  BrowserItem =({contactBrowser,isWaitingJoin,toggleContactRequest})=> {
    const rootDispatchContext = useContext(RootDispatchContext);

    /**
     * @description
     * @param _event
     */
    const toggleJoinHandler = (_event)=>{
        toggleContactRequest(contactBrowser);
        if (isWaitingJoin){
            requestSendCancel(rootDispatchContext,{id: contactBrowser.id},()=>{
                toast.success("Votre demande a été annulée " + contactBrowser.name);
            },(error)=>{
                toast.error(error.msg);
            });
        }else{
            requestSendAdd(rootDispatchContext,{id: contactBrowser.id},()=>{
                toast.success("Votre demande a été envoyée à  " + contactBrowser.name);
            },(error)=>{
                toast.error(error.msg);
            });
        }

    }

    return (
        <li data-id={contactBrowser.id}>
            <div className="tyn-media-group">
                <div className="tyn-media">
                    <img src={getPlaceholderImage(contactBrowser?.photo)} alt=""/>
                </div>
                <div className="tyn-media-col">
                    <div className="tyn-media-row">
                        <h6 className="name">{contactBrowser?.name} <span className="small">{contactBrowser?.username}</span></h6>
                    </div>
                    <div className="tyn-media-row">
                        <p className="content">{contactBrowser?.contact}</p> /
                        <p className="content">{contactBrowser?.email}</p>
                    </div>
                </div>
                <ul className="tyn-media-option-list me-n1">
                    <button className={!isWaitingJoin ? "btn btn-md btn-primary" : "btn btn-md btn-success"} onClick={toggleJoinHandler}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                             fill="currentColor" className="bi bi-check2-circle"
                             viewBox="0 0 16 16">
                            <path
                                d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"></path>
                            <path
                                d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"></path>
                        </svg>
                        <span>{isWaitingJoin ? "En cours...":"Envoyer une demande"}</span>
                    </button>

                </ul>
            </div>
        </li>

    );
}
export default  BrowserItem;