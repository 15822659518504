import React, {memo, useContext, useEffect, useMemo, useRef} from "react";
import {dateFromApiTo, generateVideoCallId, getPlaceholderImage} from "../../../utilities/common";
import TimeAgo from 'timeago-react';
import {Link} from "react-router-dom";
import ConversationMenuBadgeUnread from "./ConversationMenuBadgeUnread";
import {RootStateContext} from "../../../providers/RootProvider";


const ConversationMenuItem = ({conversation,openConversationHandler}) => {
    const rootStateContext = useContext(RootStateContext);
    const {user} = rootStateContext;

    const previewContentRef = useRef(null);

    /**
     * @param _event
     */
    const handleConversationClick = (_event) => {
        const  _target = _event.target;
        const _target_parent = _target.closest('[data-action-pib="true"]');
        if(_target_parent) {
            _event.stopPropagation();
            _event.preventDefault();
            openConversationHandler(conversation);
            toggleClassActive(_event);
        }

    }

    /**
     * @description
     * @param _event
     */
    const toggleClassActive = (_event) => {
        const  _target = _event.target;
        const _target_parent = _target.closest('[data-action-pib="true"]');

        if(_target_parent){
            _event.stopPropagation();
            _event.preventDefault();
            const _li = _target_parent.closest('.js-toggle-main');
            document.querySelectorAll('.js-toggle-main').forEach(item => {
                item.classList.remove('active');
            })
            _li.classList.add('active');
        }
    }

    useEffect(() => {
        const _html = conversation?.lastMessage?.content;
        if (_html) {
            const div = document.createElement("div");
            div.innerHTML = _html;
            previewContentRef.current.textContent = div.textContent || div.innerText || "";
        }

    }, [conversation]);


    const callVideoClientID = useMemo(()=>{
        return generateVideoCallId(conversation.aid,user.aid);
    },[conversation,user]);

    return (
        <li data-conversation-context="group" data-conversation-id={conversation.id}
            className="tyn-aside-item js-toggle-main" onClick={handleConversationClick}>
            <div className="tyn-media-group">
                <div className="tyn-media tyn-size-lg" data-action-pib="true">
                    <img src={getPlaceholderImage(conversation.photo)} alt={conversation.name}/>
                </div>
                <div className="tyn-media-col" data-action-pib="true">
                    <div className="tyn-media-row">
                        <h6 className="name">{conversation.name}</h6>
                        <div className="indicator varified">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                <path
                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                            </svg>
                            {
                                conversation.context==="contact" && (
                                    <span className="tyn-status-circle" style={{top:'10px',backgroundColor:'var(--default-back-color-'+conversation?.id+',#c5bfbf)'}} data-status-online={conversation?.id}></span>
                                )
                            }
                        </div>
                    </div>
                    <div className="tyn-media-row has-dot-sap">
                        {conversation.lastMessage ? (
                            <>
                                <p className="content" ref={previewContentRef}></p>
                                <span className="meta"><TimeAgo
                                    datetime={dateFromApiTo(conversation.lastMessage?.createdAt)}
                                    locale='fr_FR'
                                /></span>
                            </>
                        ) : (
                            <>
                                <p className="content">Cliquez pour envoyer un message</p>
                                <span className="meta"></span>
                            </>
                        )}
                    </div>
                    <ConversationMenuBadgeUnread conversation={conversation}/>
                </div>
                <div className="tyn-media-option tyn-aside-item-option">
                    <ul className="tyn-media-option-list">
                        <li className="dropdown">
                            <button className="btn btn-icon btn-white btn-pill dropdown-toggle"
                                    data-bs-toggle="dropdown" data-bs-offset="0,0" data-bs-auto-close="outside">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-three-dots" viewBox="0 0 16 16">
                                    <path
                                        d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/>
                                </svg>
                            </button>
                            <div className="dropdown-menu dropdown-menu-end">
                                <ul className="tyn-list-links">
                                    {(conversation.unreadCount > 0) && (
                                        <li>
                                            <a href="#">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                     fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                                                    <path
                                                        d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                                                </svg>
                                                <span>Marquer comme lu</span>
                                            </a>
                                        </li>
                                    )}
                                    <li><a href="#">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor" className="bi bi-bell" viewBox="0 0 16 16">
                                            <path
                                                d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"/>
                                        </svg>
                                        <span>Mute Notifications</span>
                                    </a></li>
                                    <li>
                                        <Link to={conversation.context ==="group" ? "/group/profile/view/"+ conversation.id: "/contact/profile/view/"+conversation.id}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                 fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
                                                <path
                                                    d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"/>
                                            </svg>
                                            <span>Voir le profil</span>
                                        </Link>
                                    </li>

                                    {conversation.context ==="contact" && (
                                        <>
                                            <li className="dropdown-divider"></li>
                                            <li>
                                                <a href="#" data-bs-toggle="modal">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                         fill="currentColor" className="bi bi-telephone" viewBox="0 0 16 16">
                                                        <path
                                                            d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                                                    </svg>
                                                    <span>Appel Audio</span>
                                                </a>
                                            </li>
                                            <li>
                                                <Link to={"/video-call/request-calling/"+ conversation.id +"/"+ callVideoClientID}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                         fill="currentColor" className="bi bi-camera-video" viewBox="0 0 16 16">
                                                        <path fillRule="evenodd"
                                                              d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556v4.35zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H2z"/>
                                                    </svg>
                                                    <span>Appel Vidéo</span>
                                                </Link>
                                            </li>
                                        </>
                                    )}
                                    <li className="dropdown-divider"></li>
                                    <li><a href="#">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor" className="bi bi-file-earmark-arrow-down"
                                             viewBox="0 0 16 16">
                                            <path
                                                d="M8.5 6.5a.5.5 0 0 0-1 0v3.793L6.354 9.146a.5.5 0 1 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 10.293V6.5z"/>
                                            <path
                                                d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
                                        </svg>
                                        <span>Archive</span>
                                    </a></li>
                                    <li><a href="#" data-bs-toggle="modal">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                            <path
                                                d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                            <path fillRule="evenodd"
                                                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                        </svg>
                                        <span>Delete</span>
                                    </a></li>
                                    <li><a href="#">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor" className="bi bi-exclamation-triangle"
                                             viewBox="0 0 16 16">
                                            <path
                                                d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"/>
                                            <path
                                                d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"/>
                                        </svg>
                                        <span>Report</span>
                                    </a></li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </li>
    );
}

const MemoConversationMenuItem = memo(ConversationMenuItem);
export default MemoConversationMenuItem;