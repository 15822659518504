import React, {useEffect, useRef} from "react";

export default (props) => {
    const videosBrowserButtonRef = useRef(null);
    const videosBrowserInputRef = useRef(null);

    const filesBrowserButtonRef = useRef(null);
    const filesBrowserInputRef = useRef(null);

    const  _onChangeVideoInput=(_event)=>{
        props.handleVideosBrowserChange(_event);
    }


    const  _onChangeFileInput=(_event)=>{
        props.handleFilesBrowserChange(_event);
    }

    useEffect(()=>{
        if (videosBrowserButtonRef && videosBrowserButtonRef.current) {
            videosBrowserButtonRef.current.addEventListener('click', () => videosBrowserInputRef.current.click());
        }

        if (filesBrowserButtonRef && filesBrowserButtonRef.current) {
            filesBrowserButtonRef.current.addEventListener('click', () => filesBrowserInputRef.current.click());
        }
    },[]);

    return (
        <li className="dropup">
            <button className="btn btn-icon btn-light btn-md btn-pill" data-bs-toggle="dropdown"
                    data-bs-offset="0,10">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     className="bi bi-plus-lg" viewBox="0 0 16 16">
                    <path fillRule="evenodd"
                          d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
                </svg>
            </button>
            <div className="dropdown-menu">
                <ul className="tyn-list-links">
                    {props.prefixElement === "Group" && (
                        <li>
                            <a href="#" data-bs-toggle="modal" data-bs-target="#newGroup">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                     fill="currentColor" className="bi bi-person-video2"
                                     viewBox="0 0 16 16">
                                    <path d="M10 9.05a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"/>
                                    <path
                                        d="M2 1a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2ZM1 3a1 1 0 0 1 1-1h2v2H1V3Zm4 10V2h9a1 1 0 0 1 1 1v9c0 .285-.12.543-.31.725C14.15 11.494 12.822 10 10 10c-3.037 0-4.345 1.73-4.798 3H5Zm-4-2h3v2H2a1 1 0 0 1-1-1v-1Zm3-1H1V8h3v2Zm0-3H1V5h3v2Z"/>
                                </svg>
                                <span>Nouveau groupe</span>
                            </a></li>
                    )}
                    <li>
                        <input type="file" className="d-none controlClearVideo" ref={videosBrowserInputRef}
                               onChange={_onChangeVideoInput}
                               accept="video/*"/>
                        <a href="#" ref={videosBrowserButtonRef}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-camera-video-fill" viewBox="0 0 16 16">
                                <path fillRule="evenodd"
                                      d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5z"/>
                            </svg>
                            <span>Partager une vidéo</span>
                        </a>
                    </li>
                    <li>
                        <input type="file" className="d-none controlClearFile" ref={filesBrowserInputRef}
                               onChange={_onChangeFileInput}
                               accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf"/>
                        <a href="#" ref={filesBrowserButtonRef}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                 fill="currentColor" className="bi bi-file-earmark-arrow-down"
                                 viewBox="0 0 16 16">
                                <path
                                    d="M8.5 6.5a.5.5 0 0 0-1 0v3.793L6.354 9.146a.5.5 0 1 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 10.293V6.5z"/>
                                <path
                                    d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
                            </svg>
                            <span>Téléverser un fichier</span>
                        </a>
                    </li>
                </ul>
            </div>
        </li>
    );

}