import {CONVERSATION_TAB} from "../actions/constants/Index";

const defaultState = {
    openedList: []
}
const reducer = (prevState = defaultState, action) => {
    switch (action.type) {
        case CONVERSATION_TAB.SET_TO_OPENED_LIST:
            return {
                ...prevState,
                openedList: action.payload.openedList
            };
        default:
            return prevState;
    }
};
export default reducer;
