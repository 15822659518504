import React from 'react';
import {createContext, useReducer} from "react";
import {reducers} from "../reducers/Index";

const rootInitialStateHandle = (initialState) => {
    return initialState;
}

const rootInitialState = {
    auth: {
        isLoggedIn: false,
        token_access: null,
        token_email: null,
    },
    appState: {
        isPreventLoading: false
    },
    theme: {
        mode: "light"
    },
    user:{

    },
    conversationTab:{
        openedList:null
    }
};
export const RootDispatchContext = createContext(null);
export const RootStateContext = createContext(null);


export const RootProvider = ({children}) => {
    const [rootState, rootDispatch] = useReducer(reducers, rootInitialState, rootInitialStateHandle);
    return (
        <RootStateContext.Provider value={rootState}>
            <RootDispatchContext.Provider value={rootDispatch}>
                {children}
            </RootDispatchContext.Provider>
        </RootStateContext.Provider>
    );
}