import React, {memo, useContext, useEffect, useState} from "react";
import {RootDispatchContext} from "../../../providers/RootProvider";
import {getAuthConversationsMessage} from "../../../actions/server/conversation";
import ConversationMenuItem from "./ConversationMenuItem";
import toast from "react-hot-toast";

const ConversationMenu = ({openConversationHandler, updateConversationMenuItem}) => {
    const rootDispatchContext = useContext(RootDispatchContext);
    const [isLoadingConversation, setIsLoadingConversation] = useState(true);
    const [conversations, setConversations] = useState([]);
    const [filterData, setFilterData] = useState([]);


    useEffect(() => {
        getAuthConversationsMessage(rootDispatchContext, (_conversations) => {
                setIsLoadingConversation(false);
                setConversations(_conversations);
                setFilterData(_conversations);
            },
            (error) => {
                toast.error(error.msg);
                setIsLoadingConversation(false);
            }
        )
    }, [rootDispatchContext]);


    useEffect(() => {
        if (updateConversationMenuItem) {
            if (conversations.length > 0) {
                let updateConversation = conversations.find(conv => conv.id === updateConversationMenuItem.conversationId);
                if (updateConversation) {
                    if (updateConversationMenuItem?.type === "INCREMENT") {
                        const unreadCount = updateConversationMenuItem.unreadCount || 1;
                        updateConversationMenuItem.unreadCount = updateConversation.unreadCount + unreadCount;
                    }
                    updateConversation = {
                        ...updateConversation,
                        ...updateConversationMenuItem,
                        lastMessage: {
                            ...updateConversation.lastMessage,
                            ...updateConversationMenuItem.lastMessage
                        }
                    }
                }
                let otherConversations = conversations.filter(conv => conv.id !== updateConversationMenuItem.conversationId);
                setFilterData([updateConversation, ...otherConversations]);
            }
        }
    }, [updateConversationMenuItem]);

    /**
     * @description callback permetant de filtrer la liste des contacts
     * @param _event
     */
    const filterListHandler = _event => {
        const updatedList = conversations.filter(item => item.name.toLowerCase().search(_event.target.value.toLowerCase()) !== -1);
        setFilterData(updatedList);
    }


    return (
        <div className="tyn-aside tyn-aside-base">
            <div className="tyn-aside-head">
                <div className="tyn-aside-head-text">
                    <h3 className="tyn-aside-title">Messages</h3>
                </div>
                <div className="tyn-aside-head-tools">
                    <ul className="link-group gap gx-3">
                        <li className="dropdown">
                            <button className="btn btn-icon btn-light btn-md btn-pill" data-bs-toggle="modal"
                                    data-bs-target="#newChat">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-plus-lg" viewBox="0 0 16 16">
                                    <path fillRule="evenodd"
                                          d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"></path>
                                </svg>
                            </button>
                        </li>
                        <li className="dropdown">
                            <button className="btn btn-icon btn-md btn-transparent btn-pill dropdown-toggle"
                                    data-bs-toggle="dropdown" data-bs-offset="0,10">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-three-dots" viewBox="0 0 16 16">
                                    <path
                                        d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"></path>
                                </svg>
                            </button>
                            <div className="dropdown-menu dropdown-menu-end">
                                <ul className="tyn-list-links nav nav-tabs border-0">
                                    <li>
                                        <button className="nav-link active" data-bs-toggle="modal"
                                                data-bs-target="#newGroup">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                 fill="currentColor" className="bi bi-chat-square-text"
                                                 viewBox="0 0 16 16">
                                                <path
                                                    d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                                                <path
                                                    d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"/>
                                            </svg>
                                            <span>Nouveau groupe</span>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="tyn-aside-body" data-simplebar="">
                <div className="tyn-aside-search">
                    <div className="form-group tyn-pill">
                        <div className="form-control-wrap">
                            <div className="form-control-icon start">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-search" viewBox="0 0 16 16">
                                    <path
                                        d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                                </svg>
                            </div>
                            <input type="text" className="form-control form-control-solid" id="search"
                                   placeholder="Rechercher un contact"  onChange={filterListHandler}/>
                        </div>
                    </div>
                </div>
                <div className="tab-content">
                    <div className="tab-pane show active" id="all-chats" role="tabpanel">
                        {isLoadingConversation && (
                            <div className="d-flex justify-content-center">
                                <span className="spinner-border spinner-border-sm" role="status"
                                      aria-hidden="true"></span>
                            </div>
                        )}
                        {!isLoadingConversation && (
                            <ul className="tyn-aside-list">
                                {filterData.map((conversation) => (
                                    <ConversationMenuItem key={conversation.id}
                                                          updateConversationMenuItem={updateConversationMenuItem}
                                                          conversation={conversation}
                                                          openConversationHandler={openConversationHandler}/>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
const MemoConversationMenu = memo(ConversationMenu, (prevProps, nextProps) => {
    return prevProps.updateConversationMenuItem === nextProps.updateConversationMenuItem
});
export default MemoConversationMenu;



