import axios from "axios";
import ConfigsApi from "../../../configs/Api";
import ConfigsEnv from "../../../configs/Env";

import {excludeObjectByKeys} from "../../../utilities/common";
import {ACCOUNT_ACTIONS} from "../../constants/Index";
import errorMiddleware from "../errorMiddleware";

/**
 * @description Permet
 * @param dispatch
 * @param success
 * @param failure
 */
export const getNotFriendUsers = (dispatch, success, failure) => {
    const taskURI = ConfigsApi.default[ConfigsEnv.deploy].endpoint + '/user/not-friend-users';
    axios.get(taskURI).then(v => {
        const data = v.data;
        if (data.error) {
            errorMiddleware({
                ...data,
                code: 500,
                key: "ERROR",
                msg:data.error
            },dispatch,failure);
        } else {
            success(data.users);
        }
    }).catch(error => {
        errorMiddleware({
            ...error,
            code: error.code,
            key: "ERROR",
            msg: error.message
        },dispatch,failure);
    });
}


/**
 * @description Permet
 * @param dispatch
 * @param failure
 */
export const getMinifyInfo = (dispatch,failure) => {
    const taskURI = ConfigsApi.default[ConfigsEnv.deploy].endpoint + '/user/auth-minify-info';
    axios.get(taskURI).then(v => {
        const data = v.data;
        if (data.error) {
            errorMiddleware({
                ...data,
                code: 500,
                key: "ERROR",
                msg:data.error
            },dispatch,failure);
        } else {
            const _userInfo = data.userInfo;
            let userInfo = excludeObjectByKeys(_userInfo,["__v","_id","password"]);
            userInfo.id = _userInfo._id;
            dispatch({
                type: ACCOUNT_ACTIONS.UPDATE_PROFILE,
                payload:userInfo
            });
        }
    }).catch(error => {
        errorMiddleware({
            ...error,
            code: error.code,
            key: "ERROR",
            msg: error.message
        },dispatch,failure);
    });
}


/**
 * @description
 * @param dispatch
 * @param data
 * @param success
 * @param failure
 */
export const requestSendAdd = (dispatch, data,success, failure) => {
    const taskURI = ConfigsApi.default[ConfigsEnv.deploy].endpoint + '/user/request-sent/add';
    axios.post(taskURI,data).then(v => {
        const data = v.data;
        if (data.error) {
            errorMiddleware({
                ...data,
                code: 500,
                key: "ERROR",
                msg:data.error
            },dispatch,failure);
        } else {
            success(data);
        }
    }).catch(error => {
        errorMiddleware({
            ...error,
            code: error.code,
            key: "ERROR",
            msg: error.message
        },dispatch,failure);
    });
}


/**
 * @description
 * @param dispatch
 * @param data
 * @param success
 * @param failure
 */
export const requestSendCancel = (dispatch, data,success, failure) => {
    const taskURI = ConfigsApi.default[ConfigsEnv.deploy].endpoint + '/user/request-sent/cancel';
    axios.post(taskURI,data).then(v => {
        const data = v.data;
        if (data.error) {
            errorMiddleware({
                ...data,
                code: 500,
                key: "ERROR",
                msg:data.error
            },dispatch,failure);
        } else {
            success(data);
        }
    }).catch(error => {
        errorMiddleware({
            ...error,
            code: error.code,
            key: "ERROR",
            msg: error.message
        },dispatch,failure);
    });
}


/**
 * @description
 * @param dispatch
 * @param data
 * @param success
 * @param failure
 */
export const requestReceivedCancel = (dispatch, data,success, failure) => {
    const taskURI = ConfigsApi.default[ConfigsEnv.deploy].endpoint + '/user/request-received/cancel';
    axios.post(taskURI,data).then(v => {
        const data = v.data;
        if (data.error) {
            errorMiddleware({
                ...data,
                code: 500,
                key: "ERROR",
                msg:data.error
            },dispatch,failure);
        } else {
            success(data);
        }
    }).catch(error => {
        errorMiddleware({
            ...error,
            code: error.code,
            key: "ERROR",
            msg: error.message
        },dispatch,failure);
    });
}



/**
 * @description
 * @param dispatch
 * @param data
 * @param success
 * @param failure
 */
export const requestReceivedAccept = (dispatch, data,success, failure) => {
    const taskURI = ConfigsApi.default[ConfigsEnv.deploy].endpoint + '/user/request-received/accept';
    axios.post(taskURI,data).then(v => {
        const data = v.data;
        if (data.error) {
            errorMiddleware({
                ...data,
                code: 500,
                key: "ERROR",
                msg:data.error
            },dispatch,failure);
        } else {
            success(data);
        }
    }).catch(error => {
        errorMiddleware({
            ...error,
            code: error.code,
            key: "ERROR",
            msg: error.message
        },dispatch,failure);
    });
}

