import {NOTIFICATION_ACTIONS} from "../../constants/Index";

/**
 * @description Permet
 * @param dispatch
 * @param counter
 */
export const decrementUnreadCount = (dispatch, counter) => {
    dispatch(decrementUnreadCountSuccess(counter));
}


/**
 * @description Permet
 * @param dispatch
 * @param counter
 */
export const incrementUnreadCount = (dispatch, counter) => {
    dispatch(incrementUnreadCountSuccess(counter));
}


/**
 *
 * @param counter
 * @returns {{payload, type: string}}
 */
const decrementUnreadCountSuccess = (counter) => {
    return {
        type: NOTIFICATION_ACTIONS.DECREMENT_UNREAD_COUNT,
        payload: counter
    };
}


/**
 *
 * @param counter
 * @returns {{payload, type: string}}
 */
const  incrementUnreadCountSuccess = (counter) => {
    return {
        type: NOTIFICATION_ACTIONS.INCREMENT_UNREAD_COUNT,
        payload: counter
    };
}

