import {getPlaceholderImage} from "../../../../../utilities/common";
import React, {useContext, useEffect, useState} from "react";
import {
    getAuthLoadMediaAll
} from "../../../../../actions/server/account/profile";
import toast from "react-hot-toast";
import {RootDispatchContext, RootStateContext} from "../../../../../providers/RootProvider";
import ImageItem from "./Medias/ImageItem";
import VideoItem from "./Medias/VideoItem";
import {
    getConversationFriendLoadMediaAll,
    getConversationGroupLoadMediaAll
} from "../../../../../actions/server/conversation";
import FileItem from "./Medias/FileItem";

const AsideRightInfo = ({conversation}) => {
    const rootStateContext = useContext(RootStateContext);
    const {user} = rootStateContext;
    const rootDispatchContext = useContext(RootDispatchContext);
    const [mediaAll, setMediaAll] = useState({
        images: [],
        audios: [],
        videos: [],
        files: [],
    });


    useEffect(() => {
        const responseMediaHandler = (_medias) => {
            let mediaTypes = {
                images: [],
                audios: [],
                videos: [],
                files: [],
            }
            _medias.forEach((_) => {
                if (_.type === "image") {
                    mediaTypes.images.push(_);
                } else if (_.type === "audio") {
                    mediaTypes.audios.push(_)
                } else if (_.type === "video") {
                    mediaTypes.videos.push(_);
                }else if (_.type === "file") {
                    mediaTypes.files.push(_);
                }
            });
            setMediaAll({
                ...mediaAll,
                ...mediaTypes
            });
        }
        if (conversation.context === "contact") {
            getConversationFriendLoadMediaAll(rootDispatchContext, user.id, conversation?.id, (_medias) => responseMediaHandler(_medias), (error) => {
                toast.error(error.msg);
            });
        } else {
            getConversationGroupLoadMediaAll(rootDispatchContext, user.id, conversation?.id, (_medias) => responseMediaHandler(_medias), (error) => {
                toast.error(error.msg);
            });
        }

    }, [rootDispatchContext]);


    return (
        <div
            className={"tyn-chat-content-aside align-items-center show-aside"}
            id={"tynChatAside-" + conversation.prefixElem + "-" + conversation?.id} data-simplebar>
            <div className="tyn-chat-cover">
                <img src={getPlaceholderImage(conversation.photo)}
                     alt=""/>
            </div>
            <div className="tyn-media-group tyn-media-vr tyn-media-center mt-n4">
                <div className="tyn-media tyn-size-xl border border-2 border-white">
                    <img src={getPlaceholderImage(conversation.photo)}
                         alt=""/>
                </div>
                <div className="tyn-media-col">
                    <div className="tyn-media-row">
                        <h6 className="name">{conversation.name}</h6>
                    </div>
                    <div className="tyn-media-row has-dot-sap">
                        {conversation.context === "contact" && (
                            <>
                                <span className="tyn-status-circle"
                                      style={{backgroundColor: 'var(--default-back-color-' + conversation.id + ',#c5bfbf)'}}
                                      data-status-online={conversation.id}></span>
                                <span className="meta">{conversation.contact.contact}</span>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className="tyn-aside-row">
                <ul className="nav nav-btns nav-btns-stretch nav-btns-light">
                    <li className="nav-item">
                        <button className="nav-link" data-bs-toggle="tab" data-bs-target="#chat-media"
                                type="button">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-images" viewBox="0 0 16 16">
                                <path d="M4.502 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                                <path
                                    d="M14.002 13a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V5A2 2 0 0 1 2 3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v8a2 2 0 0 1-1.998 2zM14 2H4a1 1 0 0 0-1 1h9.002a2 2 0 0 1 2 2v7A1 1 0 0 0 15 11V3a1 1 0 0 0-1-1zM2.002 4a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1h-10z"/>
                            </svg>
                            <span>Médias</span>
                        </button>
                    </li>
                    <li className="nav-item">
                        <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#chat-options"
                                type="button">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-sliders" viewBox="0 0 16 16">
                                <path fillRule="evenodd"
                                      d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3h9.05zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8h2.05zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1h9.05z"/>
                            </svg>
                            <span>Options</span>
                        </button>
                    </li>
                </ul>
            </div>
            <div className="tab-content">
                <div className="tab-pane" id="chat-media">
                    <div className="tyn-aside-row py-0 px-2">
                        <ul className="nav nav-tabs nav-tabs-line">
                            <li className="nav-item">
                                <button className="nav-link active" data-bs-toggle="tab"
                                        data-bs-target="#chat-media-images" type="button"> Images
                                </button>
                            </li>
                            <li className="nav-item">
                                <button className="nav-link" data-bs-toggle="tab"
                                        data-bs-target="#chat-media-videos" type="button"> Vidéos
                                </button>
                            </li>
                            <li className="nav-item">
                                <button className="nav-link" data-bs-toggle="tab" data-bs-target="#chat-media-files"
                                        type="button"> Fichiers
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div className="tyn-aside-row">
                        <div className="tab-content">
                            <div className="tab-pane show active" id="chat-media-images">
                                <div className="row g-3">
                                    {mediaAll.images.length > 0 && mediaAll.images.map((_mediaImage) => (
                                        <ImageItem key={_mediaImage._id}
                                                   url={_mediaImage.url}/>
                                    ))}
                                </div>
                            </div>
                            <div className="tab-pane" id="chat-media-videos">
                                <div className="row g-3">
                                    {mediaAll.videos.length > 0 && mediaAll.videos.map((_mediaVideo) => (
                                        <VideoItem key={_mediaVideo._id}
                                                   url={_mediaVideo.url} preview={_mediaVideo?.preview}/>
                                    ))}
                                </div>
                            </div>
                            <div className="tab-pane" id="chat-media-files">
                                <ul className="tyn-media-list gap gap-3">
                                    {mediaAll.files.length > 0 && mediaAll.files.map((_mediaFile) => (
                                        <FileItem key={_mediaFile._id}  name={_mediaFile.name}  size={_mediaFile.size}
                                                  url={_mediaFile.url} preview={_mediaFile?.preview}/>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tab-pane show active" id="chat-options">
                    <div className="tyn-aside-row py-0">
                        <ul className="nav nav-tabs nav-tabs-line">
                            <li className="nav-item">
                                <button className="nav-link active" data-bs-toggle="tab"
                                        data-bs-target="#chat-options-customize" type="button"> Personnaliser
                                </button>
                            </li>
                            <li className="nav-item">
                                <button className="nav-link" data-bs-toggle="tab"
                                        data-bs-target="#chat-options-manage" type="button"> Gestion
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div className="tyn-aside-row">
                        <div className="tab-content">
                            <div className="tab-pane show active" id="chat-options-customize">
                                <ul className="d-flex flex-column gap gap-4">
                                    <li>
                                        <h6 className="tyn-title-overline">Thème</h6>
                                        <ul className="tyn-chat-theme-list">
                                            <li>
                                                <button className="tyn-chat-theme-btn" data-theme="blue"></button>
                                            </li>
                                            <li>
                                                <button className="tyn-chat-theme-btn" data-theme="indigo"></button>
                                            </li>
                                            <li>
                                                <button className="tyn-chat-theme-btn" data-theme="green"></button>
                                            </li>
                                            <li>
                                                <button className="tyn-chat-theme-btn" data-theme="red"></button>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                            <div className="tab-pane" id="chat-options-manage">
                                <ul className="tyn-media-list gap gap-3">
                                    <li>
                                        <a href="#" className="tyn-file">
                                            <div className="tyn-media-group">
                                                <div className="tyn-media text-bg-light">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                         fill="currentColor" className="bi bi-person-x-fill"
                                                         viewBox="0 0 16 16">
                                                        <path fillRule="evenodd"
                                                              d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6.146-2.854a.5.5 0 0 1 .708 0L14 6.293l1.146-1.147a.5.5 0 0 1 .708.708L14.707 7l1.147 1.146a.5.5 0 0 1-.708.708L14 7.707l-1.146 1.147a.5.5 0 0 1-.708-.708L13.293 7l-1.147-1.146a.5.5 0 0 1 0-.708z"/>
                                                    </svg>
                                                </div>
                                                <div className="tyn-media-col">
                                                    <h6 className="name">Bloquer</h6>
                                                    <div className="meta">{conversation.name} ne sera plus à votre
                                                        contact.
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className="tyn-file">
                                            <div className="tyn-media-group">
                                                <div className="tyn-media text-bg-light">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                         fill="currentColor"
                                                         className="bi bi-exclamation-triangle-fill"
                                                         viewBox="0 0 16 16">
                                                        <path
                                                            d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                                                    </svg>
                                                </div>
                                                <div className="tyn-media-col">
                                                    <h6 className="name">Rapport</h6>
                                                    <div className="meta">Donner des commentaires sur la conversation
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}


export default AsideRightInfo;