import React from 'react';
const ConversationMenuBadgeUnread = ({conversation}) => {

    return (
        <>
            {conversation.unreadCount && conversation.unreadCount > 0 ? (
            <div className="tyn-media-row tyn-incoming-badge">
                <div className="badge bg-primary position-absolute rounded-pill mt-n1 me-n1"
                     style={{right: '-20px', top: '20px'}}>{conversation.unreadCount}</div>
            </div>
            ) : (
            <div className="tyn-media-row d-none tyn-incoming-badge">
                <div className="badge bg-primary position-absolute rounded-pill mt-n1 me-n1"
                     style={{right: '-20px', top: '20px'}}>0
                </div>
            </div>
            )}
        </>
    );
};

export default ConversationMenuBadgeUnread;
