import React, { useContext, useEffect, useRef, useState } from "react";
import {
  RootDispatchContext,
  RootStateContext,
} from "../../../../providers/RootProvider";
import { getProfileDetails } from "../../../../actions/server/group";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

const MembersTab = () => {
  const rootDispatchContext = useContext(RootDispatchContext);
  const rootStateContext = useContext(RootStateContext);
  const { user } = rootStateContext;
  const [isLoadingProfileDetails, setIsLoadingProfileDetails] = useState(true);
  const [profileDetails, setProfileDetails] = useState({});
  const params = useParams();
  const { groupId } = params;

  useEffect(() => {
    getProfileDetails(
      rootDispatchContext,
      groupId,
      (groupInfo) => {
        setIsLoadingProfileDetails(false);
        setProfileDetails(groupInfo);
      },
      (error) => {
        toast.error(error.msg);
        setIsLoadingProfileDetails(false);
      }
    );
  }, [rootDispatchContext, groupId]);

  return (
    <>
      <div className="tyn-aside-row py-0">
        <ul className="nav nav-tabs nav-tabs-line">
          <li className="nav-item">
            <button
              className="nav-link active"
              data-bs-toggle="tab"
              data-bs-target="#group-profile-members-customize"
              type="button"
            >
              {" "}
              Membres actifs
            </button>
          </li>
          <li className="nav-item">
            <button
              className="nav-link"
              data-bs-toggle="tab"
              data-bs-target="#group-profile-members-manage"
              type="button"
            >
              {" "}
              Membres bloqués
            </button>
          </li>
        </ul>
      </div>
      <div className="tyn-aside-row">
        <div className="tab-content">
          <div
            className="tab-pane show active"
            id="group-profile-members-customize"
          >
            <div className="row">
              {/* {<p>{profileDetails?.name}</p>} */}
              {profileDetails.members?.map((member) => (
                <li className="text-primary list-unstyled" key={member._id}>
                  {member.name}
                </li>
              ))}
            </div>
          </div>
          <div className="tab-pane" id="group-profile-members-manage"></div>
        </div>
      </div>
    </>
  );
};
export default MembersTab;
