import React from "react";

function AppLoading() {
    return (
        <div className="d-flex justify-content-center flex-column" style={{minHeight:"100vh", alignItems:"center"}}>
            <div className="spinner-border" role="status"></div>
            <div>Chargement en cours...</div>
        </div>
    );
}

export default AppLoading;