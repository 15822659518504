import React, {useContext} from "react";
import {RootStateContext} from "../../../../../../providers/RootProvider";

const  ActionsDropdown =({message,handleReplyTo,context,handleDeleteMessage})=> {
    const rootStateContext = useContext(RootStateContext);

    const {user} = rootStateContext;


    return (
        <ul className="tyn-reply-tools">
            <li>
                <button className="btn btn-icon btn-sm btn-transparent btn-pill" >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-emoji-smile-fill" viewBox="0 0 16 16">
                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zM4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683zM10 8c-.552 0-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5S10.552 8 10 8z"></path>
                    </svg>
                </button>
            </li>
            <li className="dropup-center">
                <button className="btn btn-icon btn-sm btn-transparent btn-pill" data-bs-toggle="dropdown">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots" viewBox="0 0 16 16">
                        <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"></path>
                    </svg>
                </button>
                <div className="dropdown-menu dropdown-menu-xxs">
                    <ul className="tyn-list-links">
                        <li>
                            <button className="link" onClick={()=>handleReplyTo.handleSetReplyTo({...message,context:context})}>
                                <svg  xmlns="http://www.w3.org/2000/svg" className="bi" viewBox="0 0 24 24" style={{height: '26px', width: '26px'}}><path fill="currentColor" d="M8,9.8V10.7L9.7,11C12.3,11.4 14.2,12.4 15.6,13.7C13.9,13.2 12.1,12.9 10,12.9H8V14.2L5.8,12L8,9.8M10,5L3,12L10,19V14.9C15,14.9 18.5,16.5 21,20C20,15 17,10 10,9"></path></svg>
                                <span>Répondre</span>
                            </button>
                        </li>
                        {[message.sender,message.recipient].includes(user.id) && (
                            <li>
                                <button className="link" onClick={()=>handleDeleteMessage(message)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"></path>
                                        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"></path>
                                    </svg>
                                    <span>Supprimer</span>
                                </button>
                            </li>
                        )}
                    </ul>
                </div>
            </li>
        </ul>
    );
}
export default  ActionsDropdown;