import React, {memo, useContext} from "react";
import {RootStateContext} from "../../../../../../providers/RootProvider";
import Outgoing from "./Outgoing";
import Incoming from "./Incoming";
import {dateFromApiTo} from "../../../../../../utilities/common";
import TimeAgo from "timeago-react";
import UserReadCheckSign from "./UserReadCheckSign";
import {Link} from "react-router-dom";

const AuthorSender = (props) => {
    return props.context==="incoming" ? (
        <div style={{ display:'flex', flex:'auto'}}>
            <Link style={{fontWeight:'600', fontSize:'0.6rem'}} to={"/user/profile/"+ props.message?.senderInfo?._id}>{props.message?.senderInfo?.name}</Link>
        </div>
    ) :<></>;
}
export default AuthorSender;