import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {RootDispatchContext, RootStateContext} from "../../../providers/RootProvider";
import toast from "react-hot-toast";
import ConfigsApi from "../../../configs/Api";
import ConfigsEnv from "../../../configs/Env";
import axios from "axios";
import {excludeObjectByKeys, wait} from "../../../utilities/common";
import {useSocket} from "../../../providers/SocketProvider";
import StartingCallRequestJoin from "./StartingCallRequestJoin";
import StartCalledJoin from "./StartCalledJoin";
import {useNavigate,useParams} from "react-router-dom";
const audioPath = '/assets/audio/request_calling.ogg';

function RequestCalling() {
    const navigate = useNavigate();
    const socket = useSocket();
    const params = useParams();
    const {callingId, roomID} = params;
    const [isLoadingInfo, setIsLoadingInfo] = useState(true);
    const [callProfile, setCallProfile] = useState(null);

    const rootStateContext = useContext(RootStateContext);
    const rootDispatchContext = useContext(RootDispatchContext);
    const {user} = rootStateContext;
    const audioRef = useRef();
    const [isPlaying, setIsPlaying] = useState(false);



    const [startCalling, setStartCalling] = useState(false);
    const [startCalled, setStartCalled] = useState(false);

    useEffect(() => {
        try {
            (async () => {
                const taskURI = ConfigsApi.default[ConfigsEnv.deploy].endpoint + '/user/minify-info/' + callingId;
                const apiResponse = await axios.get(taskURI);
                const data = apiResponse.data;
                if (data.error) {
                    toast.error(data.error);
                    setIsLoadingInfo(false);
                } else {
                    const _userInfo = data.userInfo;
                    let userInfo = excludeObjectByKeys(_userInfo, ["__v", "_id", "password"]);
                    userInfo.id = _userInfo._id;
                    setCallProfile(userInfo);
                    setIsLoadingInfo(false);
                }
            })();

        } catch (error) {
            toast.error(error.msg);
            setIsLoadingInfo(false);
        }
    }, [rootDispatchContext]);


    const startPeerUserInfo = useMemo(()=>{
        if (callProfile){
            return {
                roomID: roomID,
                fromUserInfo: {id:user.id,name:user.name,email:user.email,photo:user.photo,contact:user.contact},
                toUserInfo: {id:callProfile.id,name:callProfile.name,email: callProfile.email,photo:callProfile.photo,contact: callProfile.contact},
            }
        }else{
            return  null;
        }

    },[user,callProfile]);


    const onStartCallingHandler = useCallback(()=>{
        handlePlayPause();
        setStartCalling(true);
    },[startCalling]);

    const onCancelCallingHandler = useCallback(()=>{
        handleStop();
        setStartCalling(false);
        socket.emit("cancelCallingVideoRoom",startPeerUserInfo);
    },[startCalling]);


    useEffect(() => {
        if (startCalled){
            wait(0).then(()=>{
                navigate("/video-call/init/"+callingId+"/"+roomID, { replace: true })
            });
        }

    }, [startCalled]);



    useEffect(() => {
        if (startCalling && socket && startPeerUserInfo){
            socket.emit("startCallingVideoRoom",startPeerUserInfo);
        }
    }, [startCalling,socket,startPeerUserInfo]);


    useEffect(() => {
        if (socket) {
            socket.on("acceptRequestJoinCallVideoRoom", startPeerUserInfo => {
                toast.success(startPeerUserInfo.toUserInfo.name + " a accepté votre demande d'appel vidéo");
                handleStop();
                setStartCalling(false);
                setStartCalled(true);
            });

            socket.on("cancelRequestJoinCallVideoRoom", startPeerUserInfo => {
                handleStop();
                toast.success(startPeerUserInfo.toUserInfo.name + " a refusé votre demande d'appel vidéo");
                setStartCalling(false);
            });

            socket.on("notFundRequestJoinCallVideoRoom", startPeerUserInfo => {
                wait(2000).then(()=>{
                    handleStop();
                    toast.error(startPeerUserInfo.toUserInfo.name + " n'est pas en ligne actuellement !");
                    setStartCalling(false);
                });

            });

        }
    }, [socket]);


    /**
     *
     * @description Permet de mettre en pause ou en lecture le son d'appel
     */
    const handlePlayPause = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    }

    /**
     * @description Permet de stopper la lecture du son audio de l'appel
     */
    const handleStop = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
            setIsPlaying(false);
        }
    }


    if (isLoadingInfo) {
        return (<div className="tyn-content  tyn-content-page">
            <div className="tyn-main tyn-content-inner" id="tynMain">
                <div className="container">
                    <div className="d-flex justify-content-center">
                             <span className="spinner-border spinner-border-sm"
                                   role="status" aria-hidden="true"></span>
                    </div>
                </div>
            </div>
        </div>);
    }

    return (
        <div className="tyn-content  tyn-content-page">
            <div className="tyn-main tyn-content-inner" id="tynMain">
                <div className="container h-100">
                    {!startCalled ?(
                        <div className="px-3 h-100">
                            <div className="modal fade show position-static d-block w-auto h-100" tabIndex="-1">
                                <div className="modal-dialog modal-dialog-centered modal-md my-0">
                                    <div className="modal-content border-0">
                                        <StartingCallRequestJoin callProfile={callProfile} startCalling={startCalling} onStartCallingHandler={onStartCallingHandler} onCancelCallingHandler={onCancelCallingHandler}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) :
                        <StartCalledJoin callProfile={callProfile}/>
                    }
                </div>
            </div>
            <audio ref={audioRef} src={audioPath} loop style={{ display: 'none' }} />
        </div>
    );
}

export default RequestCalling;