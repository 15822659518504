import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  RootDispatchContext,
  RootStateContext,
} from "../../../providers/RootProvider";
import { getProfileDetails } from "../../../actions/server/group";
import { dateFromApiTo, getPlaceholderImage } from "../../../utilities/common";
import { useParams } from "react-router-dom";
import AboutTab from "../../../components/group/profile/tab/AboutTab";
import MediaTab from "../../../components/group/profile/tab/MediaTab";
import MembersTab from "../../../components/group/profile/tab/MembersTab";

function ProfileView() {
  const rootDispatchContext = useContext(RootDispatchContext);
  const [isLoadingProfileDetails, setIsLoadingProfileDetails] = useState(true);
  const [profileDetails, setProfileDetails] = useState({});
  const params = useParams();
  const { groupId } = params;

  useEffect(() => {
    getProfileDetails(
      rootDispatchContext,
      groupId,
      (groupInfo) => {
        setIsLoadingProfileDetails(false);
        setProfileDetails(groupInfo);
      },
      (error) => {
        toast.error(error.msg);
        setIsLoadingProfileDetails(false);
      }
    );
  }, [rootDispatchContext, groupId]);

  if (isLoadingProfileDetails) {
    return (
      <div className="tyn-content  tyn-content-page">
        <div className="tyn-main tyn-content-inner" id="tynMain">
          <div className="container">
            <div className="d-flex justify-content-center">
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="tyn-content  tyn-content-page">
      <div className="tyn-main tyn-content-inner" id="tynMain">
        <div className="container-sm">
          <div className="tyn-chat-content-aside align-items-center show-aside position-relative pt-3 tyn-group-profile rounded-1">
            <div className="tyn-chat-cover">
              <img src={getPlaceholderImage(profileDetails?.cover)} alt="" />
            </div>
            <div className="tyn-media-group tyn-media-vr tyn-media-center mt-n4 w-100">
              <div className="tyn-media tyn-size-xl border border-2 border-white">
                <img src={getPlaceholderImage(profileDetails?.cover)} alt="" />
              </div>
              <div className="tyn-media-col">
                <div className="tyn-media-row">
                  <h6 className="name">{profileDetails?.name}</h6>
                </div>
                <div className="tyn-media-row has-dot-sap">
                  <span className="meta">
                    {dateFromApiTo(profileDetails.createdAt)}
                  </span>
                </div>
              </div>
            </div>
            <div className="tyn-aside-row w-100">
              <ul className="nav nav-btns nav-btns-stretch nav-btns-light">
                <li className="nav-item">
                  <button
                    className="nav-link active"
                    data-bs-toggle="tab"
                    data-bs-target="#group-profile-about"
                    type="button"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-door-open-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M1.5 15a.5.5 0 0 0 0 1h13a.5.5 0 0 0 0-1H13V2.5A1.5 1.5 0 0 0 11.5 1H11V.5a.5.5 0 0 0-.57-.495l-7 1A.5.5 0 0 0 3 1.5V15H1.5zM11 2h.5a.5.5 0 0 1 .5.5V15h-1V2zm-2.5 8c-.276 0-.5-.448-.5-1s.224-1 .5-1 .5.448.5 1-.224 1-.5 1z"></path>
                    </svg>
                    <span>À propos</span>
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className="nav-link"
                    data-bs-toggle="tab"
                    data-bs-target="#group-profile-media"
                    type="button"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-images"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.502 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                      <path d="M14.002 13a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V5A2 2 0 0 1 2 3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v8a2 2 0 0 1-1.998 2zM14 2H4a1 1 0 0 0-1 1h9.002a2 2 0 0 1 2 2v7A1 1 0 0 0 15 11V3a1 1 0 0 0-1-1zM2.002 4a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1h-10z" />
                    </svg>
                    <span>Media</span>
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className="nav-link"
                    data-bs-toggle="tab"
                    data-bs-target="#group-profile-members"
                    type="button"
                  >
                    <svg
                      style={{ width: "1.5rem", height: "1.5rem" }}
                      xmlns="http://www.w3.org/2000/svg"
                      className="ionicon"
                      viewBox="0 0 512 512"
                    >
                      <path
                        d="M402 168c-2.93 40.67-33.1 72-66 72s-63.12-31.32-66-72c-3-42.31 26.37-72 66-72s69 30.46 66 72z"
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="32"
                      />
                      <path
                        d="M336 304c-65.17 0-127.84 32.37-143.54 95.41-2.08 8.34 3.15 16.59 11.72 16.59h263.65c8.57 0 13.77-8.25 11.72-16.59C463.85 335.36 401.18 304 336 304z"
                        fill="none"
                        stroke="currentColor"
                        strokeMiterlimit="10"
                        strokeWidth="32"
                      />
                      <path
                        d="M200 185.94c-2.34 32.48-26.72 58.06-53 58.06s-50.7-25.57-53-58.06C91.61 152.15 115.34 128 147 128s55.39 24.77 53 57.94z"
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="32"
                      />
                      <path
                        d="M206 306c-18.05-8.27-37.93-11.45-59-11.45-52 0-102.1 25.85-114.65 76.2-1.65 6.66 2.53 13.25 9.37 13.25H154"
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        strokeWidth="32"
                      />
                    </svg>
                    <span>Membres ({profileDetails.members.length})</span>
                  </button>
                </li>
              </ul>
            </div>
            <div className="tab-content w-100">
              <div className="tab-pane show active" id="group-profile-about">
                <AboutTab profileDetails={profileDetails} />
              </div>
              <div className="tab-pane" id="group-profile-media">
                <MediaTab profileDetails={profileDetails} />
              </div>
              <div className="tab-pane" id="group-profile-members">
                <MembersTab profileDetails={profileDetails} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileView;
