import React, {useContext, useEffect, useState} from "react";
import {RootDispatchContext} from "../../providers/RootProvider";
import {authLogin} from "../../actions/dispatch/auth";
import {MasterLayoutProvider} from "../../components/layouts/auth/Master";
import {Link} from "react-router-dom";
import toast from "react-hot-toast";
import {ACCOUNT_ACTIONS} from "../../actions/constants/Index";
import axios from "axios";

function Home() {

    const rootDispatchContext = useContext(RootDispatchContext);

    const [isLogging, setIsLogging] = useState(false);
    const [dataForm, setDataForm] = useState({
        email: "",
        password: "",
        modules: "profile"
    });

    useEffect(() => {
        document.querySelector('title').textContent = "Connectez-vous  a YuzMeet";
    }, []);


    /**
     * @description Permet de changer de facon intelligente la valeur
     * @param _event
     * @param key
     */
    const onChangeFormFieldHandler = (_event, key) => {
        const fieldValue = _event.target.value;
        if (key === "email") {
            setDataForm({
                ...dataForm,
                email: fieldValue
            });
        } else {
            setDataForm({
                ...dataForm,
                password: fieldValue
            });
        }
    }

    /**
     * @description Permet de se connecter
     * @param _event
     */
    const onSubmitHandler = (_event) => {
        _event.preventDefault();
        setIsLogging((prevState) => !prevState);
        authLogin(rootDispatchContext, dataForm,
            (user) => {
                axios.defaults.headers.common["X-Auth-Token"] = user.auth.access_token;
                rootDispatchContext({
                    type: ACCOUNT_ACTIONS.UPDATE_PROFILE,
                    payload:user
                });
                toast.success('Connexion reussi avec succes !');
                localStorage.setItem("pid_user", JSON.stringify(user));
            }, (error) => {
                toast.error(error.msg);
                setIsLogging(false);
            });
    }


    return (
        <MasterLayoutProvider>
            <div className="card border-0">
                <form className="p-4" method="post" onSubmit={onSubmitHandler}>
                    <h3>Connexion</h3>
                    <div className="row g-3">
                        <div className="col-12">
                            <div className="form-group">
                                <div className="form-control-wrap">
                                    <input type="email" className="form-control rounded-pill" id="email-address"
                                           placeholder="Adresse email"
                                           onChange={(_event) => onChangeFormFieldHandler(_event, "email")}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <div className="form-control-wrap">
                                    <input type="password" className="form-control rounded-pill" id="password"
                                           placeholder="Mot de passe"
                                           onChange={(_event) => onChangeFormFieldHandler(_event, "password")}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <button type="submit" className="btn btn-primary w-100"
                                    disabled={(dataForm.email === "" || dataForm.password === "") || isLogging}>
                                {isLogging ?
                                    <>
                                        <span className="spinner-border spinner-border-sm" role="status"
                                              aria-hidden="true"></span>
                                        <span className="sr-only">Connexion...</span>
                                    </> :
                                    <>Se connecter</>
                                }
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div className="text-center mt-4">
                <p className="small">Vous n'avez pas de compte ? <Link to="/register">Inscription</Link></p>
            </div>
        </MasterLayoutProvider>
    );
}

export default Home;