import React from "react";
import {getPlaceholderImage} from "../../../../../../utilities/common";
import Bubble from "./Bubble";

const Incoming = ({message,conversation,isSameDirectAuthor,handleDeleteMessage,handleReplyTo}) => {

    const IncomingWrapper = ({children}) => {
        return (
            <div className="tyn-reply-item incoming">
                <div className="tyn-reply-avatar">
                    <div className="tyn-media tyn-size-md tyn-circle">
                        <img src={getPlaceholderImage(message?.senderInfo?.photo)} alt=""/>
                    </div>
                </div>
                <div className="tyn-reply-group">
                    {children}
                </div>
            </div>
        );
    }
    return isSameDirectAuthor ?  <Bubble handleReplyTo={handleReplyTo} context="incoming" message={message} conversation={conversation} isSameDirectAuthor={isSameDirectAuthor} handleDeleteMessage={handleDeleteMessage}/> :
        <IncomingWrapper>
            <Bubble handleReplyTo={handleReplyTo} context="incoming" message={message} conversation={conversation} isSameDirectAuthor={isSameDirectAuthor} handleDeleteMessage={handleDeleteMessage}/>
        </IncomingWrapper>;
}
export default Incoming;